import React from "react";
import CounterDetails from "./CounterDetails";
import LineDataComponent from "./LineDataComponent";

const HomepageCounterComponent: React.FC<{
  heading: string;
  subHeading: string;
  counterDetails: any;
  primaryBox: any;
  secondaryBox: any;
}> = ({
  heading,
  counterDetails = [],
  subHeading,
  primaryBox = [],
  secondaryBox = [],
}) => {
  return (
    <div className="flex w-full justify-center">
      <div className="w-full max-w-[1920px] flex flex-col px-[11px] pb-10 pt-primaryPaddingYSm lg:pt-[6.25rem] lg:bg-transparent lg:pb-28 lg:px-0 lg:items-center lg:gap-y-12 lg:py-24">
        <div className="text-orangePrimary  w-full text-center text-primaryHeadingSm mb-5 lg:mb-0 lg:w-[50%] lg:h-fit lg:text-center roboto lg:text-primaryHeading2xl font-black lg:leading-[3.25rem] ">
          {heading}
        </div>
        <div className="w-full lg:w-8/12 lg:h-fit lg:flex lg:flex-row lg:justify-between">
          <CounterDetails
            initialValue={0}
            finalValue={Number(counterDetails[0]?.title || 0)}
            duration={2.25}
            finalString="+"
            counterDescription={counterDetails[0]?.description}
          />
          <CounterDetails
            initialValue={0}
            finalValue={Number(counterDetails[1]?.title || 0)}
            duration={2.25}
            initialString="$"
            finalString="B"
            counterDescription={counterDetails[1]?.description}
          />
          <CounterDetails
            initialValue={0}
            finalValue={Number(counterDetails[2]?.title || 0)}
            duration={2.25}
            finalString="M"
            counterDescription={counterDetails[2]?.description}
          />
        </div>
        <div className="border-[#F96B25] text-center lg:w-8/12 lg:h-fit lg:flex lg:flex-col lg:mt-12 lg:pb-12 lg:items-center lg:border-2 lg:rounded-xl">
          <div className="font-black text-white text-primaryHeadingSm text-center roboto lg:bg-bluePrimary lg:w-fit lg:px-16 lg:text-primaryHeading2xl lg:mt-[-33px]">
            {subHeading}
          </div>
          <div className="text-white flex flex-col font-semibold tracking-wide text-[14px] items-center mt-10 lg:h-fit lg:w-10/12 lg:text-paragraphHomepage2xl lg:mt-20">
            {primaryBox?.map((value: any, index: number) => (
              <div
                key={value?.bulletPoint}
                className={`border-gradient-orange w-fit tracking-tight px-[1px] border-2 ${
                  index === 0 ? "" : "border-t-0"
                }   flex justify-center py-[6px]  lg:py-3 lg:px-10 lg:w-[${
                  73 - index * 2
                }%]`}
              >
                {value?.bulletPoint}
              </div>
            ))}
          </div>
          <LineDataComponent threatTypes={secondaryBox} />
        </div>
      </div>
    </div>
  );
};

export default HomepageCounterComponent;
