import { VAPTComponentProps } from "../../../Interfaces/Interface";
import constants from "../../../Utils/Constants/constants";
import Navbar from "../../common/Navbar/Navbar";

const VAPTLandingComponent = ({
  heading,
  description,
  isLinearGrident,
  backgroundType,
  backgroundImage,
}: VAPTComponentProps) => {
  return (
    <div
      className={`flex relative flex-col items-center lg:min-h-screen 3xl:min-h-fit ${
        isLinearGrident && "linear_secound"
      }`}
    >
      <div className="absolute w-full h-full z-[0] hidden lg:flex">
        {constants.reusableConstants.videoFormats.includes(
          backgroundType || ""
        ) ? (
          <video
            src={backgroundImage}
            className="h-full w-full object-cover object-center opacity-[20%]"
            autoPlay
            muted
            playsInline
            loop
            onContextMenu={(e) => e.preventDefault()}
          />
        ) : !constants.reusableConstants.videoFormats.includes(
            backgroundType || ""
          ) && backgroundType ? (
          <img
            src={backgroundImage}
            alt="background_logo"
            className="w-full h-full object-cover object-center opacity-[20%]"
          />
        ) : (
          ""
        )}
      </div>
      <Navbar />
      <div className="flex flex-col px-[23px] z-[1] items-center pt-primaryPaddingYSm pb-[1.75rem] text-primaryHeadingSm max-w-[1920px] lg:items-center lg:px-homepagePaddingLg xl:px-homepagePadding2xl lg:pt-28 lg:pb-[300px]">
        <div className="flex flex-col w-full  pb-8 text-center gap-y-8 lg:text-base lg:text-left lg:w-full xl:w-[70%] lg:gap-y-[2rem] ">
          <div className="w-full text-orangePrimary font-black text-primaryHeadingSm text-center roboto lg:text-center lg:text-primaryHeading2xl lg:leading-[3.25rem]">
            {heading}
          </div>
          <div className="w-full font-semibold text-white source-sans text-descriptionHeadingSm text-center leading-normal lg:text-center lg:text-paragraphHomepage2xl ">
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VAPTLandingComponent;
