import { useField } from "formik";

const CustomDropdown: React.FC<any> = ({
  placeholder,
  data,
  type,
  serverError,
  prefix,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div className=" lg:h-[4rem]">
      <label htmlFor="country-select" className="sr-only">
        Select Country
      </label>
      <select
        id="country-select"
        {...field}
        {...props}
        className="border-[#FFFFFF50] border-b-[1px] bg-transparent w-full placeholder:text-white source-sans px-1 tracking-wide pb-1 md:pb-3 outline-none text-white text-navbar2xl lg:text-descriptionHeading2xl"
        type={type}
        autoComplete={"new-password"}
        placeholder={placeholder}
      >
        {data.map((value: string) => (
          <option key={value} value={value} className="text-black ">
            {value}
          </option>
        ))}
      </select>
      <div className=" w-full flex justify-end roboto text-[13px] text-red-500 px-2 h-[1.25rem] lg:h-[1.5rem]">
        {meta.touched && meta.error ? <div>{meta.error}</div> : null}
        {!meta.error && serverError ? <div>{serverError}</div> : null}
      </div>
    </div>
  );
};

export default CustomDropdown;
