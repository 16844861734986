import OrangeGradientButton from "../button/OrangeGradientButton";
import NavbarDropdown from "./NavbarDropdown";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { useEffect, useState } from "react";
import SimpleSubNavbar from "./SimpleSubNavbar";
import ProductsDropdown from "./ProductsDropdown";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import ResponsiveDropdown from "./ResponsiveDropdown";
import CheckScreenSize from "../CheckScreenSize/CheckScreenSize";
import useAxiosInstance from "../../../Utils/UtilityFunctions/RequestConfig";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { setNavbarData } from "../../../Redux/CMSEssentials";
import { setLoadingState } from "../../../Redux/Loading-Slice";
import apiConfig from "../../../Utils/API/apiConfig";

const Navbar = () => {
  const [outOfView, setOutOfView] = useState(false);
  const [hamburgerPressed, setHamburgerPressed] = useState(false);
  const [toc1, setToc1] = useState<any>();
  const [toc2, setToc2] = useState<any>();
  const [toc3, setToc3] = useState<any>();
  const [toc4, setToc4] = useState<any>();

  const axiosInstance = useAxiosInstance();

  const navbarData = useSelector(
    (state: RootState) => state.CMSEssentials.navbarData
  );
  const dispatch = useDispatch();

  const fetchNavbarData = async () => {
    try {
      dispatch(setLoadingState(true));
      const response = await axiosInstance.get(apiConfig.Navbar.getDetails.api);
      response.data.data.map((attr: any, index: number) => {
        const temp = attr?.attributes || null;
        dispatch(setNavbarData(temp));
      });
      dispatch(setLoadingState(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (navbarData === null) {
      fetchNavbarData();
    }
  }, [navbarData]);

  useEffect(() => {
    if (
      navbarData?.navbarList &&
      Array.isArray(navbarData?.navbarList) &&
      navbarData?.navbarList.length > 0
    ) {
      const one_toc = navbarData?.navbarList.filter(
        (data: any) => data.sectionToc === 1
      );
      setToc1(one_toc[0]);

      const two_toc = navbarData?.navbarList.filter(
        (data: any) => data.sectionToc === 2
      );
      setToc2(two_toc[0]);

      const three_toc = navbarData?.navbarList.filter(
        (data: any) => data.sectionToc === 3
      );
      setToc3(three_toc[0]);

      const four_toc = navbarData?.navbarList.filter(
        (data: any) => data.sectionToc === 4
      );
      setToc4(four_toc[0]);
    }
  }, [navbarData]);

  const smView = CheckScreenSize(640);

  const navigate = useNavigate();

  const { scrollY } = useScroll();

  const handleScroll = () => {
    if (!smView) {
      if (scrollY.get() > 170) {
        setOutOfView(true);
      } else if (scrollY.get() < 100) {
        setOutOfView(false);
      }
    } else {
      if (scrollY.get() > 50) {
        setOutOfView(true);
      } else if (scrollY.get() < 60) {
        setOutOfView(false);
      }
    }
  };

  useMotionValueEvent(scrollY, "change", handleScroll);
  return (
    <>
      {/* To replace the empty space left behind by the navbar */}
      {outOfView ? <div className="w-full h-[48px] sm:h-[72px]"></div> : <></>}
      <div
        className={`w-full items-center ${
          outOfView
            ? "flyAnimation fixed left-0 top-0 z-50 bg-black  lg:py-4"
            : "lg:pt-7 mt-2 z-[10]"
        } flex justify-center ${outOfView ? "lg:flex" : "lg:flex"} `}
      >
        <div className="lg:justify-between w-full grid  max-w-[1920px] items-center source-sans text-white lg:mx-0 lg:flex lg:gap-y-6  lg:px-homepagePaddingLg xl:px-homepagePadding2xl 2xl:grid grid-cols-12">
          <div className="col-span-2 flex items-center justify-center h-12 lg:hidden lg:h-fit">
            <GiHamburgerMenu
              onClick={() => {
                setHamburgerPressed(!hamburgerPressed);
              }}
              className="no-hover-effect w-[20px] h-[20px] mt-[-5px]"
            />
          </div>
          <div
            className={`${
              outOfView
                ? "col-span-6 "
                : "col-span-10 lg:col-span-2 2xl:col-span-6"
            }  h-12 flex items-center justify-start lg:mt-1 lg:py-0 lg:justify-start lg:h-fit`}
          >
            <img
              onClick={() => {
                navigate("/");
              }}
              alt="logo"
              className="w-[100px] lg:w-[156px] cursor-pointer"
              src={`${process.env.REACT_APP_URL}${navbarData?.navbarLogo?.data?.attributes?.url}`}
            />
          </div>

          <div className="col-span-6 w-full pl-0 lg:pl-[20%] flex flex-row justify-between 2xl:pl-0 2xl:grid grid-cols-6">
            <div
              className={`col-span-2 hidden justify-start items-center gap-x-2 2xl:col-span-1 2xl:justify-end lg:flex`}
            >
              <NavbarDropdown
                totalLength={navbarData?.navbarList?.length}
                index={toc1?.sectionToc - 1}
                tab={toc1?.title}
                fullview={true}
                subMenu={
                  <ProductsDropdown navbarSubList={toc1?.navbarSubList} />
                }
              />
            </div>
            <div
              className={`col-span-2 hidden justify-start items-center gap-x-2 2xl:col-span-1 2xl:justify-end lg:flex`}
            >
              <NavbarDropdown
                totalLength={navbarData?.navbarList?.length}
                index={toc2?.sectionToc - 1}
                tab={toc2?.title}
                subMenu={
                  <SimpleSubNavbar
                    bigWidth={toc2?.isWidthLarge}
                    subMenu={toc2?.navbarSubList}
                  />
                }
              />
            </div>
            <div
              className={`col-span-2 hidden justify-start items-center gap-x-2 2xl:col-span-1 2xl:justify-end lg:flex`}
            >
              <NavbarDropdown
                totalLength={navbarData?.navbarList?.length}
                index={toc3?.sectionToc - 1}
                tab={toc3?.title}
                subMenu={
                  <SimpleSubNavbar
                    bigWidth={toc3?.isWidthLarge}
                    subMenu={toc3?.navbarSubList}
                  />
                }
              />
            </div>
            <div
              className={`col-span-2 hidden justify-start items-center gap-x-2 2xl:col-span-1 2xl:justify-end lg:flex`}
            >
              <NavbarDropdown
                totalLength={navbarData?.navbarList?.length}
                index={toc4?.sectionToc - 1}
                tab={toc4?.title}
                subMenu={
                  <SimpleSubNavbar
                    bigWidth={toc4?.isWidthLarge}
                    subMenu={toc4?.navbarSubList}
                  />
                }
              />
            </div>
            <div className="col-span-2 hidden justify-end items-center lg:flex">
              <OrangeGradientButton
                buttonText={navbarData?.buttonText}
                buttonFunction={() => {
                  navigate(navbarData?.buttonUrl);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {hamburgerPressed ? (
        <ResponsiveDropdown navList={navbarData?.navbarList} />
      ) : (
        <></>
      )}
    </>
  );
};

export default Navbar;
