import React from "react";
import {
  INumberingCardData,
  extra,
} from "../../../Interfaces/INumberRectagleCard";

const CardWithNumber = ({
  data,
  isLinearGrident,
}: {
  data: any[];
  isLinearGrident?: boolean;
}) => {
  return (
    <div className="3xl:flex 3xl:justify-center 3xl:items-center ">
      <div
        className={`flex flex-col gap-14 py-16 md:gap-24 md:px-homepagePadding2xl w-full 3xl:max-w-[1992px]  px-6 ${
          isLinearGrident && "linear_secound"
        } `}
      >
        {data?.map((item, index) => (
          <>
            <div
              className={
                index % 2 === 1 ? `flex w-full justify-end` : "justify-start"
              }
            >
              <LongCardView
                title={item.ImageDetail.title}
                paragraph={item.ImageDetail.description}
                ExtraBulletPoints={item.ExtraBulletPoints}
                ExtraCardData={item.ExtraCardData}
                index={index}
              />
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

const LongCardView = ({
  title,
  paragraph,
  index,
  ExtraBulletPoints,
  ExtraCardData,
}: {
  title: string;
  paragraph: string;
  index: number;
  ExtraBulletPoints?: any;
  ExtraCardData?: any;
}) => {
  return (
    <>
      <div className="w-[95%] mx-[1.38rem] md:w-[30rem] lg:w-[50rem]  py-4 pl-4  md:pl-20 relative border-[3px] text-white">
        <div className="text-buttonSm md:text-paragraphHomepage2xl font-semibold text-[#F58220] source-sans text-center md:text-left ">
          {title}
        </div>
        <div className="pr-4 pt-2 text-descriptionHeadingSm md:text-descriptionHeading2xl font-light source-sans font">
          <div>{paragraph}</div>

          {/* All array list */}
          {ExtraBulletPoints && (
            <div className="pt-3">
              {ExtraBulletPoints?.map((item: any, index: number) => (
                <React.Fragment key={index}>
                  <div>
                    <li>{item.bulletPoint}</li>
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}

          {/* All title and paragraph extra */}

          {ExtraCardData && (
            <div className="pt-4">
              {ExtraCardData?.map((item: any, index: number) => (
                <div key={index}>
                  <strong className="font-semibold">{item.title} </strong>
                  <span>{item.description}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="w-28 h-4 border-x-[10px] border-[#011A28] bg-gradient-to-b from-[#F28916] via-[#F2A937] to-[#F2BF4E] absolute bottom-0 right-5 -mb-[0.6rem]"></div>
        <div className="w-[3.5rem] h-[3.5rem] md:w-28 md:h-28 bg-gradient-to-b from-[#F28916] via-[#F2A937] to-[#F2BF4E] rounded-full absolute top-0 left-0 -mt-7 -ml-7 md:-mt-[3.5rem] md:-ml-[3.5rem] flex justify-center items-center font-black source-sans text-primaryHeadingSm md:text-primaryHeading2xl roboto text-white">
          {index < 9 ? "0" + (index + 1) : index + 1}
        </div>
      </div>
    </>
  );
};

export default CardWithNumber;
