import constants from "../../../Utils/Constants/constants";

const AboutUsLandingComponent = ({
  title,
  subtitle,
  video,
  extension,
}: any) => {
  return (
    <>
      <div className="flex h-full lg:justify-center lg:items-center roboto lg:pt-[1.5rem] xl:px-[0px]">
        <div className="flex justify-center h-full items-center w-full">
          <div className="w-full h-full flex items-center justify-center lg:h-full lg:w-[100%]">
            {constants.reusableConstants.videoFormats.includes(extension) ? (
              <video
                src={video}
                playsInline
                className="h-full w-full object-cover"
                autoPlay
                muted
                loop
                onContextMenu={(e) => e.preventDefault()}
              />
            ) : (
              <img className="h-[50%]" src={video} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsLandingComponent;
