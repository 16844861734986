import { CiLinkedin } from "react-icons/ci";
import TeamDetailsPopup from "./TeamDetailsPopup/TeamDetailsPopup";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const TeamSection = ({ sectionHeading, cardList }: any) => {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);

  return (
    <div className="w-full h-fit flex flex-col">
      <TeamDetailsPopup
        link={cardList[selectedIndex]?.attributes?.linkedinUrl}
        name={cardList[selectedIndex]?.attributes?.name}
        open={open}
        setOpen={setOpen}
        description={cardList[selectedIndex]?.attributes?.content}
        title={cardList[selectedIndex]?.attributes?.heading}
        image={`${process.env.REACT_APP_URL}${cardList[selectedIndex]?.attributes?.Profile?.data?.attributes?.url}`}
      />
      <div className="flex flex-col gap-y-[3.75rem]">
        <div className="flex flex-col gap-y-[1.563rem] items-center">
          <div className="text-orangePrimary roboto leading-normal font-black text-primaryHeadingSm lg:text-primaryHeading2xl">
            {sectionHeading}
          </div>
          <hr className="border-orangePrimary w-[4.438rem] border-[0.188rem] lg:w-[9.688rem] lg:border-[0.313rem]" />
        </div>
        <div className="w-full grid grid-cols-8 gap-x-[0.75rem] gap-y-[0.625rem] lg:gap-x-[1.563rem] lg:gap-y-[1.563rem] lg:grid-cols-12">
          {cardList.map((value: any, index: number) => (
            <>
              {Array.isArray(cardList) &&
              cardList.length % 3 !== 0 &&
              index + (1 % 3) === 1 ? (
                <div className="col-span-4 flex justify-center lg:col-span-12">
                  <div
                    key={value?.attributes?.name}
                    className="w-full relative overflow-hidden flex flex-col justify-end newLandingCardBG lg:w-1/3 "
                  >
                    <div className="w-full mb-[-4px] flex lg:pt-[3.125rem] justify-center lg:h-[27rem] xl:h-[32rem] 2xl:h-[42rem]">
                      <img
                        onClick={() => {
                          setSelectedIndex(index);
                          setOpen(true);
                        }}
                        src={`${process.env.REACT_APP_URL}${value?.attributes?.Profile?.data?.attributes?.url}`}
                        className="h-[10.753rem] grayscale hover:grayscale-0 cursor-pointer ease-in-out duration-100 lg:h-full lg:w-full lg:object-cover"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </div>
                    <div
                      onClick={() => {
                        setSelectedIndex(index);
                        setOpen(true);
                      }}
                      className="w-[98%] absolute mb-[0.625rem] flex flex-row h-fit py-[0.5rem] bg-[rgba(0,0,0,0.75)] px-[0.25rem] hover:bg-orangePrimary z-[2] lg:mb-[1.5rem] lg:px-[1.125rem] lg:py-[1.5rem]"
                    >
                      <div className="w-full flex flex-col">
                        <div className="text-descriptionHeadingSm leading-normal font-bold lg:text-paragraphHomepage2xl">
                          {value?.attributes?.name}
                        </div>
                        <div className="text-[8px] roboto font-semibold leading-normal lg:text-descriptionHeading2xl">
                          {value?.attributes?.heading}
                        </div>
                      </div>
                      <div className="w-fit flex  flex-1 flex-row h-full self-end items-end">
                        <Link
                          to={value?.attributes?.linkedinUrl}
                          target="_blank"
                        >
                          <CiLinkedin className="w-[0.625rem] h-[0.625rem] no-hover-effect lg:w-[1.563rem] lg:h-[1.563rem]" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  key={value?.attributes?.name}
                  className="col-span-4 relative overflow-hidden flex flex-col justify-end newLandingCardBG "
                >
                  <div className="w-full mb-[-4px] flex items-start lg:pt-[3.125rem] justify-center lg:h-[27rem] xl:h-[32rem] 2xl:h-[42rem]">
                    <img
                      onClick={() => {
                        setSelectedIndex(index);
                        setOpen(true);
                      }}
                      src={`${process.env.REACT_APP_URL}${value?.attributes?.Profile?.data?.attributes?.url}`}
                      className="h-[10.753rem] grayscale hover:grayscale-0 cursor-pointer ease-in-out duration-100 lg:h-full lg:w-full lg:object-cover"
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </div>
                  <div
                    onClick={() => {
                      setSelectedIndex(index);
                      setOpen(true);
                    }}
                    className="w-[98%] absolute mb-[0.625rem] flex flex-row h-fit py-[0.5rem] bg-[rgba(0,0,0,0.75)] px-[0.25rem] hover:bg-orangePrimary z-[2] lg:mb-[1.5rem] lg:px-[1.125rem] lg:py-[1.5rem]"
                  >
                    <div className="w-full flex flex-col">
                      <div className="text-descriptionHeadingSm leading-normal font-bold lg:text-paragraphHomepage2xl">
                        {value?.attributes?.name}
                      </div>
                      <div className="text-[8px] roboto font-semibold leading-normal lg:text-descriptionHeading2xl">
                        {value?.attributes?.heading}
                      </div>
                    </div>
                    <div className="w-fit flex  flex-1 flex-row h-full self-end items-end">
                      <Link to={value?.attributes?.linkedinUrl} target="_blank">
                        <CiLinkedin className="w-[0.625rem] h-[0.625rem] no-hover-effect lg:w-[1.563rem] lg:h-[1.563rem]" />
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
