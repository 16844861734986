import React from "react";
import { pillarCard, pillarCardBody } from "../../../Interfaces/Interface";

interface PillarCardsProps {
  initialHeading: string;
  cardData: any;
  isLinearGridiant?: boolean;
}

const PillarCards = ({
  initialHeading,
  cardData,
  isLinearGridiant,
}: PillarCardsProps) => {
  return (
    <div
      className={`flex bg-bluePrimary ${
        isLinearGridiant && "linear_secound"
      } justify-center`}
    >
      <div
        className={` w-full min-h-screen max-w-[1920px] 3xl:min-h-fit py-primaryPaddingYSm px-homepagePaddingSm sm:py-primaryPaddingY2xl sm:px-4 md:px-0 pb-20 `}
      >
        <div className=" w-full flex justify-center flex-col items-center gap-8">
          {/* header */}
          <div className="text-homepagePaddingSm md:self-center md:text-center text-white text-lg roboto pb-0 sm:pb-14 md:w-[30rem] md:text-3xl lg:text-4xl xl:text-primaryHeading2xl 2xl:text-primaryHeading2xl font-black  2xl:leading-[3.25rem]  ">
            {initialHeading}
          </div>
          <div className="flex w-full  items-center justify-center gap-x-[1rem] lg:gap-x-[3rem] source-sans flex-col gap-y-10 sm:gap-y-0 sm:flex-row">
            {/* Loop for the number of cards */}
            {cardData?.map((content: pillarCard, index: number) => (
              <div className=" flex flex-col border-2 border-white items-center   gap-y-4 w-full py-7 px-2 sm:px-10 sm:py-28 sm:w-[27%] lg:w-[19%]">
                <img
                  src={`${process.env.REACT_APP_URL}${content?.attributes?.image.data.attributes.url}`}
                  className="w-[4rem] h-[4rem]"
                />
                <div className=" text-white font-bold text-primaryHeadingSm sm:text-[30px]">
                  {content?.attributes?.head}
                </div>
                <div className="flex flex-col gap-y-8 items-center">
                  {/* Loop for the content inside the card */}
                  {content?.attributes?.CardData?.map(
                    (data: pillarCardBody, index: number) => (
                      <div className="flex flex-col">
                        <div className=" text-orangePrimary font-bold text-center text-buttonSm sm:text-paragraphHomepage2xl">
                          {data.title}
                        </div>
                        <div className="text-white font-light text-center text-descriptionHeadingSm sm:text-descriptionHeading2xl">
                          {data.description}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PillarCards;
