import Popup from "reactjs-popup";
import { CiLinkedin } from "react-icons/ci";
import { Link } from "react-router-dom";
import whiteBG from "../../../../Images/white2.jpg";

const TeamDetailsPopup = ({
  name,
  title,
  image,
  description,
  open,
  link,
  setOpen,
}: any) => {
  const modalOverlayStyle = {
    backdropFilter: "blur(3px)",
  };

  const popupBody: any = (close: () => void) => {
    return (
      <div className="flex flex-col lg:flex-row lg:overflow-hidden items-end w-[20rem] justify-end bg-white h-[24.379rem] lg:px-[0] lg:min-h-[70vh] lg:w-[85vw] 2xl:w-[70vw] 3xl:min-h-[60rem]">
        <div
          style={{
            backgroundImage: `url(${whiteBG})`,
            backgroundSize: "cover",
          }}
          className="w-full justify-center lg:w-[40%]  h-full flex items-end lg:justify-end"
        >
          <img
            alt="person"
            src={image}
            className=" h-[12rem] lg:h-fit lg:w-[100%]"
          />
        </div>
        <div className="w-full lg:w-[60%] bg-orangePrimary flex h-full justify-center flex-col z-[10]">
          <div className="text-center flex flex-col px-[2rem]">
            <div className="text-descriptionHeadingSm font-bold lg:text-paragraphHomepage2xl">
              {name}
            </div>
            <div className="roboto text-[8px] font-semibold roboto lg:text-descriptionHeading2xl">
              {title}
            </div>
            <div className="text-center text-[8px] font-semibold mt-[0.313rem] lg:mt-[2.188rem]  lg:text-descriptionHeading2xl">
              {description}
            </div>
          </div>
          <div className="h-[1.125rem] w-full flex justify-end px-[0.625rem] lg:px-[1.25rem] lg:h-[3.063rem]">
            <Link to={link} target="_blank">
              <CiLinkedin className="w-[1rem] h-[1rem] no-hover-effect lg:w-[1.875rem] lg:h-[1.875rem]" />
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Popup
      modal
      overlayStyle={modalOverlayStyle}
      closeOnDocumentClick={true}
      lockScroll={true}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      {popupBody}
    </Popup>
  );
};

export default TeamDetailsPopup;
