import React from "react";
import NonBorderButton from "../../Homepage/MainLanding/NonBorderButton";
import { CheckWords } from "../../../Utils/UtilityFunctions/UtilityFunction";
import { Link } from "react-router-dom";
interface CardItemProps {
  className?: string;
  title: string;
  description: string;
  link: string;
}

const CardItem: React.FC<CardItemProps> = ({ title, description, link }) => {
  const wordArr = CheckWords(title);
  return (
    <div className="col-span-4 flex justify-center py-7 md:py-10 rounded-md shadow-lg bg-bluePrimary h-full">
      <div className="md:w-[240px] md:h-96 flex flex-col justify-between  overflow-hidden ">
        <div className="text-[16px] font-semibold px-16  md:px-0 md:font-bold md:text-paragraphHomepage2xl text-orangePrimary text-center h-22">
          {CheckWords(title).length === 2 ? (
            <>
              {wordArr[0]}
              <br /> {wordArr[1]}
            </>
          ) : (
            <>{title}</>
          )}
        </div>
        <div className="text-white text-center text-descriptionHeadingSm font-300 md:text-descriptionHeading2xl  md:leading-8 overflow-clip  source-sans font-light px-[1.6rem] md:px-0 py-9 md:py-0 md:h-56 ">
          {description}
        </div>
        <Link
          to={link}
          aria-label={`Learn more about ${title}`}
          title={`Learn more about ${title}`}
        >
          <div className="flex justify-center">
            <NonBorderButton
              buttonText="READ MORE"
              buttonFunction={() => {}}
              textColor="orange"
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CardItem;
