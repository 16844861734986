import { MainLandingDetailsProps } from "../../../Interfaces/Interface";
import NonBorderButton from "./NonBorderButton";

const MainLandingCard: React.FC<MainLandingDetailsProps> = ({
  heading,
  description,
  buttonText,
  textColor,
  buttonFunction,
  path
}) => {
  return (
    <div className="col-span-12 flex flex-col justify-between bg-[rgba(217,217,217,0.5)] h-full source-sans mainLandingCard px-6 py-6 lg:px-[22px] lg:col-span-4 ">
      <div className="w-full text-[#F58220] tracking-widest max-h-[3.5rem] line-clamp-2 text-descriptionHeadingSm h-fit lg:text-xl xl:h-fit ">
        {heading}
      </div>
      <div className="flex flex-col">
        <div className="w-full text-white font-semibold text-buttonSm truncate h-[70px] lg:mt-5 lg:text-button2xl lg:h-[100px]">
          {description}
        </div>
        <div className={`w-full lg:text-[18px]`}>
          <NonBorderButton buttonText={buttonText} buttonFunction={buttonFunction} path={path}/>
        </div>
      </div>
    </div>
  );
};

export default MainLandingCard;
