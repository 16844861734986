import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../Redux/Loading-Slice";
import { setStatusCode } from "../../Redux/Error-Slice";
import { useNavigate } from "react-router-dom";

const useAxiosInstance = () => {
  const navigate = useNavigate();
  // Create an instance of Axios with custom configuration
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    // Other custom configurations...
  });

  // Set default headers for different methods
  axiosInstance.defaults.headers.get[
    "Authorization"
  ] = `Bearer ${process.env.REACT_APP_AC_TOKEN}`;
  axiosInstance.defaults.headers.post[
    "Authorization"
  ] = `Bearer ${process.env.REACT_APP_POST_TOKEN}`;

  // Create a dispatch function
  const dispatch = useDispatch();

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    function (config) {
      // Check if the request method is GET and set the appropriate token
      if (config.method === "get") {
        config.headers.Authorization = `Bearer ${process.env.REACT_APP_AC_TOKEN}`;
      } else if (config.method === "post") {
        config.headers.Authorization = `Bearer ${process.env.REACT_APP_POST_TOKEN}`;
      }

      return config;
    },
    function (error) {
      const statusCode = error.response.status;
      dispatch(setStatusCode(statusCode));
      navigate("/error");
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.config && error?.config?.method === "get") {
        const statusCode = error?.response?.status || 500;
        dispatch(setLoadingState(false));
        dispatch(setStatusCode(statusCode));
        navigate("/error");
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxiosInstance;
