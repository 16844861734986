import { useField } from "formik";
import { ChangeEvent } from "react";
import Captcha from "../Captcha/Captcha";

const SingleCheckbox: React.FC<any> = ({
  checkboxText,
  setCaptchaToken,
  captchaReset,
  serverError,
  disclaimer,
  type,
  uniqueName,
  requiredLine = true,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <div className="flex justify-start flex-col">
      <span className="mt-2 pl-4"></span>
      {requiredLine && <hr className="border-white border-opacity-[0.31]" />}
      <div className="flex flex-col mt-6 overflow-hidden w-full items-center">
        {serverError &&
        "field" in serverError &&
        serverError.field === "captchaToken" &&
        serverError.error ? (
          <div className=" w-full flex justify-center roboto text-[13px] text-red-500 px-2 h-[1.25rem] lg:h-[1.5rem]">
            {serverError.error}
          </div>
        ) : (
          <></>
        )}
        <Captcha onVerify={setCaptchaToken} captchaToken={captchaReset} />
      </div>
      <div className="flex justify-start items-center gap-4  md:gap-5 pl-2 md:pl-0  mt-6">
        <input
          {...field}
          {...props}
          checked={field.value}
          id="newsletter"
          type="checkbox"
          className={`w-5 h-5 ${
            type === "home"
              ? "lg:w-[20px] lg:h-[20px]"
              : "lg:w-[30px] lg:h-[30px]"
          }`}
        />
        <label htmlFor="newsletter" className="max-sm:flex-1 text-[15px]">
          {checkboxText}
        </label>
      </div>
      <span
        className="text-white text-opacity-60 w-full pt-[0.938rem] md:pt-3 text-[14px] md:text-[15px] leading-6"
        style={{ fontSize: "clamp(15px, 0.6vw, 1rem)" }}
      >
        {disclaimer}
      </span>
    </div>
  );
};

export default SingleCheckbox;
