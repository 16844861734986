import { useNavigate } from "react-router-dom";
import { curvedDivProps } from "../../../Interfaces/Interface";
import { FaCircle } from "react-icons/fa6";

const CurvedDiv: React.FC<curvedDivProps> = ({
  labelDescription,
  boxHeading,
  boxDescription,
  image,
  index,
  route,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {(index + 1) % 3 === 0 ? (
        // for third
        <div className="w-full">
          <div className="w-full justify-start lg:mt-[2rem] hidden lg:flex">
            <div className="w-[30rem] flex justify-center ">
              <div
                onClick={() => {
                  navigate(route);
                }}
                className="w-[17.7rem] growAnimationCurvedDiv h-[10.2rem] rotate-[150deg] mt-[-5%] curvedDivIMG flex justify-center items-center"
              >
                <img
                  src={image}
                  className="w-[5rem] h-[5.5rem] rotate-[-150deg]"
                />
              </div>
            </div>
            <div className="w-[33.4%] "></div>
          </div>
          <div className="w-full  flex justify-start mt-[0] lg:mt-[-1rem]">
            <div className="w-[30rem] flex-row items-start justify-end hidden lg:flex">
              <div className="w-[1rem] h-[50%] flex items-center justify-end">
                <div className="w-[2px] h-full mt-[1px] bg-white"></div>
              </div>
              <div className="w-[40%] h-full justify-end items-center hidden lg:flex">
                <div className="w-full h-[2px] bg-white"></div>
              </div>
            </div>
            <div
              onClick={() => {
                navigate(route);
              }}
              className="flex relative justify-start items-center curvedDivBoxHover border-2 mt-[-5px] text-center source-sans lg:w-[42%]"
            >
              <FaCircle className="absolute h-[18px] ml-[-10px] w-[18px] text-white hidden lg:flex z-[10]" />
              <div className="w-full flex flex-col px-[0.63rem] gap-y-[0.63rem] py-[1.9rem] lg:gap-y-5 lg:px-16">
                <img
                  src={image}
                  className="w-[3.5rem] h-[4rem] self-center flex lg:hidden"
                />
                <div className="text-orangePrimary font-semibold text-buttonSm lg:text-paragraphHomepage2xl">
                  {boxHeading}
                </div>
                <div className="text-center text-white font-light text-descriptionHeadingSm  lg:text-descriptionHeading2xl">
                  {boxDescription}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (index + 1) % 2 === 0 ? (
        // for second
        <div className="w-full flex justify-start">
          <div className="text-primaryHeading2xl roboto h-full items-center text-white font-black w-[14rem] leading-none text-center hidden lg:flex">
            {labelDescription}
          </div>
          <div className="w-fit items-center growAnimationCurvedDiv hidden lg:flex">
            <div
              onClick={() => {
                navigate(route);
              }}
              className="w-[17.7rem] h-[10.2rem] rotate-[90deg]  curvedDivIMG flex justify-center items-center ml-[1rem] lg:ml-0"
            >
              <img
                src={image}
                className="w-[5rem] rotate-[-90deg] h-[5.5rem]  "
              />
            </div>
          </div>

          <div className="w-[10rem] ml-[-4rem] items-center  hidden lg:flex">
            <div className="bg-white w-full h-[2px]"></div>
          </div>
          <div
            onClick={() => {
              navigate(route);
            }}
            className=" flex justify-start items-center curvedDivBoxHover border-2  relative  text-center source-sans w-full lg:w-[42%]"
          >
            <FaCircle className="absolute h-[18px] ml-[-10px] w-[18px] text-white hidden lg:flex z-[10]" />
            <div className="w-full flex flex-col px-[0.63rem] py-5 gap-y-[0.63rem] lg:gap-y-5 lg:px-16">
              <img
                src={image}
                className="w-[3.5rem] h-[4rem] self-center flex lg:hidden"
              />
              <div className="text-orangePrimary font-semibold text-buttonSm lg:text-paragraphHomepage2xl">
                {boxHeading}
              </div>
              <div className="text-center text-white font-light text-descriptionHeadingSm  lg:text-descriptionHeading2xl">
                {boxDescription}
              </div>
            </div>
          </div>
        </div>
      ) : (
        // for first
        <div className="w-full">
          <div className="w-full  flex justify-start">
            <div className="w-[28rem] flex-row items-end justify-end hidden lg:flex">
              <div className="w-[1rem] h-[50%] flex items-start justify-end">
                <div className="w-[2px] h-full mt-[-1px] bg-white"></div>
              </div>
              <div className="w-[40%] h-full flex justify-end items-center">
                <div className="w-full h-[2px] bg-white"></div>
              </div>
            </div>
            <div
              onClick={() => {
                navigate(route);
              }}
              className=" flex curvedDivBoxHover justify-start items-center border-2  text-center source-sans w-full  lg:w-[42%]"
            >
              <FaCircle className="absolute h-[18px] ml-[-10px] w-[18px] text-white hidden lg:flex z-[10]" />
              <div className="w-full flex flex-col px-[0.63rem] mt-[-5px] py-5 gap-y-[0.63rem] lg:gap-y-5 lg:px-16">
                <img
                  src={image}
                  className="w-[3.5rem] h-[4rem] self-center flex lg:hidden"
                />
                <div className=" text-orangePrimary font-semibold text-buttonSm lg:text-paragraphHomepage2xl">
                  {boxHeading}
                </div>
                <div className="text-center  text-white font-light text-descriptionHeadingSm  lg:text-descriptionHeading2xl">
                  {boxDescription}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full justify-start hidden lg:flex">
            <div className="w-[28rem] flex justify-center">
              <div
                onClick={() => {
                  navigate(route);
                }}
                className="w-[17.7rem] h-[10.2rem] rotate-[30deg] growAnimationCurvedDiv mt-[-5%] curvedDivIMG flex justify-center items-center"
              >
                <img
                  src={image}
                  className="w-[5rem] h-[5.5rem] rotate-[-30deg]"
                />
              </div>
            </div>
            <div className="w-[33.4%] "></div>
          </div>
        </div>
      )}
    </>
  );
};

export default CurvedDiv;
