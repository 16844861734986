import { useEffect, useState } from "react";
import AssistPage from "../../Components/Homepage/AssistPage/AssistPage";
import Honeycomb from "../../Components/Homepage/Honeycomb/Honeycomb";
import MainLandingComponent from "../../Components/Homepage/MainLanding/MainLandingComponent";
import HomepageCounterComponent from "../../Components/Homepage/HomepageCounterComponent/HomepageCounterComponent";
import { useDispatch } from "react-redux";
import PageFooter from "../../Components/common/Footer/PageFooter";
import { useLocation } from "react-router-dom";
import SecuritySolutions from "../../Components/Homepage/SecuritySolutions/SecuritySolutions";
import useAxiosInstance from "../../Utils/UtilityFunctions/RequestConfig";
import { setLoadingState } from "../../Redux/Loading-Slice";
import GetStartedComponent from "../../Components/FormComponents/GetStarted/GetStartedComponent";
import apiConfig from "../../Utils/API/apiConfig";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Homepage = () => {
  const location = useLocation();

  const [landingData, setLandingData] = useState<any>([]);
  const [toc1, setToc1] = useState<any>();
  const [toc2, setToc2] = useState<any>();
  const [toc3, setToc3] = useState<any>();
  const [toc4, setToc4] = useState<any>();
  const [toc5, setToc5] = useState<any>();
  const [toc6, setToc6] = useState<any>();
  const [toc7, setToc7] = useState<any>();
  const [blogDetails, setBlogDetails] = useState<any>();
  const [pressReleaseDetails, setPressReleaseDetails] = useState<any>();
  const [advisoryReportDetails, setAdvisoryReportDetails] = useState<any>();

  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();

  const fetchLandingData = async () => {
    try {
      const blogResponse = axiosInstance.get(
        apiConfig.Blog.getRecentIDDetails.api
      );

      const pressResponse = axiosInstance.get(
        apiConfig.PressRelease.getRecentIDDetails.api
      );

      const advisoryResponse = axiosInstance.get(
        apiConfig.AdvisoryReports.getRecentIDDetails.api
      );

      dispatch(setLoadingState(true));

      const response = axiosInstance.get(apiConfig.Homepage.getDetails.api);

      const Result = await Promise.all([
        response,
        blogResponse,
        pressResponse,
        advisoryResponse,
      ]);

      Result[0].data.data.map((attr: any, index: number) => {
        const landingData = attr.attributes.landingPage;
        setLandingData(landingData);
      });

      Result[1].data?.data?.attributes?.data?.results.map(
        (attr: any, index: number) => {
          const blogData = attr;
          setBlogDetails(blogData);
        }
      );
      Result[2].data?.data?.attributes?.data?.results.map(
        (attr: any, index: number) => {
          const pressData = attr;
          setPressReleaseDetails(pressData);
        }
      );
      Result[3].data?.data?.attributes?.data?.results.map(
        (attr: any, index: number) => {
          const advisoryData = attr;
          setAdvisoryReportDetails(advisoryData);
        }
      );
      dispatch(setLoadingState(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLandingData();
  }, []);

  useEffect(() => {
    if (landingData && landingData.length > 0) {
      const one_toc = landingData?.filter((data: any) => data.sectionToc === 1);

      setToc1(one_toc[0]);

      const two_toc = landingData?.filter((data: any) => data.sectionToc === 2);
      setToc2(two_toc[0]);

      const three_toc = landingData?.filter(
        (data: any) => data.sectionToc === 3
      );
      setToc3(three_toc[0]);

      const four_toc = landingData?.filter(
        (data: any) => data.sectionToc === 4
      );
      setToc4(four_toc[0]);

      const five_toc = landingData?.filter(
        (data: any) => data.sectionToc === 5
      );
      setToc5(five_toc[0]);

      const six_toc = landingData?.filter((data: any) => data.sectionToc === 6);
      setToc6(six_toc[0]);

      const seven_toc = landingData?.filter(
        (data: any) => data.sectionToc === 7
      );
      setToc7(seven_toc[0]);
    }
  }, [landingData]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Vairav Technology | Leading Cybersecurity Operation Center | #US #Asia
          #Australia #Singapore
        </title>
        <meta
          name="description"
          content="At Vairav Tech, we view cybersecurity as a fundamental right. Our mission is to provide individuals, businesses, and organizations with comprehensive security solutions to protect the ever-evolving digital landscape."
        />
      </Helmet>
      <div className="flex bg-bluePrimary overflow-hidden w-full">
        <div className={`flex-col  homepageLayout enableBlur w-full`}>
          <MainLandingComponent
            backgroundImage={`${process.env.REACT_APP_URL}${toc1?.background?.data?.attributes?.url}`}
            backgroundType={toc1?.background?.data?.attributes?.ext}
            buttonText={toc1?.buttonText}
            buttonURL={toc1?.buttonUrl}
            animationVisibility={toc1?.animationVisibility}
            landingDetails={toc1?.carosel}
            blogDetails={blogDetails}
            advisoryReportDetails={advisoryReportDetails}
            pressReleaseDetails={pressReleaseDetails}
          />
          <HomepageCounterComponent
            heading={toc2?.title}
            subHeading={toc2?.subTitle}
            counterDetails={toc2?.numberCount}
            primaryBox={toc2?.boxText}
            secondaryBox={toc2?.buttonText}
          />
          <SecuritySolutions
            heading={toc3?.heading}
            cardDetails={toc3?.Biscuits}
          />
          <Honeycomb toc={toc4} tocSecound={toc5} />
          <AssistPage
            image={
              toc6?.topSection?.image?.data
                ? `${process.env.REACT_APP_URL}${toc6?.topSection?.image?.data?.attributes?.url}`
                : ""
            }
            title={toc6?.topSection?.title}
            description={toc6?.topSection?.description}
            card={toc6?.buttonSection}
          />
          <GetStartedComponent data={toc7} pageRoute={location.pathname} />
          <PageFooter />
        </div>
      </div>
    </HelmetProvider>
  );
};

export default Homepage;
