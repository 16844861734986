import React from "react";
import tridentImage from "../../../Images/trident-white.svg";
import { Link, useNavigate } from "react-router-dom";
const HomepageCard = ({
  title,
  subTitle,
  ButtonText,
  ButtonUrl,
  image,
}: {
  title: string;
  subTitle: string | null;
  ButtonText: string;
  ButtonUrl: string;
  image: string;
}) => {
  const navigate = useNavigate();
  return (
    <div className="lg:w-[55rem] lg:h-[50rem] flex justify-center items-center flex-col gap-6 mt-[3.125rem] bg-[#09202E] shadow-md py-8 lg:py-0">
      <img
        src={image}
        alt="trident"
        className="w-40 lg:w-[36rem] md:-mt-12"
        height={"auto"}
      />
      <div className=" flex flex-col justify-center items-center gap-4 py-7">
        <div className="text-primaryHeadingSm font-bold lg:text-primaryHeading2xl roboto text-orangeSecondary">
          {title}
        </div>
        <div className="text-xs leading-normal lg:text-paragraphHomepage2xl font-normal w-full lg:w-[78%]">
          {subTitle}
        </div>
      </div>
      <Link to={ButtonUrl}>
        <div className="border-[2px] source-sans lg:text-paragraphHomepage2xl border-orangeSecondary px-14 py-3 rounded-sm cursor-pointer z-30">
          {ButtonText}
        </div>
      </Link>
    </div>
  );
};

export default HomepageCard;
