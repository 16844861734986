import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/common/Navbar/Navbar";
import PageFooter from "../../../Components/common/Footer/PageFooter";
import AboutUsLandingComponent from "../../../Components/Company/AboutUsComponents/AboutUsLandingComponent";
import AboutUsClients from "../../../Components/Company/AboutUsComponents/AboutUsClients";
import { useNavigate } from "react-router-dom";
import useAxiosInstance from "../../../Utils/UtilityFunctions/RequestConfig";
import AboutUsVideoBanner from "../../../Components/Company/AboutUsComponents/AboutUsVideoBanner";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../../Redux/Loading-Slice";
import VairavTimeline from "../../../Components/Company/AboutUsComponents/VairavTimeline";
import apiConfig from "../../../Utils/API/apiConfig";

const AboutUs = () => {
  const navigate = useNavigate();

  const [aboutUsData, setAboutUsData] = useState([]);

  const [toc1, setToc1] = useState<any>();
  const [toc2, setToc2] = useState<any>();
  const [toc3, setToc3] = useState<any>();
  const [toc4, setToc4] = useState<any>();

  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();

  const fetchAboutUsData = async () => {
    try {
      dispatch(setLoadingState(true));
      const response = await axiosInstance.get(
        apiConfig.Form.getAboutUsDetails.api
      );

      response.data.data.map((attr: any, index: number) => {
        const aboutUsData = attr?.attributes?.aboutUs;
        setAboutUsData(aboutUsData);
      });
      dispatch(setLoadingState(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAboutUsData();
  }, []);

  useEffect(() => {
    if (aboutUsData && aboutUsData.length > 0) {
      const one_toc = aboutUsData.filter((data: any) => data.sectionToc === 1);
      setToc1(one_toc[0]);

      const two_toc = aboutUsData.filter((data: any) => data.sectionToc === 2);
      setToc2(two_toc[0]);

      const three_toc = aboutUsData.filter(
        (data: any) => data.sectionToc === 3
      );
      setToc3(three_toc[0]);

      const four_toc = aboutUsData.filter((data: any) => data.sectionToc === 4);
      setToc4(four_toc[0]);
    }
  }, [aboutUsData]);

  return (
    <div className="flex bg-bluePrimary overflow-hidden w-full">
      <div className={`flex-col mainPlastic homepageLayout enableBlur w-full`}>
        <div className="flex flex-col lg:h-screen">
          <Navbar />
          <AboutUsLandingComponent
            title={toc1?.Header?.title}
            subtitle={toc1?.Header?.description}
            extension={toc1?.Header?.image?.data?.attributes?.ext}
            video={`${process.env.REACT_APP_URL}${toc1?.Header?.image?.data?.attributes?.url}`}
          />
        </div>
        <VairavTimeline
          primaryHeading={toc2?.card?.title}
          primaryDescription={toc2?.card?.description}
          secondaryHeading={toc2?.heading}
          secondaryDescription={toc2?.content}
          detailsArr={toc2?.TimelineHexagon}
          backgroundImage={`${process.env.REACT_APP_URL}${toc2?.backgroundImage?.data?.attributes?.url}`}
        />
        <AboutUsVideoBanner
          title={toc3?.title}
          subtitle={toc3?.heading}
          description={toc3?.content}
          extension={
            toc3?.images?.data !== null
              ? toc3?.images?.data[0]?.attributes?.ext
              : ""
          }
          video={`${process.env.REACT_APP_URL}${
            toc3?.images?.data !== null
              ? toc3?.images?.data[0]?.attributes?.url
              : ""
          }`}
        />
        <AboutUsClients icons={toc4?.images?.data} heading={toc4?.title} />
        <PageFooter />
      </div>
    </div>
  );
};

export default AboutUs;
