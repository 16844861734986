import OrangeGradientButton from "../../common/button/OrangeGradientButton";
import { MainLandingDetailsProps } from "../../../Interfaces/Interface";
import { AnimationControls, motion, useAnimationControls } from "framer-motion";

const MainLandingDetails: React.FC<
  MainLandingDetailsProps & { appearControls?: AnimationControls }
> = ({
  heading,
  description,
  buttonText,
  buttonFunction,
  changed,
  appearControls,
}) => {
  return (
    <>
      <motion.div
        animate={appearControls}
        className="flex flex-col w-full min-h-[220px] mt-[50px] lg:min-h-[325px] pb-8 gap-y-8 lg:mt-40 lg:w-7/12 lg:gap-y-[1.25rem] "
      >
        <div className="w-full text-white font-black text-primaryHeadingSm text-center roboto lg:text-start lg:text-primaryHeading2xl lg:leading-[3.25rem]">
          {heading}
        </div>
        <div className="w-full text-white source-sans text-descriptionHeadingSm text-center lg:text-start lg:text-paragraphHomepage2xl ">
          {description}
        </div>
      </motion.div>
      <OrangeGradientButton
        buttonText={buttonText}
        buttonFunction={buttonFunction}
      />
    </>
  );
};

export default MainLandingDetails;
