import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface CMSEssentialsState {
  navbarData: any;
  footerData: any;
  popupData: any;
}

// Define the initial state using that type
const initialState: CMSEssentialsState = {
  navbarData: null,
  footerData: null,
  popupData: null,
};

export const CMSEssentialsSlice = createSlice({
  name: "Authorization",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setNavbarData: (state, action: PayloadAction<any>) => {
      state.navbarData = action.payload;
    },
    setFooterData: (state, action: PayloadAction<any>) => {
      state.footerData = action.payload;
    },
    setPopupData: (state, action: PayloadAction<any>) => {
      state.popupData = action.payload;
    },
  },
});

export const { setNavbarData, setFooterData, setPopupData } =
  CMSEssentialsSlice.actions;

export default CMSEssentialsSlice.reducer;
