import * as yup from "yup";
import { checkForPhoneNumbers } from "../../Utils/UtilityFunctions/UtilityFunction";

export const advisoryFormSchema = yup.object({
  firstName: yup.string().required("* Required"),
  lastName: yup.string().required("* Required"),
  emailAddress: yup
    .string()
    .email("Invalid email address")
    .required("* Required"),
  jobTitle: yup.string().required("* Required"),
  phoneNumber: yup
    .string()
    .required("* Required")
    .min(7, "Minimum 7 numbers required")
    .max(22, "Maximum 22 numbers allowed")
    .test("isNumber", "Invalid phone number", checkForPhoneNumbers),
  companyName: yup.string().required("* Required"),
  country: yup.string().required("* Required"),
  requiresEmailsNewsletters: yup.boolean(),
});
