import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Navbar from "../../../../Components/common/Navbar/Navbar";
import PageFooter from "../../../../Components/common/Footer/PageFooter";
import PaginationControl from "../../../../Components/UtilityComponents/Pagination/PaginationControl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import axios from "axios";
import { setNoOfPages } from "../../../../Redux/Pagination-Slice";
import {
  getPagesArray,
  replaceSpace,
} from "../../../../Utils/UtilityFunctions/UtilityFunction";
import useAxiosInstance from "../../../../Utils/UtilityFunctions/RequestConfig";
import operationIMG from "../../../Images/Operation.svg";
import { setLoadingState } from "../../../../Redux/Loading-Slice";
import BlogCard from "../../../../Components/common/BlogComponents/BlogCard";
import apiConfig from "../../../../Utils/API/apiConfig";

const PressReleasePage = () => {
  const navigate = useNavigate();
  const pageState = useSelector((state: RootState) => state.pagination);
  const dispatch = useDispatch();

  const [pressReleaseData, setPressReleaseData] = useState<any>([]);
  const [pressReleaseCMS, setPressReleaseCMS] = useState<any>([]);
  const [toc1, setToc1] = useState<any>();

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    GetPressReleaseDetails();
  }, [pageState.currentPage, pageState.recordsPerPage]);

  useEffect(() => {
    GetPressReleaseDetails(1);
  }, []);

  const GetPressReleaseDetails = async (initialPage?: number) => {
    try {
      dispatch(setLoadingState(true));
      const response = await axiosInstance.get(
        apiConfig.PressRelease.getDetails.api(
          initialPage ? initialPage : pageState.currentPage,
          pageState.recordsPerPage
        )
      );
      let tempPressReleaseData = [];
      tempPressReleaseData = response?.data?.data || [];
      setPressReleaseData(tempPressReleaseData);
      dispatch(
        setNoOfPages(
          getPagesArray(
            pageState.recordsPerPage,
            response?.data?.data?.attributes?.data?.pagination?.total
          )
        )
      );
      dispatch(setLoadingState(false));
    } catch {}
  };

  useEffect(() => {
    if (pressReleaseCMS && pressReleaseCMS.length > 0) {
      const one_toc = pressReleaseCMS?.filter(
        (data: any) => data.sectionToc === 1
      );
      setToc1(one_toc[0]);
    }
  }, [pressReleaseCMS]);

  return (
    <div className="flex bg-bluePrimary overflow-hidden w-full">
      <div
        className={`flex flex-col items-center mainPlastic homepageLayout 3xl:min-h-screen 3xl:justify-between enableBlur w-full`}
      >
        <Navbar />
        <div className="w-full flex flex-col px-homepagePaddingSm max-w-[1920px] py-[3.125rem] gap-y-[3.125rem] lg:py-[6.25rem] lg:gap-y-[6.25rem] lg:px-homepagePaddingLg xl:px-homepagePadding2xl">
          <div className=" flex justify-center roboto font-black  text-white text-primaryHeadingSm lg:text-primaryHeading2xl">
            PRESS RELEASE
          </div>
          <div className="w-full grid gap-5 grid-cols-1 lg:grid-rows-[repeat(8,_minmax(15rem,_auto))] lg:grid-cols-3">
            {pressReleaseData?.attributes?.data?.results?.map((value: any) => (
              <BlogCard
                key={value?.id}
                date={value?.createdAt}
                image={
                  value?.media?.url ||
                  pressReleaseData?.attributes?.defaultImage?.url ||
                  ""
                }
                cardType="pressRelease"
                path={`/company/press-releases/${replaceSpace(value?.title)}/${
                  value?.id
                }`}
                buttonText={"READ MORE"}
                heading={value?.title}
              />
            ))}
          </div>
          <PaginationControl />
        </div>
        <PageFooter />
      </div>
    </div>
  );
};

export default PressReleasePage;
