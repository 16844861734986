import { OrangeButtonProps } from "../../../Interfaces/Interface";

const OrangeGradientButton: React.FC<OrangeButtonProps> = ({
  buttonSize,
  buttonText,
  buttonFunction = () => {},
  buttonType = "button",
  textSize,
}) => {
  return (
    <button
      onClick={() => {
        buttonFunction();
      }}
      type={buttonType}
      className={`orangePrimaryGradient source-sans text-white font-semibold rounded-sm hover:scale-110 ease-in duration-150 text-buttonSm w-[170px] py-2 lg:text-[16px] lg:px-0  lg:py-[10px] lg:w-[182px]`}
      style={{ width: buttonSize!, fontSize: textSize }}
    >
      {buttonText}
    </button>
  );
};

export default OrangeGradientButton;
