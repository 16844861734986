const reusableConstants = {
  recordsPerPage: 8 * 3,
  imageFormats: [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".bmp",
    ".tiff",
    ".svg",
    ".webp",
    ".ico",
    ".heic",
    ".pdf",
    ".psd",
    ".raw",
    ".ai",
    ".eps",
    ".indd",
  ],
  videoFormats: [".mp4", ".webm", ".ogv"],
};

Object.freeze(reusableConstants);

export default { reusableConstants };
