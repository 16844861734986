import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { queries } from "@testing-library/react";

const ClickToTop = () => {
  const [outOfView, setOutOfView] = useState(false)
  const [closest, setClosest] = useState("sm")

  const { scrollY } = useScroll()

  const scrollToTop = () => {
    // Define how many pixels to scroll per frame
    const scrollStep = window.scrollY / 30;
    
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        // Scroll up by the scrollStep amount
        window.scrollBy(0, -scrollStep);
      } else {
        // Clear the interval when at the top of the page
        clearInterval(scrollInterval);
      }
    }, 15); // Adjust the interval for smoother scrolling
  };


  const handleScroll = () => {

    if (scrollY.get() > 5100) {
        setOutOfView(true);
        
    } else if((scrollY.get() < 5070)) {
        setOutOfView(false);
    }
  };

  useMotionValueEvent(scrollY, "change", handleScroll);


  return (
    <>
      {outOfView? 
        <button onClick={()=>{scrollToTop()}} className="fixed flex justify-center items-center rounded-[50%] buttonOrangeGlow topButton bg-[#042535] bg-0 cursor-pointer z-50 w-[2.2rem] h-[2.2rem] bottom-10 right-[0.7rem] lg:bottom-20 lg:right-[4.5rem] lg:w-[4rem] lg:h-[4rem]">
            <FaArrowUp className="clickTopSymbol text-orangePrimary w-[15px] h-[15px] lg:w-[25px] lg:h-[25px]"/>
        </button>
      : <></>
    }
    </>
  );
}

export default ClickToTop;