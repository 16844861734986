import React, { useEffect, useState } from "react";
import SOCLandingComponent from "../../../Components/Services/SOCLandingComponent/SOCLandingComponent";
import { useLocation, useNavigate } from "react-router-dom";
import SocHeader from "../../../Components/Services/SocHeader/SocHeader";
import CylinderContentComponent from "../../../Components/Services/CylinderComponent/CylinderContentComponent";
import OurApproachComponent from "../../../Components/Services/OurApproachPage/OurApproachComponent";
import PageFooter from "../../../Components/common/Footer/PageFooter";
import CurvedCategories from "../../../Components/Services/CurvedCategories/CurvedCategories";
import useAxiosInstance from "../../../Utils/UtilityFunctions/RequestConfig";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../../Redux/Loading-Slice";
import GetStartedComponent from "../../../Components/FormComponents/GetStarted/GetStartedComponent";
import apiConfig from "../../../Utils/API/apiConfig";

const Vapt = () => {
  const navigate = useNavigate();

  const [vaptData, setVaptData] = useState([]);

  const [toc1, setToc1] = useState<any>();
  const [toc2, setToc2] = useState<any>();
  const [toc3, setToc3] = useState<any>();
  const [toc4, setToc4] = useState<any>();
  const [toc5, setToc5] = useState<any>();
  const [toc6, setToc6] = useState<any>();
  const [toc7, setToc7] = useState<any>();

  const axiosInstance = useAxiosInstance();
  const location = useLocation();
  const dispatch = useDispatch();

  const fetchVaptData = async () => {
    try {
      dispatch(setLoadingState(true));
      const response = await axiosInstance.get(apiConfig.VAPT.getDetails.api);

      response.data.data.forEach((attr: any, index: number) => {
        const vaptData = attr?.attributes?.VAPTS;
        setVaptData(vaptData);
      });
      dispatch(setLoadingState(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchVaptData();
  }, []);

  useEffect(() => {
    if (vaptData && vaptData.length > 0) {
      const one_toc = vaptData.filter((data: any) => data.sectionToc === 1);
      setToc1(one_toc[0]);

      const two_toc = vaptData.filter((data: any) => data.sectionToc === 2);
      setToc2(two_toc[0]);

      const three_toc = vaptData.filter((data: any) => data.sectionToc === 3);
      setToc3(three_toc[0]);

      const four_toc = vaptData.filter((data: any) => data.sectionToc === 4);
      setToc4(four_toc[0]);

      const five_toc = vaptData.filter((data: any) => data.sectionToc === 5);
      setToc5(five_toc[0]);

      const six_toc = vaptData.filter((data: any) => data.sectionToc === 6);
      setToc6(six_toc[0]);

      const seven_toc = vaptData.filter((data: any) => data.sectionToc === 7);
      setToc7(seven_toc[0]);
    }
  }, [vaptData]);

  return (
    <div className="flex bg-bluePrimary overflow-hidden w-full">
      <div className={`flex-col  homepageLayout enableBlur w-full`}>
        {toc1 ? (
          <SOCLandingComponent
            buttonFunction={() => {
              navigate(`/get-started?path=${location.pathname}`);
            }}
            backgroundImage={`${process.env.REACT_APP_URL}${toc1?.Header?.image?.data?.attributes?.url}`}
            backgroundType={toc1?.Header?.image?.data?.attributes?.ext}
            heading={toc1?.Header?.title}
            buttonText={toc1?.Header?.buttonText}
            description={toc1?.Header?.description}
            isLinearGrident={true}
          />
        ) : (
          <></>
        )}
        {toc2 && toc3 ? (
          <SocHeader
            pageParagraph={toc2?.pageParagraph}
            pageHeader={toc2?.pageHeader}
            cardData={toc2?.Cards}
            HorizentailDetails={toc3?.Cards}
            headingText={[`${toc3?.pageHeader}`, `${toc3?.pageParagraph}`]}
          />
        ) : (
          <></>
        )}
        {toc4 ? (
          <CylinderContentComponent
            orientation="left"
            bulletPoints={toc4?.BulletPoints}
            content={toc4?.content}
            heading={toc4?.heading}
            listView={true}
          />
        ) : (
          <></>
        )}
        {toc5 ? (
          <OurApproachComponent
            title={toc5?.heading}
            textColor={"text-orangePrimary"}
            mappingData={toc5?.TiersDetails}
            useFinal={false}
            isLinearGrident={true}
          />
        ) : (
          <></>
        )}
        {toc6 ? (
          <CurvedCategories
            categoriesData={toc6?.Biscuits}
            labelHeading={toc6?.heading}
          />
        ) : (
          <></>
        )}
        <GetStartedComponent data={toc7} pageRoute={location.pathname} />
        <PageFooter />
      </div>
    </div>
  );
};

export default Vapt;
