import { motion, useMotionValue, useTransform, animate, useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { CounterProps } from "../../../Interfaces/Interface";


const Counter: React.FC<CounterProps> = ({initialValue, finalValue, duration}) => {
  const count = useMotionValue(initialValue);
  let rounded = useTransform(count, Math.round);
  const counterContainer = useRef(null)
  const isInView = useInView(counterContainer, {once: true})

  const [isFinalValue, setIsFinalValue] = useState(false)

  const runAnimation = async () => {
    if (isInView) {
      await animate(count, finalValue, { duration: duration });
      setIsFinalValue(true)
    }
  };

  useEffect(() => {
    if(isInView){
     runAnimation()
    }
  }, [isInView]);
  

  return <motion.h1 ref={counterContainer}>{!isFinalValue? rounded:finalValue.toLocaleString("en-US")}</motion.h1>;
}

export default Counter;