import { whyVairavBiscuitProps } from "../../../Interfaces/Interface";
import ReactMarkdown from "react-markdown";

const IndustryRecognitionComponent: React.FC<whyVairavBiscuitProps> = ({
  data,
}) => {
  return (
    <div className="flex justify-center">
      <div className=" flex flex-col w-full  bg-white items-center source-sans gap-y-8 py-[3.125rem] max-w-[1920px] px-homepagePaddingSm lg:px-homepagePadding2xl lg:gap-y-[9rem] lg:min-h-screen lg:py-[6.25rem] 3xl:min-h-fit">
        <div className="text-primaryHeadingSm text-orangePrimary font-black lg:text-primaryHeading2xl roboto">
          {data?.heading}
        </div>
        <div className="w-full grid gap-x-5 gap-y-5 lg:gap-x-[3.125rem] lg:gap-y-[3.125rem] grid-cols-2">
          {data?.Biscuits?.map((value, index) => (
            <div
              key={value?.image?.data?.attributes?.url}
              className={`col-span-1 flex ${
                index % 2 === 0 ? "lg:justify-end" : "lg:justify-start"
              } lg:min-h-[29.901rem]`}
            >
              <div
                key={value.image?.data?.attributes?.url}
                className="industryCardShadow max-lg:w-full  flex flex-col items-center bg-white text-center gap-y-[0.75rem] px-[1rem] py-10 lg:px-20 lg:py-[6.25rem]"
              >
                <div className="h-[3.858rem] flex items-end lg:h-[9.758rem] ">
                  <img
                    src={`${process.env.REACT_APP_URL}${value.image?.data?.attributes?.url}`}
                    className="w-fit h-full"
                  />
                </div>
                <div className="text-blueTertiary  text-[0.625rem] lg:text-paragraphHomepage2xl lg:w-[19.5rem]">
                  <ReactMarkdown>{value?.title}</ReactMarkdown>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndustryRecognitionComponent;
