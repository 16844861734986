import { useEffect, useState } from "react";
import Navbar from "../../Components/common/Navbar/Navbar";
import ReactMarkdown from "react-markdown";
import { parseDateToCustomFormat } from "../../Utils/UtilityFunctions/UtilityFunction";
import rehypeRaw from "rehype-raw";
import PageFooter from "../../Components/common/Footer/PageFooter";
import useAxiosInstance from "../../Utils/UtilityFunctions/RequestConfig";
import { templatePolicyProps } from "../../Interfaces/Interface";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../Redux/Loading-Slice";

const TemplatePolicy: React.FC<templatePolicyProps> = ({
  route,
  objectProperty,
}) => {
  const [infoDetails, setInfoDetails] = useState<any>({});

  const axiosInstance = useAxiosInstance();

  const dispatch = useDispatch();

  useEffect(() => {
    GetInfoDetails();
  }, [route, objectProperty]);

  const GetInfoDetails = async () => {
    try {
      dispatch(setLoadingState(true));
      const response = await axiosInstance.get(route);
      const tempInfoData = response?.data?.data[0]?.attributes || {};
      setInfoDetails(tempInfoData);
      dispatch(setLoadingState(false));
    } catch {}
  };

  return (
    <div className="flex bg-bluePrimary overflow-hidden w-full">
      <div className={`flex-col mainPlastic homepageLayout enableBlur w-full`}>
        <div className="flex flex-col bg-bluePrimary">
          <Navbar />
          <div className="flex flex-col bg-white">
            <div className="bg-bluePrimary  text-white roboto font-black px-homepagePaddingSm text-primaryHeadingSm pb-10 pt-[3.125rem] lg:pt-[6.25rem] lg:text-primaryHeading2xl lg:px-homepagePaddingLg xl:px-homepagePadding2xl">
              <div className="w-full lg:w-[45%] robot">
                {infoDetails?.title}
              </div>
            </div>
            <div className="flex flex-col pt-5 pb-[3.125rem] gap-y-6 px-homepagePaddingSm text-[0.6rem] lg:text-base  lg:px-homepagePaddingLg xl:px-homepagePadding2xl">
              <div className="flex gap-x-2 font-light source-sans text-orangePrimary ">
                <div className="tracking-widest">
                  {parseDateToCustomFormat(
                    infoDetails?.createdAt || Date.now()
                  )}
                </div>
                <div className="w-[2px] h-5 bg-[#959595]" />
                <div>by {infoDetails?.authorName}</div>
              </div>
              <div className="w-full h-fit border-black blogDetailsParent">
                <ReactMarkdown rehypePlugins={[rehypeRaw]} className="">
                  {infoDetails &&
                    objectProperty in infoDetails &&
                    infoDetails[objectProperty]}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
        <PageFooter />
      </div>
    </div>
  );
};

export default TemplatePolicy;
