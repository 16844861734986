import NavbarIndividualProduct from "./NavbarIndividualProduct";
import React from "react";

const ProductsDropdown = ({ navbarSubList }: any) => {
  return (
    <div
      className={`flex flex-row bg-black lg:w-full items-center lg:justify-center`}
    >
      {navbarSubList?.map((value: any, index: number) => (
        <React.Fragment key={value?.heading}>
          <NavbarIndividualProduct
            color={value?.textColor}
            heading={value?.heading}
            image={`${process.env.REACT_APP_URL}${value.image?.data?.attributes?.url}`}
            route={value?.link}
            description={value?.subTitle}
          />
        </React.Fragment>
      ))}
      {/* <div className="h-[300px] w-[1px] bg-white" />
      <NavbarIndividualProduct
        color="blue"
        heading="E-LEARNING AWARENESS"
        image={cybersathiIMG}
        route="/in-progress"
        description="An e-learning platform available 24/7, 365 days a year that provides
                cyber-awareness best practices, inculcating the cybersecurity concepts in
                a simpler form, presented via humorous yet scholarly video modules."
      /> */}
    </div>
  );
};

export default ProductsDropdown;
