import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface formPopupState {
  formPopupState: boolean;
  selectedURL: string;
}

// Define the initial state using that type
const initialState: formPopupState = {
  formPopupState: false,
  selectedURL: "",
};

export const formPopupSlice = createSlice({
  name: "Authorization",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setFormPopupState: (state, action: PayloadAction<boolean>) => {
      state.formPopupState = action.payload;
    },
    setSelectedURL: (state, action: PayloadAction<string>) => {
      state.selectedURL = action.payload;
    },
  },
});

export const { setFormPopupState, setSelectedURL } = formPopupSlice.actions;

export default formPopupSlice.reducer;
