import greyVairavImg from "../../../Images/greyVairav.svg";
import whyVairavLines from "../../../Images/whyVairavLineBottom.svg";

const WhyVairavGoals: React.FC<{
  data: any;
}> = ({ data }) => {
  return (
    <div className="flex justify-center">
      <div className="relative w-full max-w-[1920px] flex flex-col justify-start items-center ">
        <div className="absolute">
          <img
            src={greyVairavImg}
            className="w-[16.866rem] mt-[5rem] lg:mt-0 lg:w-[42.294rem]"
          />
        </div>
        <div className="flex flex-col w-full px-homepagePaddingSm gap-y-5 pt-[3.125rem] lg:pt-[6.25rem] lg:gap-y-[3.125rem] lg:px-homepagePadding2xl">
          {data?.objective.map((value: any) => (
            <div key={value?.title} className="flex flex-col items-center">
              <div className="flex w-full items-center gap-x-[0.938rem]">
                <div className="flex-1 bg-white h-[2px]" />
                <div className="w-[3.375rem] lg:w-[8.485rem]">
                  <img
                    src={`${process.env.REACT_APP_URL}${value?.image?.data?.attributes?.url}`}
                    className="w-full"
                  />
                </div>
                <div className="flex-1 bg-white h-[2px]" />
              </div>
              <div className="justify-center font-black roboto text-center text-orangePrimary text-primaryHeadingSm mt-[0.625rem] lg:mt-[1.875rem] lg:text-primaryHeading2xl lg:w-[72.289rem]">
                {value?.title}
              </div>
              <div className="justify-center font-semibold text-center mt-[0.625rem] text-descriptionHeadingSm lg:mt-[1.563rem] lg:text-paragraphHomepage2xl lg:w-[72.289rem]">
                {value?.subTitle}
              </div>
              <div className="flex font-bold flex-wrap truncate gap-x-2 justify-center  text-center text-descriptionHeadingSm lg:text-paragraphHomepage2xl w-full lg:gap-x-0 lg:justify-between lg:w-[72.289rem]">
                {value?.point?.map((val: any, index: number) => (
                  <>
                    <span>{val}</span>
                    {index !== value?.point?.length - 1 && (
                      <div className=" w-[3px] bg-orangePrimary" />
                    )}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col w-full lg:mt-[-5rem]">
          <div className="flex flex-row w-full">
            <img src={whyVairavLines} className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyVairavGoals;
