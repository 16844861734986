import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../Utils/UtilityFunctions/UtilityFunction";
import constants from "../Utils/Constants/constants";

// Define a type for the slice state
export interface PaginationState {
  currentPage: number;
  recordsPerPage: number;
  noOfPages: number[];
}

// Define the initial state using that type
const initialState: PaginationState = {
  currentPage: 1,
  recordsPerPage: constants.reusableConstants.recordsPerPage,
  noOfPages: [],
};

export const paginationSlice = createSlice({
  name: "Pagination",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    resetPagination: () => initialState,
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setRecordsPerPage: (state, action: PayloadAction<number>) => {
      state.recordsPerPage = action.payload;
    },
    setNoOfPages: (state, action: PayloadAction<number[]>) => {
      state.noOfPages = action.payload;
    },
  },
});

export const {
  setCurrentPage,
  setRecordsPerPage,
  setNoOfPages,
  resetPagination,
} = paginationSlice.actions;

export default paginationSlice.reducer;
