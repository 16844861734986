import React from "react";
import "./Honeycome.css";
import halfTriangleIMG from "../../../Images/halfTriangle.png";

const HoneycomBox = ({ data }: { data: string[] }) => {
  data = data?.slice(0, 13);

  return (
    <div className="flex  xl:items-center w-full justify-center  z-10 relative h-[42rem]  translate-y-[1%] md:translate-y-[10%]e  mt-[6.3rem] ex-xl:mt-[7.6rem] sm:mt-[10.1rem] lg:mt-[-8.3rem] xl:mt-[-3.6rem] 2xl:mt-[-10.1rem]">
      <div className="grid-cols-4 hidden place-items-center lg:grid justify-center items-center flex-col lg:gap-[1rem] -rotate-45 lg:h-[45rem] lg:w-[45rem] xl:gap-[2rem] xl:h-auto xl:w-auto 2xl:gap-[3rem] 2xl:h-auto 2xl:w-auto 2xl:mt-80 3xl:mt-[26rem]">
        {data?.map((value: any, index: number) => (
          <React.Fragment key={index}>
            {index === 1 ? (
              <>
                <LargeSizeScreenBox value={value} />
                <div className="col-span-1"></div>
                <div className="col-span-1"></div>
              </>
            ) : index === 4 ? (
              <>
                <LargeSizeScreenBox value={value} />
                <div className="col-span-1"></div>
              </>
            ) : (
              <LargeSizeScreenBox value={value} />
            )}
          </React.Fragment>
        ))}
      </div>
      {/* responsive for small devices */}
      <div className=" grid grid-cols-5 justify-center place-items-center h-[35rem] w-[35rem] ex-xl:h-[40rem] ex-xl:w-[40rem] sm:h-[50rem] sm:w-[50rem] md:h-[55rem] md:w-[55rem]  items-center flex-col gap-[1rem]  lg:hidden  absolute  rotate-[-45deg]">
        {data?.map((value: string, index: number) => (
          <React.Fragment key={index}>
            {index === 0 ? (
              <>
                <div className="col-span-1"></div>
                <div className="col-span-1"></div>
                <div className="col-span-1"></div>
                <RectangularBox value={value} />
              </>
            ) : index === 2 ? (
              <>
                <div className="col-span-1"></div>
                <div className="col-span-1"></div>
                <RectangularBox value={value} />
              </>
            ) : index === 5 ? (
              <>
                <div className="col-span-1"></div>

                <RectangularBox value={value} />
                {/* <div className="col-span-1"></div> */}
              </>
            ) : index === 7 ? (
              <>
                <RectangularBox value={value} />
                <div className="col-span-1"></div>
              </>
            ) : index === 10 ? (
              <>
                <RectangularBox value={value} />
                <div className="col-span-1"></div>
                <div className="col-span-1"></div>
              </>
            ) : (
              <>
                <RectangularBox value={value} />
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default HoneycomBox;

const RectangularBox = ({ value }: { value: string }) => {
  return (
    <>
      <div
        className={`rotate-[90deg] col-span-1 h-[6rem] w-[6rem] ex-xl:h-[7rem] ex-xl:w-[7rem] sm:h-[9rem] sm:w-[9rem]  boxSize relative btn-gradient-1 text-[1.58rem] rounded-xl xl:rounded-3xl  cursor-pointer  flex justify-center items-center text-wrap text-center px-4 xl:px-7 py-6 tracking-wide singlebox `}
      >
        <img
          src={halfTriangleIMG}
          className="absolute rotate-[-45deg] h-[25px] w-[50px]   mt-[3.5rem] ml-[3.5rem] ex-xl:mt-[4.5rem] ex-xl:ml-[4.5rem] sm:h-[30px] sm:w-[60px] sm:mt-[5rem] sm:ml-[5rem] lg:w-[70px] lg:h-[35px] xl:w-[80px] xl:h-[40px] lg:mt-[6rem] lg:ml-[6rem] xl:mt-[7rem] xl:ml-[7rem] 2xl:mt-[10rem] 2xl:ml-[10rem]"
          alt="halftrag"
        />
        <p className=" rotate-[-45deg] source-sans  font-semibold text-white text-[10px] ex-xl:text-descriptionHeadingSm ">
          {/* Incident Report and Active Response */}
          {value}
        </p>
      </div>
    </>
  );
};

// For Large Box

const LargeSizeScreenBox = ({ value }: { value: string }) => {
  return (
    <div
      className={`col-span-1 sm:h-[20rem] sm:w-[20rem]  3xl:h-[16rem] rotate-90 3xl:w-[16rem] lg:h-[10rem] lg:w-[10rem] xl:h-[12rem] xl:w-[12rem] 2xl:h-[14rem] 2xl:w-[14rem] relative btn-gradient-1 text-[1.58rem] rounded-3xl  cursor-pointer  flex justify-center items-center text-wrap text-center px-7 py-6 tracking-wide`}
    >
      <img
        src={halfTriangleIMG}
        className="absolute rotate-[-45deg] lg:w-[70px] lg:h-[35px] xl:w-[80px] xl:h-[40px] lg:mt-[6rem] lg:ml-[6rem] xl:mt-[7rem] xl:ml-[7rem] 2xl:mt-[10rem] 2xl:ml-[10rem] "
        alt="halftrag"
      />
      <p className="rotate-[-45deg] source-sans  font-semibold text-white lg:text-[1rem] xl:text-[1.263rem] 2xl:text-paragraphHomepage2xl">
        {/* Incident Report and Active Response */}
        {value}
      </p>
    </div>
  );
};
