import { Variant, motion } from "framer-motion";
import mascotLoading from "../../../../Images/vairavLoading.gif";

const LoadingScreen = () => {
  return (
    <div className="w-full h-screen bg-bluePrimary flex flex-col items-center justify-center gap-y-8">
      <img src={mascotLoading} className="w-[10rem]" />
    </div>
  );
};

export default LoadingScreen;
