import { useEffect, useState } from "react";
import PageFooter from "../../../Components/common/Footer/PageFooter";
import Navbar from "../../../Components/common/Navbar/Navbar";
import useAxiosInstance from "../../../Utils/UtilityFunctions/RequestConfig";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../../Redux/Loading-Slice";
import GetStartedComponent from "../../../Components/FormComponents/GetStarted/GetStartedComponent";
import apiConfig from "../../../Utils/API/apiConfig";

const GetStarted = () => {
  const [getStartedData, setGetStartedData] = useState<any>([]);

  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();

  const fetchGetStartedData = async () => {
    try {
      dispatch(setLoadingState(true));
      const response = await axiosInstance.get(
        apiConfig.Form.getGetStartedDetails.api
      );
      response.data.data.map((attr: any, index: number) => {
        const temp = attr.attributes;
        setGetStartedData(temp);
      });
      dispatch(setLoadingState(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchGetStartedData();
  }, []);

  return (
    <div className="flex flex-col bg-bluePrimary overflow-hidden w-full 3xl:justify-between 3xl:min-h-screen">
      <Navbar />
      <GetStartedComponent data={getStartedData} />
      <PageFooter />
    </div>
  );
};

export default GetStarted;
