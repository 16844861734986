import React from "react";
import CardItem from "../../Services/card/CardItem";

interface IcardData {
  title: string;
  description: string;
  buttonUrl: string;
  id: number;
  buttonText: string | null;
  image: any;
}

const AssistPage = ({
  image,
  title,
  description,
  card,
}: {
  image: string;
  title: string;
  description: string;
  card: IcardData[];
}) => {
  return (
    <div className="px-3 w-[100%] overflow-hidden xl:px-homepagePadding2xl linear_secound 3xl:flex 3xl:justify-center 3xl:items-center">
      <div className="py-[3.125rem]  lg:mt-[6.250rem] lg:pb-[6.250rem]  px-5 md:px-0 3xl:w-full  max-w-[1920px]">
        <div className="flex flex-col justify-center items-center gap-4 xl:gap-6 roboto font-black ">
          <div className="w-full flex justify-center">
            {image && (
              <img
                src={image}
                alt="mascot"
                className="h-[7rem] md:h-[15rem] w-[18rem] object-contain 2xl:py-4"
              />
            )}
          </div>
          <div className="text-center  w-full text-primaryHeadingSm text-orangePrimary xl:font-bold xl:text-primaryHeading2xl roboto xl:leading-8  tracking-wider">
            {title}
          </div>
          <div className="text-descriptionHeadingSm  md:w-[30rem] lg:w-[735px]  text-white source-sans  pb-[1.6rem] text-center xl:pb-11 font-normal xl:text-[28px] 2xl:text-center">
            {description}
          </div>
        </div>
        <div className="flex justify-center items-center flex-col gap-6 md:grid-cols-1 lg:grid lg:grid-cols-2 xl:grid-cols-3 xl:place-content-center  lg:gap-6 xl:gap-12  lg:mx-36 xl:mx-0 2xl:mx-24 3xl:mx-36">
          {card?.map((obj, index) => (
            <React.Fragment key={index}>
              <div className=" flex flex-col lg:flex-row lg:justify-center xl:gap-12">
                <React.Fragment key={index}>
                  <div className="md:w-[26rem] lg:w-[100%] md:flex md:flex-col assistCard">
                    <CardItem
                      className="col-span-2"
                      title={obj.title}
                      description={obj.description}
                      link={obj.buttonUrl}
                    />
                  </div>
                </React.Fragment>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AssistPage;
