import HomepageCard from "../../Services/card/HomepageCard";
import { Iproduct } from "../../../Interfaces/ILandingInterface";

const HoneyPageHeader = ({
  title,
  heading,
  subTitle,
  productData,
}: {
  title: string;
  heading: string;
  subTitle: string;
  productData: Iproduct;
}) => {
  const getHighlightedText = (text: string) => {
    const parts = text?.split(/(#.*?#)/g); // Split by the # delimiter
    return parts?.map((part: any, index: number) => {
      if (part?.startsWith("#") && part?.endsWith("#")) {
        return (
          <span key={index} className="text-orangePrimary">
            {part.slice(1, -1)}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <>
      <div className="text-white w-screen flex flex-col justify-center items-center text-center px-4 md:px-8 xl:w-full lg:px-16 xl:px-24 2xl:px-32 ">
        <h1 className="text-lg roboto font-black md:text-3xl lg:text-4xl xl:text-primaryHeading2xl 2xl:text-primaryHeading2xl 2xl:leading-[3.25rem]">
          <p className="lg:w-[53rem] lg:leading-[3.25rem]">
            {getHighlightedText(title)}
          </p>
        </h1>
        <div className="mt-4 text-center lg:mt-9  xl:text-balance xl:paragraphHomepage2xl 2xl:px-72">
          <p className="text-xs md:text-base lg:text-lg xl:text-xl 2xl:text-paragraphHomepage2xl font-medium source-sans xl:text-wrap 2xl:font-semibold 2xl:leading-9 lg:w-[1000px]">
            {getHighlightedText(subTitle)}
          </p>
        </div>
        <HomepageCard
          title={productData?.title}
          subTitle={productData?.heading}
          ButtonText={productData?.buttonTitle}
          ButtonUrl={productData?.url}
          image={
            productData?.media?.data
              ? `${process.env.REACT_APP_URL}${productData?.media?.data[0]?.attributes?.url}`
              : ""
          }
        />
        <h1 className="text-lg py-primaryPaddingYSm lg:py-primaryPaddingY2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl roboto leading-snug  lg:mt-0 2xl:text-primaryHeading2xl 2xl:leading-[3.25rem] xl:font-[900] roboto font-black lg:w-[879px] 2xl:mt-0">
          {getHighlightedText(heading)}
        </h1>
      </div>
    </>
  );
};

export default HoneyPageHeader;
