import { useNavigate } from "react-router-dom";
import useAxiosInstance from "./RequestConfig";

export function useSubmitUserDetails() {
  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();

  const submitUserDetails = async (
    route: string,
    data: any,
    formType: string,
    resetForm: any,
    serverError: any,
    setServerError: any,
    captchaReset: boolean,
    setCaptchaReset: any,
    captchaToken: string,
    customSuccessFunction?: () => {}
  ) => {
    try {
      if (captchaToken) {
        const contactData = {
          data: {
            ...data,
            formType,
            phoneNumber: data.countryCode + data.phoneNumber,
          },
        };
        const response = await axiosInstance
          .post(route, contactData)
          .then(() => {
            customSuccessFunction && customSuccessFunction();
            navigate("/form/success");
            resetForm();
          })
          .catch((error) => {
            if (error?.response?.data?.error?.details?.errors) {
              error?.response?.data?.error?.details?.errors.forEach(
                (value: any) => {
                  setServerError({
                    field: value?.path[0],
                    error: value?.message,
                  });
                }
              );
            }
            setCaptchaReset(!captchaReset);
          });
      }
    } catch (error) {
      if (error) {
        setCaptchaReset(!captchaReset);
      }
    }
  };

  return submitUserDetails;
}
