import { Link, useNavigate } from "react-router-dom";
import { navbarIndividualComponentProps } from "../../../Interfaces/Interface";

const NavbarIndividualProduct: React.FC<navbarIndividualComponentProps> = ({
  image,
  heading,
  description,
  color,
  route,
}) => {
  const navigate = useNavigate();
  return (
    <Link
      to={route}
      className="navbarProductContainer w-full flex flex-col items-start cursor-pointer py-[1.563rem] lg:py-10 lg:w-1/2 lg:items-center "
    >
      <div className="flex items-end w-full justify-start lg:justify-center">
        <img src={image} className={`w-[10.283rem] lg:w-[15rem]`} />
      </div>
      <div
        style={{ color: color }}
        className={`navbarProductHeading w-fit source-sans font-bold tracking-widest text-descriptionHeadingSm mt-[0.438rem] lg:text-paragraphHomepage2xl lg:mt-8`}
      >
        {heading}
      </div>
      <div className="w-fit text-[#959595] source-sans text-start mt-[1.563rem] text-descriptionHeadingSm lg:text-center lg:w-[25rem] lg:text-descriptionHeadingSm lg:mt-3">
        {description}
      </div>
    </Link>
  );
};

export default NavbarIndividualProduct;
