import { useField } from "formik";
const CustomInput: React.FC<any> = ({
  placeholder,
  type,
  serverError,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div className=" lg:h-[4rem]">
      <input
        {...field}
        {...props}
        className="border-[#FFFFFF50] border-b-[1px] bg-transparent w-full placeholder:text-white source-sans px-2 tracking-wide pb-1 md:pb-3 outline-none text-white text-navbar2xl lg:text-descriptionHeading2xl"
        type={type}
        autoComplete={"new-password"}
        placeholder={placeholder}
      />
      <div className=" w-full flex justify-end roboto text-[13px] text-red-500 px-2 h-[1.25rem] lg:h-[1.5rem]">
        {meta.touched && meta.error ? <div>{meta.error}</div> : null}
        {!meta.error && serverError ? <div>{serverError}</div> : null}
      </div>
    </div>
  );
};

export default CustomInput;
