import { curvedCategoriesProps } from "../../../Interfaces/Interface";
import CurvedDiv from "../curvedDiv/CurvedDiv";

const CurvedCategories: React.FC<curvedCategoriesProps> = ({
  categoriesData,
  pageheading,
  labelHeading,
  isLinearGrident = true,
}) => {
  return (
    <div
      className={`flex justify-center bg-bluePrimary ${
        isLinearGrident && "linear_secound"
      }`}
    >
      <div className={`flex  max-w-[1920px]`}>
        <div
          className={`flex flex-col w-full gap-y-standardPageGap px-5 py-primaryPaddingYSm sm:py-primaryPaddingY2xl md:px-[1rem] xl:pl-[2rem] 2xl:pl-homepagePadding2xl`}
        >
          {pageheading ? (
            <div className="flex w-full justify-center text-primaryHeadingSm font-black text-white roboto md:text-primaryHeading2xl">
              {pageheading}
            </div>
          ) : (
            <></>
          )}

          {categoriesData?.map((value, index) => (
            <CurvedDiv
              boxDescription={value?.description}
              labelDescription={labelHeading}
              boxHeading={value?.title}
              image={`${process.env.REACT_APP_URL}${value?.image?.data?.attributes?.url}`}
              index={index}
              route={value?.url}
              key={value.boxHeading}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CurvedCategories;
