import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CheckWords } from "../../../Utils/UtilityFunctions/UtilityFunction";
import { motion, useInView } from "framer-motion";
import "./SocHeader.css";
import { IsocHeader, LineBox } from "../../../Interfaces/IsocInterface";

const SocHeader = ({
  pageHeader,
  pageParagraph,
  cardData,
  HorizentailDetails,
  headingText,
  isLinearGrident,
}: IsocHeader) => {
  // horizeental Card Data

  const [firstAnimationComplete, setFirstAnimationCompleted] = useState(false);

  // play animation if user once visit that page

  const [playanimation, setPlayAnimation] = React.useState(false);

  const lineContainer = React.useRef<HTMLDivElement>(null);
  const isInView = useInView(lineContainer, { once: true });

  const animateLineSequence = async () => {
    try {
      if (isInView) {
        setPlayAnimation(true);
      }
    } catch (error) {
      if (error) {
        return error;
      }
    }
  };
  React.useEffect(() => {
    animateLineSequence();
  }, [isInView]);

  return (
    <div
      className={`w-full mt-5 bg-[#011A28] md:pt-20 px-4 lg:px-0 ${
        isLinearGrident && "linear_secound"
      }`}
    >
      <div className=" w-full flex justify-center flex-col items-center gap-8 pt-10">
        {/* header */}
        <div className="text-homepagePaddingSm md:self-center md:text-center text-orangePrimary text-lg roboto  md:text-3xl lg:text-4xl xl:text-primaryHeading2xl 2xl:text-primaryHeading2xl font-black 2xl:leading-[3.25rem] md:w-[30rem] ">
          {pageHeader}
        </div>

        {/* paragraph */}

        <div className="text-xs md:text-base lg:text-lg xl:text-xl 2xl:text-paragraphHomepage2xl font-medium source-sans xl:text-wrap 2xl:font-semibold 2xl:leading-9 text-white  text-center w-[19rem] md:w-[44rem] px-4 md:px-0">
          {pageParagraph}
        </div>

        {/* RowCard Button */}
        <div className="md:w-[100%] flex flex-col gap-6 items-center md:items-stretch md:flex:row md:justify-center md:flex-row md:gap-9 md:flex-wrap">
          {cardData?.map((data, ind) => (
            <React.Fragment key={ind}>
              <CardItem title={data.title} description={data.description} />
            </React.Fragment>
          ))}
        </div>
      </div>
      <div
        className="flex-col gap-9 lg:gap-0 w-full  lg:min-h-[66rem] flex lg:flex-row justify-center lg:items-center pt-10 pb-0 lg:py-24  relative"
        ref={lineContainer}
      >
        <div className="flex-col min-h-12 text-white flex-[0.4] flex lg:flex-row   lg:justify-end items-center  md:self-center text-center  text-lg roboto font-black md:text-3xl lg:text-2xl xl:text-4xl xl:text-primaryHeading2xl 2xl:text-primaryHeading2xl  2xl:leading-[3.25rem] md:w-[30rem]">
          <div
            className={`border-[2px] px-7 ${
              headingText[1] !== "" ? "py-4" : "py-4 sm:py-8"
            }  border-orangePrimary rounded-md w-[80%] lg:w-[19rem] xl:w-[33rem]`}
            ref={lineContainer}
          >
            {headingText[0]}
            <br />
            <span className="text-orangePrimary">
              {" "}
              {headingText[1] == null ? "" : headingText[1]}
            </span>
          </div>
          <motion.div
            className="hidden lg:flex w-[3rem] xl:w-[5rem] h-[3px] bg-white"
            initial={{ scaleX: 0, originX: 0 }}
            animate={playanimation ? { scaleX: 1, originX: 0 } : {}}
            transition={{ duration: 0.35 }}
            onAnimationComplete={() => setFirstAnimationCompleted(true)}
          ></motion.div>
        </div>

        {/* {firstAnimationComplete && distance ? (
          <motion.div
            className={`hidden md:flex w-[2px]  bg-white`}
            style={{ height: distance && `${distance + 2}px` }}
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            transition={{ duration: 0.35 }}
            onAnimationComplete={() => setSecoundAnimationCompleted(true)}
          ></motion.div>
        ) : (
          <></>
        )} */}

        <motion.div
          className="flex  flex-[0.5] md:justify-left md:items-left flex-col gap-[3.5rem] md:gap-9"
          ref={lineContainer}
          initial={{ opacity: 0 }}
          animate={firstAnimationComplete && { opacity: 1 }}
          transition={{ duration: 0.35 }}
        >
          <Box
            firstAnimation={firstAnimationComplete}
            HorizentailDetails={HorizentailDetails}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default SocHeader;

// Card items
const CardItem = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const wordArr = CheckWords(title);
  return (
    <div className="max-md:w-[70%] max-lg:w-full  col-span-4 flex justify-start  rounded-md   bg-[#152C39] py-5 shadow-[1px_1px_62px_27px_#00000024] md:shadow-none">
      {/* Top div for header paragraph */}
      <div className="lg:w-[351px]  flex flex-col overflow-hidden ">
        <div className="text-buttonSm font-semibold md:text-paragraphHomepage2xl text-orangePrimary text-center  px-7 tracking-wide ">
          {CheckWords(title).length === 2 ? (
            <>
              {wordArr[0]}
              <br /> {wordArr[1]}
            </>
          ) : (
            <>{title}</>
          )}
        </div>
        <div className="text-descriptionHeadingSm FW_300_FS_18px  source-sans font-light  px-5 md:px-10 pt-6 pb-4">
          {description}
        </div>
      </div>
    </div>
  );
};

// box animation

const Box = ({
  firstAnimation,
  HorizentailDetails,
}: {
  firstAnimation: boolean;
  HorizentailDetails: LineBox[];
}) => {
  const [secoundANimation, setSecoundAnimation] = useState(false);

  return (
    <>
      <div
        className="flex justify-start "
        style={{ paddingTop: HorizentailDetails?.length >= 5 ? "2.7%" : "" }}
      >
        <motion.table
          className="border-collapse border border-transparent"
          initial={{ opacity: 0, scaleY: 0 }}
          animate={firstAnimation ? { opacity: 1, scaleY: 1 } : {}}
          transition={{ duration: 0.3 }}
          onAnimationComplete={() => {
            setSecoundAnimation(true);
          }}
        >
          <tbody>
            {HorizentailDetails?.map((data, index) => (
              <React.Fragment key={index}>
                <tr className="">
                  <motion.td
                    className="border-[3px] border-white p-3 lg:w-[5rem]  max-lg:hidden"
                    style={{
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: index === 0 ? "none" : "",
                    }}
                  ></motion.td>
                  <td className="" rowSpan={2}>
                    {/* Applied border-none class */}
                    <motion.div
                      className="border-[3px] border-white lg:w-[35rem] xl:w-[41rem] mb-6 lg:mb-10  rounded-md flex flex-col justify-center items-center text-center py-3  lg:leading-7"
                      initial={{
                        // scale: 0.5,
                        opacity: 0,
                        // originX: -1,
                      }} // Initial scale and opacity
                      animate={
                        secoundANimation
                          ? {
                              // scale: 1,
                              opacity: 1,
                              // originX: 1,
                            }
                          : {}
                      } // Final scale and opacity
                      transition={{ duration: 0.5, delay: 0.1 }} // Animation duration and delay
                    >
                      <div className="font-buttonSm FW_600_FS_25px lg:tracking-wider pb-3 lg:pb-3">
                        {data.title}
                      </div>
                      <div className="text-descriptionHeadingSm FW_300_FS_18px source-sans  font-light lg:px-[1rem] px-[0.5rem] lg:tracking-wide">
                        {data.description}
                      </div>
                    </motion.div>
                  </td>
                </tr>
                <tr>
                  <td
                    className="lg:border-[3px] border-white p-4 max-lg:hidden"
                    style={{
                      borderTop: "none",
                      borderBottom: "none",
                      borderRight: "none",
                      borderLeft:
                        index === HorizentailDetails.length - 1 ? "none" : "",
                    }}
                  ></td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </motion.table>
      </div>
    </>
  );
};
