import whyVairavBorder from "../../../Images/whyVairavBorder.svg";
import whyVairavLines from "../../../Images/whyVairavLines.svg";
import vairavBullet from "../../../Images/vairavGrayBullet.svg";
import { useEffect, useState } from "react";

const WhyVairavPoints: React.FC<{ data: any; imageChange: number }> = ({
  data,
  imageChange,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (
      data?.images?.data &&
      Array.isArray(data?.images?.data) &&
      data?.images?.data.length > 0
    ) {
      const interval = setInterval(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % data?.images?.data.length
        );
      }, imageChange);

      return () => clearInterval(interval);
    }
  }, [data]);

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col w-full max-w-[1920px]">
        <div className="flex w-full justify-between items-center mt-[-4.2rem]  lg:mt-[-14rem]">
          <img src={whyVairavBorder} className="w-[3.76rem] lg:w-fit" />
          <div className="text-primaryHeadingSm roboto text-center font-black mt-[10px] text-orangePrimary lg:text-primaryHeading2xl">
            {data?.title}
          </div>
          <img
            src={whyVairavBorder}
            className="scale-x-[-1] w-[3.76rem] lg:w-fit"
          />
        </div>
        <div className="flex flex-col w-full relative lg:mt-[-5rem]">
          <div className="flex flex-row w-full lg:absolute">
            <img src={whyVairavLines} className="w-full" />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row px-homepagePaddingSm w-full gap-x-[5.375rem] lg:px-homepagePadding2xl">
            <div className="hidden flex-col items-end gap-y-[3.375rem] w-[50%] lg:pt-[12rem] lg:flex">
              <div className="w-[33.268rem] h-[24.781rem] border-[5px] border-orangePrimary">
                <img
                  src={`${process.env.REACT_APP_URL}${
                    data?.images?.data &&
                    data?.images?.data[currentIndex]?.attributes?.url
                  }`}
                  className="grayscale w-full h-full object-cover"
                />
              </div>
              <div className="w-[33.268rem] gap-x-[1.625rem] grid grid-cols-3">
                {data?.images?.data &&
                  data?.images?.data.map((value: any, index: number) => (
                    <div
                      key={value?.attributes?.url}
                      className="w-fit h-fit border-2 border-orangePrimary"
                    >
                      <img
                        src={`${process.env.REACT_APP_URL}${value?.attributes?.url}`}
                        className="grayscale col-span-1"
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="flex flex-col items-start w-full gap-y-[2.75rem] lg:pt-[7.5rem] lg:w-[50%]">
              <div className="flex flex-col gap-y-[2.75rem] lg:w-[29.669rem]">
                <div className="flex flex-col gap-y-[0.938rem] text-center lg:text-left">
                  <div className="font-black roboto text-primaryHeadingSm lg:text-paragraphHomepage2xl">
                    {data?.heading?.title}
                  </div>
                  <div className="text-descriptionHeadingSm lg:text-descriptionHeading2xl ">
                    {data?.heading?.description}
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  {data?.pointDetail?.map((value: any, index: number) => (
                    <div
                      key={value?.title}
                      className="flex w-full flex-row gap-x-[1.25rem] lg:mt-[-1px]"
                    >
                      <div className="flex flex-col items-center  w-fit">
                        <img
                          src={vairavBullet}
                          className="lg:w-[28px] h-fit grayscale"
                        />
                        <div
                          className={`h-full ${
                            index === data?.pointDetail.length - 1
                              ? "hidden"
                              : ""
                          } bg-[#FFFFFF] w-[0.5px]`}
                        />
                      </div>
                      <div className="flex flex-col pb-[0.938rem] w-[99%] lg:pb-[1.563rem]">
                        <div className="roboto font-black text-buttonSm lg:text-paragraphHomepage2xl">
                          {value?.title}
                        </div>
                        <div className="text-descriptionHeadingSm lg:text-descriptionHeading2xl">
                          {value?.description}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyVairavPoints;
