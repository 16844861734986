import { Form, Formik } from "formik";
import AICPAIMG from "../../../Images/AICPA.svg";
import MSSPIMG from "../../../Images/MSSP.svg";
import { contactSchema } from "../../../Schema/Forms/GeneralFormSchema";
import halfVairav from "../../../Images/halfVairav.svg";
import fullVairav from "../../../Images/fullVairavLogo.svg";
import Iso from "../../../Images/Iso.svg";
import React, { useEffect, useState } from "react";
import CustomInput from "../../common/customInput/CustomInput";
import CustomCheckboxSelect from "../../common/customInput/CustomCheckboxSelect";
import SingleCheckbox from "../../common/customInput/SingleCheckbox";
import OrangeGradientButton from "../../common/button/OrangeGradientButton";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Captcha from "../../common/Captcha/Captcha";
import { countries, getCountryCode, getCountryData } from "countries-list";
import CustomDropdown from "../../common/customInput/CustomDropdown";
import { useSubmitUserDetails } from "../../../Utils/UtilityFunctions/UtilComponentFunctions";
import useAxiosInstance from "../../../Utils/UtilityFunctions/RequestConfig";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../../Redux/Loading-Slice";
import apiConfig from "../../../Utils/API/apiConfig";
import CustomPhoneNumber from "../../common/customInput/CustomPhoneNumber";

const checkboxData = [
  "Cloud Security",
  "Network Security",
  "Endpoint/Application Security",
  "24/7, 365 Continuous Monitoring",
  "Incident Response",
  "Digital Forensics",
  "Vulnerability Management",
  "Compliance Management",
];

const formData = [
  {
    placeholder: "Email Address",
    name: "emailAddress",
    type: "email",
    id: "emailAddress",
  },
  {
    placeholder: "First Name",
    name: "firstName",
    type: "text",
    id: "firstName",
  },
  {
    placeholder: "Last Name",
    name: "lastName",
    type: "text",
    id: "lastName",
  },
  {
    placeholder: "Job Title",
    name: "jobTitle",
    type: "text",
    id: "jobTitle",
  },
  {
    placeholder: "Select Country",
    name: "country",
    type: "text",
    id: "countryName",
  },
  {
    placeholder: "Phone Number",
    name: "phoneNumber",
    type: "text",
    id: "phoneNumber",
  },
  {
    placeholder: "Company Name",
    name: "companyName",
    type: "text",
    id: "companyName",
  },
];

const RequestADemoComponent = () => {
  const [countryList, setCountryList] = useState<string[]>([]);
  const [serverError, setServerError] = useState<any>({});

  const submitUserDetails = useSubmitUserDetails();

  const [searchParams] = useSearchParams();

  const [requestDemoData, setRequestDemoData] = useState<any>([]);

  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();

  const fetchRequestDemo = async () => {
    try {
      dispatch(setLoadingState(true));
      const response = await axiosInstance.get(
        apiConfig.Form.getRequestADemoDetails.api
      );
      response.data.data.map((attr: any, index: number) => {
        const requestDemo = attr?.attributes;
        setRequestDemoData(requestDemo);
      });
      dispatch(setLoadingState(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchRequestDemo();
  }, []);

  const [captchaToken, setCaptchaToken] = useState("");
  const [captchaReset, setCaptchaReset] = useState(false);

  useEffect(() => {
    const names = Object.values(countries).map((country) => country.name);
    setCountryList(names);
  }, []);

  const navigate = useNavigate();

  return (
    <div className="bg-bluePrimary flex justify-center lg:py-[6.25rem] ">
      <div className="w-full flex requestADemoGradient flex-col items-center lg:items-start py-[3.125rem] gap-y-[3.125rem] lg:gap-y-0 lg:px-[5rem] lg:py-0 xl:w-[78%] lg:justify-start lg:flex-row 3xl:max-w-[90rem] ">
        <div className="flex relative lg:pt-[8.125rem]  lg:w-6/12 z-10 ">
          <div className="absolute flex justify-center lg:justify-end h-fit w-full z-[-100] lg:mt-[35%]">
            <img
              alt="halfVairav"
              src={halfVairav}
              className="w-[12.5rem] hidden 2xl:mb-[20%] lg:flex"
            />
            <img
              alt="fullVairav"
              src={fullVairav}
              className="w-[10.314rem] flex lg:hidden"
            />
          </div>
          <div className="flex flex-col items-center justify-center gap-y-[1.6rem] xl: 2xl:mr-[5rem] w-full">
            <div className="text-orangePrimary roboto leading-tight font-black text-center text-primaryHeadingSm w-[17rem] lg:text-primaryHeading2xl">
              {requestDemoData?.heading}
            </div>
            <hr className="bg-white border-2 w-20 hidden lg:flex" />
            <div className="text-white source-sans font-semibold text-center w-[17.5rem] text-buttonSm lg:w-full lg:text-paragraphHomepage2xl">
              {requestDemoData?.subHeading}
            </div>
            <div className=" flex flex-col items-center gap-y-[0.625rem]">
              <div className="text-orangePrimary font-normal text-buttonSm lg:text-descriptionHeading2xl">
                {requestDemoData?.bulletPointsHeader}
              </div>
              <div className="flex w-fit flex-row  border  px-[1.25rem] py-[0.625rem] gap-x-[2.563rem] lg:gap-x-[2.3rem] lg:py-4 lg:px-7">
                {requestDemoData?.bulletPoints?.map((value: any) => (
                  <img
                    key={`${process.env.REACT_APP_URL}${value.image.data.attributes.url}`}
                    alt="AICPAIMG"
                    src={`${process.env.REACT_APP_URL}${value.image.data.attributes.url}`}
                    className="w-[4.125rem] lg:w-[5.813rem]"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-fit source-sans px-3 lg:px-0 lg:w-7/12">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              emailAddress: "",
              jobTitle: "",
              phoneNumber: "",
              companyName: "",
              country: "United States",
              countryCode: getCountryData("US").phone[0],
              solutionsRequired: [],
              requiresEmailsNewsletters: false,
            }}
            onSubmit={async (values, actions) => {
              const formWithCapatchaToken = {
                ...values,
                captchaToken,
                pageRoute: searchParams.get("path"),
              };
              submitUserDetails(
                "/api/security-services",
                formWithCapatchaToken,
                "Request A Demo",
                actions.resetForm,
                serverError,
                setServerError,
                captchaReset,
                setCaptchaReset,
                captchaToken
              );
            }}
            validationSchema={contactSchema}
            enableReinitialize={true}
          >
            {({ isSubmitting, values }) => (
              <Form className="w-full bg-[#00000065] text-white px-3 md:px-10 pt-[1.625rem] pb-[3.125rem] md:py-[2.125rem] flex flex-col lg:justify-start">
                <div className="text-buttonSm mb-[1.875rem] font-semibold lg:text-paragraphHomepage2xl text-center w-[16rem] self-center lg:w-full lg:text-start">
                  Fill out the form to fortify your organization's defense
                  against cyber threats today.
                </div>
                {formData?.map((items, index) => (
                  <React.Fragment key={index}>
                    {items.name === "country" ? (
                      <CustomDropdown
                        placeholder={items.placeholder}
                        name={items.name}
                        type={items.type}
                        serverError={
                          serverError &&
                          "field" in serverError &&
                          serverError.field === items.name &&
                          serverError.error
                        }
                        data={countryList}
                      />
                    ) : items.name === "phoneNumber" ? (
                      <CustomPhoneNumber
                        placeholder={items.placeholder}
                        phoneName={items.name}
                        countryCodeName={"countryCode"}
                        country={values.country}
                        type={items.type}
                        serverError={
                          (serverError &&
                            "field" in serverError &&
                            serverError.field === items.name) ||
                          (serverError.field === "countryCode" &&
                            serverError.error)
                        }
                      />
                    ) : (
                      <CustomInput
                        placeholder={items.placeholder}
                        name={items.name}
                        type={items.type}
                        serverError={
                          serverError &&
                          "field" in serverError &&
                          serverError.field === items.name &&
                          serverError.error
                        }
                      />
                    )}
                  </React.Fragment>
                ))}
                <div className="text-[14px] lg:text-descriptionHeading2xl">
                  <p className="mb-5 text-buttonSm lg:text-descriptionHeading2xl">
                    What are the issues that your organization is aiming to
                    resolve?
                  </p>
                  <CustomCheckboxSelect
                    name={"solutionsRequired"}
                    checkboxData={checkboxData}
                    serverError={
                      serverError &&
                      "field" in serverError &&
                      serverError.field === "solutionsRequired" &&
                      serverError.error
                    }
                  />
                  <p className=" text-buttonSm mb-2 lg:mb-5 lg:text-descriptionHeading2xl">
                    What do you expect to acknowledge from this demo?
                  </p>
                  <SingleCheckbox
                    setCaptchaToken={setCaptchaToken}
                    captchaReset={captchaReset}
                    serverError={serverError}
                    name="requiresEmailsNewsletters"
                    checkboxText={
                      "I would like to subscribe to the Newsletters from Vairav TECH and get informed about the latest trends of cybersecurity."
                    }
                    type={"request"}
                    disclaimer={
                      <>
                        By submitting this form, I agree to the{" "}
                        <span
                          onClick={() =>
                            navigate("/policies/terms-and-conditions")
                          }
                          className="underline cursor-pointer"
                        >
                          Website Terms of Conditions
                        </span>{" "}
                        and the{" "}
                        <span
                          onClick={() => navigate("/policies/privacy-notice")}
                          className="underline cursor-pointer"
                        >
                          Vairav TECH Privacy Policy.
                        </span>
                      </>
                    }
                  />
                </div>
                <div className="w-full flex justify-center items-center pt-[1.563rem] md:pt-9">
                  <OrangeGradientButton
                    buttonText="SUBMIT"
                    buttonSize="w-[300px]"
                    buttonType="submit"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RequestADemoComponent;
