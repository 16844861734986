import { useField, useFormikContext } from "formik";
import React, { ChangeEvent } from "react";

const CustomCheckboxSelect: React.FC<any> = ({
  checkboxData,
  uniqueName,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    let temp = [...field.value];
    if (event.target.checked) {
      let appendedArr = [...temp, value];
      setFieldValue("solutionsRequired", appendedArr);
    } else {
      let removedValueArr = temp.filter((e) => e !== value);
      setFieldValue("solutionsRequired", removedValueArr);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      {checkboxData?.map((item: string, key: number) => (
        <React.Fragment key={key}>
          <div className="flex justify-start items-start md:items-center gap-3  md:gap-5 pl-[15px] md:pl-4">
            <input
              checked={field.value.includes(item) ? true : false}
              id={uniqueName + item}
              onChange={(e) => {
                handleCheckboxChange(e, item);
              }}
              type="checkbox"
              className="w-5 h-5 md:w-5 md:h-5 "
            />
            <label
              htmlFor={uniqueName + item}
              className="flex-1 max-sm:flex-[1] text-buttonSm md:text-[15px]"
            >
              {item}
            </label>
          </div>
        </React.Fragment>
      ))}
      <div className="h-[1.5rem] w-full flex justify-end roboto text-[13px] text-red-500 px-2">
        {meta.touched && meta.error ? <div>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export default CustomCheckboxSelect;
