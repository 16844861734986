import { hexagonalPageProps } from "../../../Interfaces/Interface";
import Hexagonal from "./Hexagonal";

const HexagonalPage: React.FC<hexagonalPageProps> = ({ title, data }) => {
  return (
    <div className="w-full py-primaryPaddingYSm  sm:py-primaryPaddingY2xl">
      <div className="w-full flex justify-center text-white blueprimaryGradient font-black text-primaryHeadingSm roboto lg:text-primaryHeading2xl">
        {title}
      </div>
      <Hexagonal HexagonalData={data} />
    </div>
  );
};

export default HexagonalPage;
