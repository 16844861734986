import { Link } from "react-router-dom";
import { OrangeButtonProps } from "../../../Interfaces/Interface";
import { IoChevronDownOutline } from "react-icons/io5";

const NonBorderButton: React.FC<OrangeButtonProps> = ({
  buttonText,
  textColor,
  buttonFunction,
  path,
}) => {
  return (
    <>
      {path ? (
        <Link to={path || ""} className="">
          <button
            onClick={buttonFunction}
            className={`flex flex-row gap-x-2 ${
              textColor === "orange" ? "text-orangePrimary" : "text-white"
            } items-center hover:scale-105 hover:gap-x-4 hover:ease-in hover:text-orangePrimary landingCardButton text-descriptionHeadingSm lg:text-base`}
          >
            {buttonText}
            <IoChevronDownOutline className="no-hover-effect w-[18px] h-[18px] mt-[2px] landingCardVector" />
          </button>
        </Link>
      ) : (
        <button
          onClick={buttonFunction}
          className={`flex flex-row gap-x-2 ${
            textColor === "orange" ? "text-orangePrimary" : "text-white"
          } items-center hover:scale-105 hover:gap-x-4 hover:ease-in hover:text-orangePrimary landingCardButton text-descriptionHeadingSm lg:text-base`}
        >
          {buttonText}
          <IoChevronDownOutline className="no-hover-effect w-[18px] h-[18px] mt-[2px] landingCardVector" />
        </button>
      )}
    </>
  );
};

export default NonBorderButton;
