import { Link, useRouteError } from "react-router-dom";
import mascotIMG from "../../Images/mascot.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";

const ErrorElement = () => {
  const error: any = useRouteError();
  const errorStates = useSelector((root: RootState) => root.error);
  return (
    <div className="bg-bluePrimary">
      <div className="flex flex-col justify-center items-center h-screen max-w-[1920px]">
        <img src={mascotIMG} className="w-[9.563rem] lg:w-[8%]" />
        <div className="roboto text-orangePrimary text-primaryHeadingSm font-black lg:text-primaryHeading2xl">
          {error?.status
            ? String(error?.status)[0] + "xx"
            : errorStates?.statusCode
            ? String(errorStates.statusCode)[0] + "xx"
            : ""}
        </div>
        <div className="text-descriptionHeadingSm flex items-center flex-col tracking-wider lg:text-descriptionHeading2xl">
          <div>Please redirect to the </div>

          <Link to={"/"} className="underline text-orangePrimary">
            homepage
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorElement;
