import { useEffect } from "react";
import { approachContentBoxProps } from "../../../Interfaces/Interface";
import { CheckWords } from "../../../Utils/UtilityFunctions/UtilityFunction";
import {
  AnimationControls,
  Variant,
  motion,
  useAnimationControls,
  useInView,
} from "framer-motion";

const ApproachContentBox: React.FC<
  approachContentBoxProps & { lineControl: AnimationControls }
> = ({
  tier,
  heading,
  content,
  image,
  index,
  length,
  lineControl,
  product,
  useFinal = false,
}) => {
  const wordArr = CheckWords(heading);

  const lineGrowVerticalZero: Variant = {
    scaleY: 1,
    originY: 0,
    transition: { duration: 0.25 },
  };

  const lineGrowVerticalOne: Variant = {
    scaleY: 1,
    originY: 1,
    transition: { duration: 0.25 },
  };

  const lineGrowHorizontalZero: Variant = {
    scaleX: 1,
    originX: 0,
    transition: { duration: 0.25 },
  };

  const lineGrowHorizontalOne: Variant = {
    scaleX: 1,
    originX: 1,
    transition: { duration: 0.25 },
  };

  return (
    <>
      {index === 0 ? (
        <>
          <div className="w-full flex flex-col-reverse items-center px-[25px] gap-y-3 lg:h-[260px] lg:gap-y-0 lg:px-0 lg:flex-row lg:justify-center lg:pt-[40px]">
            <div className=" text-center tracking-wide text-white text-descriptionHeadingSm source-sans font-light w-full lg:font-semibold lg:leading-8 lg:text-descriptionHeading2xl lg:w-[40%] xl:lg:w-[40%] xl:w-[28.25%]">
              {content}
            </div>
            <div className="w-[7%] h-full justify-center hidden lg:flex" />
            <div className=" flex items-center gap-x-10 w-full flex-col gap-y-3 lg:gap-y-0 lg:flex-row lg:w-[40%] xl:lg:w-[40%] xl:w-[27%]">
              <img
                src={image}
                alt={tier}
                className=" shadowed w-[65px] h-[65px] lg:w-[200px] lg:h-[229.3px]"
              />
              <div className="flex flex-col justify-center w-full text-center items-center gap-y-3 lg:gap-y-0 lg:items-start lg:text-left lg:w-fit">
                <div
                  className={`source-sans font-black text-orangePrimary text-primaryHeadingSm leading-tight lg:leading-normal lg:text-primaryHeading2xl`}
                >
                  {tier}
                </div>
                <div
                  className={`source-sans font-semibold ${
                    product && product === "trident"
                      ? "text-orangePrimary lg:text-[28px]"
                      : "text-white lg:text-paragraphHomepage2xl"
                  } tracking-wide text-buttonSm w-[11rem] leading-tight lg:w-64 lg:leading-8`}
                >
                  {wordArr?.length === 2 ? (
                    <>
                      {wordArr[0]}
                      <br /> {wordArr[1]}
                    </>
                  ) : (
                    <>{heading}</>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[63px] flex-row lg:justify-center items-center hidden lg:flex">
            <div className="lg:w-[40%] xl:w-[28.25%] h-full text-center font-semibold leading-8 tracking-wide text-white source-sans lg:text-descriptionHeading2xl "></div>
            <div className="w-[7%] h-full flex justify-end items-end">
              <motion.div
                initial={{ scaleX: 0, originX: 1 }}
                variants={{ [`lineStart${index}_3`]: lineGrowHorizontalOne }}
                animate={lineControl}
                className="h-[3px] w-[51.6%] 2xl:w-[51.2%] bg-white"
              />
            </div>
            <div className="lg:w-[40%] xl:w-[27%] flex h-full items-center gap-x-10">
              <div className="w-[200px] flex flex-col items-center">
                <motion.div
                  initial={{ scaleY: 0, originY: 0 }}
                  variants={{ [`lineStart${index}_1`]: lineGrowVerticalZero }}
                  animate={lineControl}
                  className="h-[60px] w-[3px] bg-white"
                />
                <div className="h-[3px] flex flex-row justify-end w-full lg:pr-[49.5%] xl:pr-[49%]">
                  <motion.div
                    initial={{ scaleX: 0, originX: 1 }}
                    variants={{
                      [`lineStart${index}_2`]: lineGrowHorizontalOne,
                    }}
                    animate={lineControl}
                    className="h-[3px] w-full bg-white"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center text-left"></div>
            </div>
          </div>
        </>
      ) : (index + 1) % 2 !== 0 &&
        (!useFinal || index + 1 !== length) &&
        index !== 0 ? (
        <>
          <div className="w-full flex flex-col-reverse items-center px-[25px] gap-y-3 lg:h-[260px] lg:gap-y-0 lg:px-0 lg:flex-row lg:justify-center">
            <div className="text-center tracking-wide text-white text-descriptionHeadingSm source-sans font-light w-full lg:font-semibold lg:leading-8 lg:text-descriptionHeading2xl lg:w-[40%] xl:lg:w-[40%] xl:w-[28.25%]">
              {content}
            </div>
            <div className="w-[7%] h-full justify-center hidden lg:flex">
              <motion.div
                initial={{ scaleY: 0, originY: 0 }}
                variants={{ [`lineStart${index}_1`]: lineGrowVerticalZero }}
                animate={lineControl}
                className="w-[3px] bg-white"
              />
            </div>
            <div className="flex items-center gap-x-10 w-full flex-col gap-y-3 lg:gap-y-0 lg:flex-row lg:w-[40%] xl:lg:w-[40%] xl:w-[27%]">
              <img
                src={image}
                alt={tier}
                className="shadowed w-[65px] lg:w-[200px] lg:h-[229.3px] mt-[7%]"
              />
              <div className="flex flex-col justify-center text-center gap-y-2 lg:gap-y-0 lg:text-left">
                <div
                  className={`source-sans font-black text-orangePrimary text-primaryHeadingSm leading-tight lg:leading-normal lg:text-primaryHeading2xl`}
                >
                  {tier}
                </div>
                <div
                  className={`source-sans font-semibold ${
                    product && product === "trident"
                      ? "text-orangePrimary lg:text-[28px]"
                      : "text-white lg:text-paragraphHomepage2xl"
                  } tracking-wide text-buttonSm w-[11rem] leading-tight lg:w-64 lg:leading-8`}
                >
                  {wordArr?.length === 2 ? (
                    <>
                      {wordArr[0]}
                      <br /> {wordArr[1]}
                    </>
                  ) : (
                    <>{heading}</>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[63px] flex-row lg:justify-center items-center hidden lg:flex">
            <div className="lg:w-[40%] xl:w-[28.25%] h-full text-center font-semibold leading-8 tracking-wide text-white source-sans lg:text-descriptionHeading2xl " />
            <div className="w-[7%] h-full flex flex-col justify-end items-center">
              <motion.div
                initial={{ scaleY: 0, originY: 0 }}
                variants={{ [`lineStart${index}_2`]: lineGrowVerticalZero }}
                animate={lineControl}
                className="h-[60px] w-[3px] bg-white"
              />
              <div className="h-[3px] flex flex-row justify-end w-full">
                <motion.div
                  initial={{ scaleX: 0, originX: 0 }}
                  variants={{ [`lineStart${index}_3`]: lineGrowHorizontalZero }}
                  animate={lineControl}
                  className="h-[3px] w-[51.6%] 2xl:w-[51.2%] bg-white"
                />
              </div>
            </div>
            <div className="lg:w-[40%] xl:w-[27%] flex h-full items-center gap-x-10">
              <div className="w-[200px] flex flex-col items-center">
                <motion.div
                  initial={{ scaleY: 0, originY: 1 }}
                  variants={{ [`lineStart${index}_5`]: lineGrowVerticalOne }}
                  animate={lineControl}
                  className="h-[60px] w-[3px] bg-white"
                />
                <div className="h-[3px] flex flex-row justify-end w-full pr-[49%]">
                  <motion.div
                    initial={{ scaleX: 0, originX: 0 }}
                    variants={{
                      [`lineStart${index}_4`]: lineGrowHorizontalZero,
                    }}
                    animate={lineControl}
                    className="h-[3px] w-full bg-white"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center text-left"></div>
            </div>
          </div>
        </>
      ) : (index + 1) % 2 !== 1 && (!useFinal || index + 1 !== length) ? (
        <>
          <div className="w-full flex flex-col px-[25px] gap-y-3 lg:h-[260px] lg:gap-y-0 lg:px-0 lg:flex-row lg:justify-center items-center">
            <div className="lg:w-[40%] xl:w-[29.50%]  flex flex-col-reverse justify-end items-center gap-x-10 gap-y-3 lg:pt-[40px] lg:gap-y-0 lg:flex-row">
              <div className="flex flex-col justify-center text-center items-center gap-y-3 lg:gap-y-0 lg:text-right lg:items-end ">
                <div
                  className={`source-sans font-black text-orangePrimary text-primaryHeadingSm leading-tight lg:leading-normal lg:text-primaryHeading2xl`}
                >
                  {tier}
                </div>
                <div
                  className={`source-sans font-semibold ${
                    product && product === "trident"
                      ? "text-orangePrimary lg:text-[28px]"
                      : "text-white lg:text-paragraphHomepage2xl"
                  } tracking-wide text-buttonSm w-[11rem] leading-tight lg:w-64 lg:text-paragraphHomepage2xl lg:leading-8`}
                >
                  {wordArr?.length === 2 ? (
                    <>
                      {wordArr[0]}
                      <br /> {wordArr[1]}
                    </>
                  ) : (
                    <>{heading}</>
                  )}
                </div>
              </div>
              <img
                src={image}
                alt={tier}
                className="shadowed w-[65px] lg:w-[200px] lg:h-[229.3px]"
              />
            </div>
            <div className="w-[7%] h-full justify-center hidden lg:flex">
              <motion.div
                initial={{ scaleY: 0, originY: 0 }}
                variants={{ [`lineStart${index}_1`]: lineGrowVerticalZero }}
                animate={lineControl}
                className="w-[3px] bg-white"
              />
            </div>
            <div className=" text-center tracking-wide text-white text-descriptionHeadingSm source-sans font-light w-full lg:font-semibold lg:leading-8 lg:text-descriptionHeading2xl lg:pt-[40px] lg:w-[40%] xl:lg:w-[40%] xl:w-[28.25%]">
              {content}
            </div>
          </div>
          <div className="w-full h-[63px] flex-row lg:justify-center items-center hidden lg:flex">
            <div className="lg:w-[40%] xl:w-[29.50%] flex justify-end items-center gap-x-10">
              <div className="w-[200px] flex flex-col items-center">
                <motion.div
                  initial={{ scaleY: 0, originY: 1 }}
                  variants={{ [`lineStart${index}_5`]: lineGrowVerticalOne }}
                  animate={lineControl}
                  className="h-[60px] w-[3px] bg-white"
                />
                <div className="h-[3px] flex flex-row justify-center w-full lg:pl-[49.5%] xl:pl-[49%] ">
                  <motion.div
                    initial={{ scaleX: 0, originX: 1 }}
                    variants={{
                      [`lineStart${index}_4`]: lineGrowHorizontalOne,
                    }}
                    animate={lineControl}
                    className="h-[3px] w-full bg-white"
                  />
                </div>
              </div>
            </div>
            <div className="w-[7%] h-full flex flex-col justify-end items-center">
              <motion.div
                initial={{ scaleY: 0, originY: 0 }}
                variants={{ [`lineStart${index}_2`]: lineGrowVerticalZero }}
                animate={lineControl}
                className="h-[60px] w-[3px] bg-white"
              />
              <div className="h-[3px] flex flex-row justify-start w-full">
                <motion.div
                  initial={{ scaleX: 0, originX: 1 }}
                  variants={{ [`lineStart${index}_3`]: lineGrowHorizontalOne }}
                  animate={lineControl}
                  className="h-[3px] w-[51%] bg-white"
                />
              </div>
            </div>
            <div className="lg:w-[40%] xl:w-[28.25%] text-center font-semibold tracking-wide leading-8 text-white source-sans lg:text-descriptionHeading2xl"></div>
          </div>
        </>
      ) : index + 1 === length && useFinal ? (
        <>
          <div className="w-full h-[80px] justify-center pl-0 hidden lg:flex 2xl:pl-[1.3%]">
            <motion.div
              initial={{ scaleY: 0, originY: 0 }}
              variants={{ [`lineStart${index}_1`]: lineGrowVerticalZero }}
              animate={lineControl}
              className="h-full w-[3px] bg-white"
            />
          </div>
          <div className="w-full h-fit flex flex-col items-center pl-0 gap-y-3 lg:gap-y-0  2xl:pl-[1.3%]">
            <img
              src={image}
              alt={tier}
              className="shadowed w-[65px] lg:w-[200px] lg:h-[229.3px]"
            />
            <div
              className={`source-sans font-black text-orangePrimary text-primaryHeadingSm leading-tight lg:leading-normal lg:text-primaryHeading2xl`}
            >
              {heading}
            </div>
            <div className="text-center tracking-wide text-white source-sans font-light text-descriptionHeadingSm px-[25px] lg:px-0 lg:font-semibold lg:leading-8 lg:text-descriptionHeading2xl lg:w-[40%] xl:lg:w-[40%] xl:w-[28.25%]">
              {content}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ApproachContentBox;
