import axios from "axios";
import { useEffect, useState } from "react";
import Navbar from "../../../Components/common/Navbar/Navbar";
import WhyVairavLandingComponent from "../../../Components/WhyVairav/WhyVairavLandingComponent/WhyVairavLandingComponent";
import WhyVairavGoals from "../../../Components/WhyVairav/WhyVairavGoals/WhyVairavGoals";
import IndustryRecognitionComponent from "../../../Components/WhyVairav/IndustryRecognitionComponent/IndustryRecognitionComponent";
import PageFooter from "../../../Components/common/Footer/PageFooter";
import useAxiosInstance from "../../../Utils/UtilityFunctions/RequestConfig";
import WhyVairavPoints from "../../../Components/WhyVairav/WhyVairavPoints/WhyVairavPoints";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../../Redux/Loading-Slice";
import GetStartedComponent from "../../../Components/FormComponents/GetStarted/GetStartedComponent";
import apiConfig from "../../../Utils/API/apiConfig";

const WhyVairavPage = () => {
  const location = useLocation();

  const [tridentData, setTridentData] = useState<any>([]);
  const [toc1, setToc1] = useState<any>();
  const [toc2, setToc2] = useState<any>();
  const [toc3, setToc3] = useState<any>();
  const [toc4, setToc4] = useState<any>();
  const [toc5, setToc5] = useState<any>();

  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();

  const fetchTridentData = async () => {
    try {
      dispatch(setLoadingState(true));
      const response = await axiosInstance.get(
        apiConfig.WhyVairav.getDetails.api
      );

      response.data.data.map((attr: any, index: number) => {
        const tridentData = attr.attributes.whyVairav;
        setTridentData(tridentData);
      });
      dispatch(setLoadingState(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTridentData();
  }, []);

  useEffect(() => {
    if (tridentData && tridentData.length > 0) {
      const one_toc = tridentData?.filter((data: any) => data.sectionToc === 1);
      setToc1(one_toc[0]);

      const two_toc = tridentData?.filter((data: any) => data.sectionToc === 2);
      setToc2(two_toc[0]);

      const three_toc = tridentData?.filter(
        (data: any) => data.sectionToc === 3
      );
      setToc3(three_toc[0]);

      const four_toc = tridentData?.filter(
        (data: any) => data.sectionToc === 4
      );
      setToc4(four_toc[0]);

      const five_toc = tridentData?.filter(
        (data: any) => data.sectionToc === 5
      );
      setToc5(five_toc[0]);
    }
  }, [tridentData]);
  return (
    <div className="flex   bg-bluePrimary overflow-hidden w-full sm:min-h-screen">
      <div className={`flex-col homepageLayout enableBlur w-full`}>
        <Navbar />
        <WhyVairavLandingComponent data={toc1} isLinearGrident={true} />
        <WhyVairavPoints data={toc2} imageChange={2000} />
        <WhyVairavGoals data={toc3} />
        <IndustryRecognitionComponent data={toc4} />
        <GetStartedComponent data={toc5} pageRoute={location.pathname} />
        <PageFooter />
      </div>
    </div>
  );
};

export default WhyVairavPage;
