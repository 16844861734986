import Particles, { initParticlesEngine } from "@tsparticles/react";
import { particlesOptions } from "../../../Utils/ParticlesConfig/particles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { loadSlim } from "@tsparticles/slim";

const ParticleAnimation = () => {
  const particleRef = useRef(null);
  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {});
  }, []);

  const options: any = useMemo(() => particlesOptions, []);

  return <Particles id="tsparticles" options={options} />;
};

export default React.memo(ParticleAnimation);
