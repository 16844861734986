import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface CardState {
  inCardHover: boolean;
}

// Define the initial state using that type
const initialState: CardState = {
  inCardHover: false,
};

export const cardBlurSlice = createSlice({
  name: "Authorization",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setInCardHover: (state, action: PayloadAction<boolean>) => {
      state.inCardHover = action.payload;
    },
  },
});

export const { setInCardHover } = cardBlurSlice.actions;

export default cardBlurSlice.reducer;
