import PageFooter from "../../Components/common/Footer/PageFooter";
import Navbar from "../../Components/common/Navbar/Navbar";
import RequestADemoComponent from "../../Components/FormComponents/RequestADemo/RequestADemoComponent";

const RequestADemoPage = () => {
  return (
    <div className="flex flex-col bg-bluePrimary overflow-hidden w-full 3xl:min-h-screen 3xl:justify-between">
      <Navbar />
      <RequestADemoComponent />
      <PageFooter />
    </div>
  );
};

export default RequestADemoPage;
