import { useEffect, useState } from "react";
import Navbar from "../../../Components/common/Navbar/Navbar";
import ReactMarkdown from "react-markdown";
import { parseDateToCustomFormat } from "../../../Utils/UtilityFunctions/UtilityFunction";
import rehypeRaw from "rehype-raw";
import PageFooter from "../../../Components/common/Footer/PageFooter";
import { useParams } from "react-router-dom";
import useAxiosInstance from "../../../Utils/UtilityFunctions/RequestConfig";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../../Redux/Loading-Slice";
import apiConfig from "../../../Utils/API/apiConfig";

const BlogDetailsPage = () => {
  const [blogDetails, setBlogDetails] = useState<any>({});

  const { id } = useParams();

  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();

  useEffect(() => {
    GetBlogDetails();
  }, []);

  const GetBlogDetails = async () => {
    try {
      dispatch(setLoadingState(true));
      const response = await axiosInstance.get(
        apiConfig.Blog.getBlogIDDetails.api(id || "")
      );
      const tempBlogData = response?.data?.data?.attributes || {};
      setBlogDetails(tempBlogData);
      dispatch(setLoadingState(false));
    } catch {}
  };

  return (
    <div className="flex bg-bluePrimary overflow-hidden w-full">
      <div className={`flex-col mainPlastic homepageLayout enableBlur w-full`}>
        <div className="flex flex-col bg-bluePrimary">
          <Navbar />
          <div className="flex flex-col bg-white">
            <div className="bg-bluePrimary  text-white roboto font-black px-homepagePaddingSm text-primaryHeadingSm pb-10 pt-[3.125rem] lg:pt-[6.25rem] lg:text-primaryHeading2xl lg:px-homepagePaddingLg xl:px-homepagePadding2xl">
              <div className="w-full xl:w-[45%] roboto">
                {blogDetails?.title}
              </div>
            </div>
            <div className="flex flex-col pt-5 pb-[3.125rem] gap-y-6 px-homepagePaddingSm text-[0.6rem] lg:text-base lg:px-homepagePaddingLg xl:px-homepagePadding2xl">
              <div className="flex gap-x-2 source-sans text-[#5A5B5D] ">
                <div className="tracking-widest">
                  {parseDateToCustomFormat(blogDetails?.createdAt)}
                </div>
                <div className="w-[2px] h-5 bg-[#5A5B5D]" />
                <div>by {blogDetails?.authorName}</div>
              </div>
              <div className="w-full h-fit text-black blogDetailsParent">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {blogDetails?.blogDetail}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
        <PageFooter />
      </div>
    </div>
  );
};

export default BlogDetailsPage;
