import { useNavigate } from "react-router-dom";
import Navbar from "../../../Components/common/Navbar/Navbar";
import OrangeGradientButton from "../../../Components/common/button/OrangeGradientButton";
import mascotIMG from "../../../Images/mascot.svg";

const FormSucessPage = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-bluePrimary">
      <div className="flex flex-col min-h-screen pb-[3.125rem] linear_bg_Class">
        <Navbar />
        <div className="flex flex-col items-center mt-[10.625rem] mb-[6.35rem]">
          <div className="w-full flex justify-center items-center">
            <img src={mascotIMG} className="w-[15.313rem]" />
          </div>
          <div className="flex flex-col items-center mt-[3.563rem] mb-[3.125rem]">
            <div className="roboto text-primaryHeading2xl font-black">
              Thank you for reaching out to us.
            </div>
            <div className="text-paragraphHomepage2xl font-semibold">
              We will get in touch with you shortly.
            </div>
          </div>

          <div>
            <OrangeGradientButton
              buttonFunction={() => {
                navigate("/products/trident");
              }}
              buttonSize="20.438rem"
              buttonText="EXPLORE OUR SOLUTIONS"
              textSize="1.25rem"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSucessPage;
