import React, { useEffect, useState } from "react";
import LocationImg from "../../../Images/Location.svg";
import { Formik, Form } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { countries, getCountryData } from "countries-list";
import { useSubmitUserDetails } from "../../../Utils/UtilityFunctions/UtilComponentFunctions";
import { contactSchema } from "../../../Schema/Forms/GeneralFormSchema";
import CustomInput from "../../common/customInput/CustomInput";
import CustomDropdown from "../../common/customInput/CustomDropdown";
import CustomCheckboxSelect from "../../common/customInput/CustomCheckboxSelect";
import SingleCheckbox from "../../common/customInput/SingleCheckbox";
import OrangeGradientButton from "../../common/button/OrangeGradientButton";
import CustomPhoneNumber from "../../common/customInput/CustomPhoneNumber";

const checkboxData = [
  "Managed Detection & Response",
  "SOC-as-a-service",
  "Penetration Testing",
  "Information Security Audit",
  "Governance, Risk & Compliance",
  "Managed Cybersecurity Awareness",
  "Information Security Consultation",
];

const formData = [
  {
    placeholder: "Email Address",
    name: "emailAddress",
    type: "email",
    id: "emailAddress",
  },
  {
    placeholder: "First Name",
    name: "firstName",
    type: "text",
    id: "firstName",
  },
  {
    placeholder: "Last Name",
    name: "lastName",
    type: "text",
    id: "lastName",
  },
  {
    placeholder: "Job Title",
    name: "jobTitle",
    type: "text",
    id: "jobTitle",
  },
  {
    placeholder: "Select Country",
    name: "country",
    type: "text",
    id: "countryName",
  },
  {
    placeholder: "Phone Number",
    name: "phoneNumber",
    type: "text",
    id: "phoneNumber",
  },
  {
    placeholder: "Company Name",
    name: "companyName",
    type: "text",
    id: "companyName",
  },
];

const GetStartedComponent = ({
  data,
  pageRoute,
}: {
  data: any;
  pageRoute?: string;
}) => {
  const [countryList, setCountryList] = useState<string[]>([]);
  const [captchaReset, setCaptchaReset] = useState(false);
  const [serverError, setServerError] = useState<any>({});

  const [searchParams] = useSearchParams();
  const submitUserDetails = useSubmitUserDetails();

  useEffect(() => {
    const names = Object.values(countries).map((country) => country.name);
    setCountryList(names);
  }, []);

  const [captchaToken, setCaptchaToken] = useState("");

  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center">
      <div className=" relative flex justify-center  lg:py-[6.25rem] max-w-[1920px]">
        <div className="relative flex py-[3.125rem] lg:py-0 flex-col lg:flex-row bg-[rgba(225,225,225,0.04)] lg:rounded-sm shadow-md px-3 lg:px-[4rem] xl:w-[78%] gap-x-[5rem]">
          <div className="px-6 lg:px-0 lg:w-5/12  lg:pt-[8.125rem] flex flex-col items-start gap-y-[0.938rem] lg:gap-y-[1.6rem]">
            <div className="text-orangePrimary roboto text-primaryHeadingSm lg:text-primaryHeading2xl leading-tight font-black text-left w-[70%] lg:w-auto">
              {data?.get_started?.data?.attributes?.heading || data?.heading}
            </div>
            <hr className="bg-white border-2 w-12 lg:w-20" />
            <div className="text-white source-sans text-buttonSm lg:text-paragraphHomepage2xl font-semibold text-start">
              {data?.get_started?.data?.attributes?.subHeading ||
                data?.subHeading}
            </div>
            <div className=" flex flex-col  gap-y-[0.625rem]">
              <div className="text-white text-descriptionHeadingSm md:text-descriptionHeading2xl font-normal">
                {data?.get_started?.data?.attributes?.description ||
                  data?.description}
              </div>
              <div className="flex flex-row py-4   gap-x-[3.6rem]">
                <div className=" py-5 ">
                  <p className="text-buttonSm lg:text-descriptionHeading2xl text-orangePrimary mb-4 font-light tracking-widest">
                    {data?.get_started?.data?.attributes?.bulletPointsHeader ||
                      data?.bulletPointsHeader}
                  </p>
                  <ul className="lg:pt-2">
                    {/* condition for Form RightSide data that come from cms and dummy data for homepage */}
                    {data?.get_started?.data?.attributes?.bulletPoints !==
                    undefined ? (
                      <>
                        {data?.get_started?.data?.attributes?.bulletPoints?.map(
                          (value: any, index: any) => (
                            <li
                              key={index}
                              className={`text-buttonSm lg:text-button2xl flex justify-start w-[19rem] items-center gap-5 ${
                                value?.image?.data?.attributes?.url && "pt-3"
                              }`}
                            >
                              {value?.image?.data?.attributes?.url && (
                                <>
                                  <img
                                    src={`${process.env.REACT_APP_URL}${value?.image?.data?.attributes?.url}`}
                                    alt="icon"
                                    className="w-9"
                                  />
                                </>
                              )}
                              <p className="leading-6">{value.title}</p>
                            </li>
                          )
                        )}
                      </>
                    ) : data?.bulletPoints &&
                      data?.bulletPoints?.length > 0 &&
                      data?.bulletPoints[0] &&
                      "title" in data?.bulletPoints[0] ? (
                      <>
                        {data?.bulletPoints?.map((value: any, index: any) => (
                          <li
                            key={index}
                            className={`text-buttonSm lg:text-button2xl flex justify-start w-[19rem] items-center gap-5 ${
                              value?.image?.data?.attributes?.url && "pt-3"
                            }`}
                          >
                            {value?.image?.data?.attributes?.url && (
                              <>
                                <img
                                  src={`${process.env.REACT_APP_URL}${value?.image?.data?.attributes?.url}`}
                                  alt="icon"
                                  className="w-9"
                                />
                              </>
                            )}
                            <p className="leading-6">{value.title}</p>
                          </li>
                        ))}
                      </>
                    ) : (
                      <>
                        {/* this is dummy data for Homepage */}
                        {data?.bulletPoints?.map((value: any, index: any) => (
                          <li
                            key={index}
                            className={`text-buttonSm lg:text-button2xl flex w-[19rem] justify-start items-center gap-5 ${
                              value?.icon && "pt-3 "
                            }`}
                          >
                            {value?.icon && (
                              <>
                                <img
                                  src={value?.icon === "abc" ? LocationImg : ""}
                                  alt="icon"
                                  className="w-9"
                                />
                              </>
                            )}

                            <span className="leading-6">
                              {value.bulletPoint}
                            </span>
                          </li>
                        ))}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-fit source-sans  lg:px-0 lg:w-7/12">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                emailAddress: "",
                jobTitle: "",
                phoneNumber: "",
                companyName: "",
                country: "United States",
                countryCode: getCountryData("US").phone[0],
                solutionsRequired: [],
                requiresEmailsNewsletters: false,
              }}
              onSubmit={async (values, actions) => {
                const formWithCapatchaToken = {
                  ...values,
                  captchaToken,
                  ...{
                    pageRoute: pageRoute ? pageRoute : searchParams.get("path"),
                  },
                };
                submitUserDetails(
                  "/api/security-services",
                  formWithCapatchaToken,
                  "Get Started",
                  actions.resetForm,
                  serverError,
                  setServerError,
                  captchaReset,
                  setCaptchaReset,
                  captchaToken
                );
              }}
              validationSchema={contactSchema}
              enableReinitialize={true}
            >
              {({ isSubmitting, values }) => (
                <Form className="w-full bg-[#00000065] text-white px-3 md:px-10 pt-[1.625rem] pb-[3.125rem] md:py-[2.125rem] flex flex-col lg:justify-start">
                  <div className="text-buttonSm mb-[1.875rem] font-semibold lg:text-paragraphHomepage2xl text-center w-[16rem] self-center lg:w-full lg:text-start">
                    Fill out the form to fortify your organization's defense
                    against cyber threats today.
                  </div>
                  {formData?.map((items, index) => (
                    <React.Fragment key={index}>
                      {items.name === "country" ? (
                        <CustomDropdown
                          placeholder={items.placeholder}
                          name={items.name}
                          type={items.type}
                          serverError={
                            serverError &&
                            "field" in serverError &&
                            serverError.field === items.name &&
                            serverError.error
                          }
                          data={countryList}
                        />
                      ) : items.name === "phoneNumber" ? (
                        <CustomPhoneNumber
                          placeholder={items.placeholder}
                          phoneName={items.name}
                          countryCodeName={"countryCode"}
                          country={values.country}
                          type={items.type}
                          serverError={
                            (serverError &&
                              "field" in serverError &&
                              serverError.field === items.name) ||
                            (serverError.field === "countryCode" &&
                              serverError.error)
                          }
                        />
                      ) : (
                        <CustomInput
                          placeholder={items.placeholder}
                          name={items.name}
                          type={items.type}
                          serverError={
                            serverError &&
                            "field" in serverError &&
                            serverError.field === items.name &&
                            serverError.error
                          }
                        />
                      )}
                    </React.Fragment>
                  ))}
                  <div className="text-[14px] lg:text-descriptionHeading2xl">
                    <p className="mb-5 text-buttonSm lg:text-descriptionHeading2xl">
                      The solutions you're looking to solve:
                    </p>
                    <CustomCheckboxSelect
                      name={"solutionsRequired"}
                      checkboxData={checkboxData}
                      serverError={
                        serverError &&
                        "field" in serverError &&
                        serverError.field === "solutionsRequired" &&
                        serverError.error
                      }
                    />

                    <SingleCheckbox
                      setCaptchaToken={setCaptchaToken}
                      captchaReset={captchaReset}
                      serverError={serverError}
                      name="requiresEmailsNewsletters"
                      checkboxText={
                        "I'm looking forward to receiving emails and newsletters from Vairav Tech."
                      }
                      type={"home"}
                      disclaimer={
                        <>
                          By submitting this form, I agree to the{" "}
                          <span
                            onClick={() =>
                              navigate("/policies/terms-and-conditions")
                            }
                            className="underline cursor-pointer"
                          >
                            Website Terms of Conditions
                          </span>{" "}
                          and the{" "}
                          <span
                            onClick={() => navigate("/policies/privacy-notice")}
                            className="underline cursor-pointer"
                          >
                            Vairav TECH Privacy Policy.
                          </span>
                        </>
                      }
                    />
                  </div>
                  <div className="w-full flex justify-center items-center pt-[1.563rem] md:pt-9">
                    <OrangeGradientButton
                      buttonText="SUBMIT"
                      buttonSize="w-[300px]"
                      buttonType="submit"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStartedComponent;
