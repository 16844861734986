import { createAction } from "@reduxjs/toolkit";

export const CheckWords = (title: string) => {
  return title?.split(" ");
};

export const renderLineBreaks = (str: string) => {
  return str?.replace(/\n/g, "<br />");
};

export const checkForPhoneNumbers = (value: string): boolean => {
  return /^\+?\d+$/.test(value);
};

export const extractFileName = (url: string) => {
  const parts = url.split("/");
  return parts[parts.length - 1];
};

export const getPagesArray = (
  recordsPerPage: number,
  totalDataLength: number
): number[] => {
  const npage = Math.ceil(totalDataLength / recordsPerPage);
  const numbers: number[] = [];
  for (let i = 1; i <= npage; i++) {
    numbers.push(i);
  }
  return numbers;
};

export const splitFirstWord = (text: string) => {
  let splitText = text.split(" ");
  if (splitText && splitText?.length > 1) {
    return [splitText[0], splitText.slice(1).join(" ")];
  } else {
    return ["", ""];
  }
};

export const replaceSpace = (originalString: string) => {
  const temp = originalString.replace(/[;,\/\?:@&=\+\$_\.\!\~\*'\(\)#]/g, "");
  return temp.replace(/ /g, "-");
};

export function parseDateToCustomFormat(dateString: string) {
  // Parse the input date string into a Date object
  const date = new Date(dateString);

  // Extract the year, month, and day from the Date object
  const year = date.getUTCFullYear();
  const month = date
    .toLocaleString("default", { month: "short" })
    .toUpperCase();
  const day = date.getUTCDate();

  // Construct the formatted date string
  const formattedDate = `${month} ${day < 10 ? "0" + day : day}, ${year}`;

  return formattedDate;
}

export const revertAll = createAction("REVERT_ALL");
