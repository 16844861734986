import { Link } from "react-router-dom";
import { useEffect } from "react";
import useAxiosInstance from "../../../Utils/UtilityFunctions/RequestConfig";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { setFooterData } from "../../../Redux/CMSEssentials";
import apiConfig from "../../../Utils/API/apiConfig";

const PageFooter = () => {
  const axiosInstance = useAxiosInstance();

  const footerData = useSelector(
    (state: RootState) => state.CMSEssentials.footerData
  );
  const dispatch = useDispatch();

  const fetchFooterData = async () => {
    try {
      const response = await axiosInstance.get(apiConfig.Footer.getDetails.api);
      response.data.data.map((attr: any, index: number) => {
        const temp = attr?.attributes || null;
        dispatch(setFooterData(temp));
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (footerData === null) {
      fetchFooterData();
    }
  }, [footerData]);
  return (
    <div className="flex w-full bg-[#040C11] justify-center">
      <div className="w-full flex flex-col max-w-[1920px] overflow-hidden  gap-y-6 source-sans px-homepagePaddingSm pt-[3.125rem] pb-[1rem] lg:px-homepagePaddingLg xl:px-homepagePadding2xl lg:pb-[3.125rem] lg:pt-[6.25rem]">
        <div className="flex w-full justify-between flex-col lg:flex-row lg:gap-x-[5rem] xl:gap-x-[10rem]">
          <div className="flex flex-col  gap-y-[0.5rem] font-light text-white text-descriptionHeadingSm lg:text-navbar2xl">
            <div className="flex flex-col gap-y-2 w-[10.75rem] lg:pt-0">
              <img
                alt="tridentIMG"
                src={`${process.env.REACT_APP_URL}${footerData?.footerLeftSide?.image?.data?.attributes?.url}`}
                className="w-full"
              />
              <div className="w-full">
                <Link
                  to={"/products/trident"}
                  aria-label="Learn more about Trident product"
                >
                  <button
                    className={`w-full border-2 border-[#F37027] delay-[40ms] transit source-sans text-white py-[0.625rem] font-normal flex justify-center text-buttonSm `}
                  >
                    LEARN MORE
                  </button>
                </Link>
              </div>
            </div>
            <div className="font-normal pt-[2rem] pb-0 lg:pt-[1.563rem] lg:pb-[0.6rem]">
              {footerData?.footerLeftSide?.heading}
            </div>
            <div className="flex flex-col gap-y-[0.938rem]">
              {footerData?.footerLeftSide?.subTitle.map((value: any) => (
                <div key={value?.description}>
                  <div className="text-orangePrimary font-semibold w-full flex flex-nowrap">
                    {value?.title}
                  </div>
                  <div className="w-full flex flex-col lg:w-[11.6rem]">
                    <div className="w-full">{value?.description}</div>

                    <div className="w-full">{value?.phoneNumber}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col w-full lg:pt-[2.5rem]">
            <div className="w-full flex  justify-between flex-col lg:mb-[-2rem] lg:flex-row">
              {footerData?.footerList?.map((value: any, index: number) => (
                <div
                  key={value?.heading + "ForFooter"}
                  className="flex flex-col w-fit gap-y-[0.5rem]  font-light text-white text-descriptionHeadingSm pt-[2rem] lg:pt-0  lg:text-navbar2xl"
                >
                  <div className="font-normal">{value?.heading}</div>
                  {value?.subLink?.map((value: any) => (
                    <div key={value?.heading} className="cursor-pointer">
                      <Link to={value?.url} aria-label={value?.heading}>
                        {value?.heading}
                      </Link>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className="w-full flex flex-row items-center justify-between sm:justify-start lg:justify-end mt-[2rem] lg:pt-0 lg:gap-x-[3.125rem]">
              {footerData?.media?.data?.map((value: any, index: number) => (
                <img
                  key={value?.attributes?.url}
                  alt={"achievements"}
                  src={`${process.env.REACT_APP_URL}${value?.attributes?.url}`}
                  className="w-[4.8125rem] lg:w-[5.75rem]"
                />
              ))}
            </div>
          </div>
        </div>

        <hr className="hidden lg:flex" />
        <div className="flex justify-between   text-white font-normal flex-col items-start gap-y-4 text-[0.562rem] lg:text-[0.625rem] lg:gap-y-0 lg:gap-x-8 lg:items-center lg:flex-row">
          <img
            alt="vairavIMG"
            src={`${process.env.REACT_APP_URL}${footerData?.socialMediaAndPrivacyPolices?.footerLogo?.data?.attributes?.url}`}
            className="h-[1.563rem] cursor-pointer"
          />
          <div className="flex flex-row items-center flex-wrap gap-x-4">
            {footerData?.socialMediaAndPrivacyPolices?.socialMedia?.map(
              (value: any) => (
                <Link
                  to={value?.url}
                  target="_blank"
                  key={value?.socialMediaIcon?.data?.attributes?.url}
                >
                  <img
                    className="h-[1.375rem] w-[1.375rem]"
                    alt={"media_link"}
                    src={`${process.env.REACT_APP_URL}${value?.socialMediaIcon?.data?.attributes?.url}`}
                  />
                </Link>
              )
            )}
          </div>
          <div className="grid-cols-[auto_auto_auto_auto] justify-between md:grid-cols-[repeat(auto-fit,minmax(0,1fr))] w-full grid mt-10 font-light sm:justify-between sm:flex sm:grid-cols-12 lg:grid-cols-9 lg:justify-between lg:flex lg:flex-row lg:mt-0 lg:w-[44.125rem]">
            {footerData?.socialMediaAndPrivacyPolices?.privacyAndPolicy.map(
              (value: any) => (
                <Link key={value?.heading} to={value?.url}>
                  <div className="col-span-1 w-fit cursor-pointer">
                    {value?.heading}
                  </div>
                </Link>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageFooter;
