import { productsLandingComponentProps } from "../../../Interfaces/Interface";

const WhyVairavLandingComponent: React.FC<productsLandingComponentProps> = ({
  data,
  isLinearGrident,
}) => {
  return (
    <div
      className={`flex justify-center ${isLinearGrident && "linear_secound"} `}
    >
      <div
        className={`flex flex-col w-full items-center  px-homepagePaddingSm gap-y-[0.625rem] max-w-[1920px] source-sans pt-5 pb-[10.125rem] lg:min-h-screen lg:gap-y-8 lg:px-homepagePadding2xl lg:pt-20 lg:pb-[10rem] 3xl:min-h-fit 3xl:pb-[200px]`}
      >
        <img
          alt="product"
          src={`${process.env.REACT_APP_URL}${data?.Header?.image?.data?.attributes?.url}`}
          className="w-20 lg:w-[12.188rem]"
        />
        <div className="flex flex-col items-center">
          <div className="text-white font-bold text-primaryHeadingSm text-center roboto lg:text-primaryHeading2xl">
            {data?.Header?.title}
          </div>
          <div className="text-center flex flex-col text-white font-normal gap-y-8 w-full lg:text-paragraphHomepage2xl lg:w-[55%]">
            {data?.extraPoints?.map((value: any) => (
              <div key={value.id}>{value?.bulletPoint}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyVairavLandingComponent;
