import { MainLandingDetailsProps } from "../../../Interfaces/Interface";
import constants from "../../../Utils/Constants/constants";
import Navbar from "../../common/Navbar/Navbar";
import OrangeGradientButton from "../../common/button/OrangeGradientButton";

const SOCLandingComponent: React.FC<MainLandingDetailsProps> = ({
  heading,
  description,
  buttonText,
  buttonFunction,
  backgroundImage,
  backgroundType,
  changed,
  isLinearGrident,
}) => {
  return (
    <div
      className={`flex ${isLinearGrident && "linear_bg_Class"} justify-center`}
    >
      <div
        className={`flex relative flex-col max-w-[1920px] lg:min-h-screen 3xl:min-h-fit 3xl:pb-[200px]  `}
      >
        <div className="absolute w-full h-full hidden z-[0] lg:flex">
          {constants.reusableConstants.videoFormats.includes(
            backgroundType || ""
          ) ? (
            <video
              src={backgroundImage}
              className="h-full w-full object-cover object-center opacity-[20%]"
              autoPlay
              muted
              loop
              playsInline
              onContextMenu={(e) => e.preventDefault()}
            />
          ) : !constants.reusableConstants.videoFormats.includes(
              backgroundType || ""
            ) && backgroundType ? (
            <img
              src={backgroundImage}
              alt="background_logo"
              className="w-full h-full object-cover object-center opacity-[20%]"
            />
          ) : (
            ""
          )}
        </div>

        <Navbar />
        <div className="flex flex-col px-[23px] z-[1] items-center pb-[1.75rem] text-primaryHeadingSm pt-primaryPaddingYSm lg:items-start lg:px-homepagePaddingLg xl:px-homepagePadding2xl lg:pt-40 ">
          <div className="flex flex-col w-full  pb-8 text-center gap-y-8 lg:text-base lg:text-left lg:w-full xl:w-[46%] lg:gap-y-[2rem] ">
            <div className="w-full text-white font-black text-primaryHeadingSm text-center roboto lg:text-start lg:text-primaryHeading2xl lg:leading-[3.25rem]">
              {heading}
            </div>
            <div className="w-full text-white source-sans text-descriptionHeadingSm text-center leading-normal lg:font-semibold lg:text-start lg:text-paragraphHomepage2xl ">
              {description}
            </div>
          </div>
          <OrangeGradientButton
            buttonText={buttonText}
            buttonFunction={buttonFunction}
          />
        </div>
      </div>
    </div>
  );
};

export default SOCLandingComponent;
