import React from "react";

const AboutUsClients = ({ icons, heading }: any) => {
  return (
    <div className="flex flex-col ">
      <div className="py-[50px] lg:py-[100px] text-white text-button2xl lg:text-primaryHeading2xl font-black flex justify-center bg-transparent roboto ">
        {heading}
      </div>
      <div className="border-t-[3px] border-b-[3px] lg:border-t-[10px] lg:border-b-[10px] bg-white border-[#F47924] flex justify-center ">
        <div className="grid grid-cols-3 place-items-center place-content-center my-[50px] lg:my-[100px] gap-x-[10px] gap-y-[60px] lg:gap-x-[70px] lg:gap-y-[125px] px-homepagePaddingSm lg:px-7 w-full 3xl:max-w-[1920px]">
          {icons?.map((item: any, index: number) => (
            <React.Fragment key={index}>
              {icons?.length % 3 === 1 && index === icons?.length - 1 ? (
                <div className="col-span-3 flex justify-center">
                  <img
                    src={`${process.env.REACT_APP_URL}${item?.attributes?.url}`}
                    alt="img"
                    key={`${process.env.REACT_APP_URL}${item?.attributes?.url}`}
                    className="w-[45%] lg:w-[60%] 2xl:w-auto col-span-1 justify-self-center"
                  />
                </div>
              ) : (
                <img
                  src={`${process.env.REACT_APP_URL}${item?.attributes?.url}`}
                  alt="img"
                  key={`${process.env.REACT_APP_URL}${item?.attributes?.url}`}
                  className="w-[70%] lg:w-[60%] 2xl:w-auto col-span-1 justify-self-center"
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUsClients;
