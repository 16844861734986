import { productsLandingComponentProps } from "../../../Interfaces/Interface";
import constants from "../../../Utils/Constants/constants";
import Navbar from "../Navbar/Navbar";

const ProductsLandingComponent: React.FC<productsLandingComponentProps> = ({
  data,
  isLinearGrident,
}) => {
  console.log(data?.Header?.extraMedia?.data?.attributes?.ext);
  return (
    <div
      className={`flex justify-center  lg:min-h-screen 3xl:min-h-fit ${
        isLinearGrident && "linear_bg_Class"
      } `}
    >
      <div className="flex relative flex-col max-w-[1920px]">
        <div className="absolute w-full h-full hidden z-[0] lg:flex">
          {constants.reusableConstants.videoFormats.includes(
            data?.Header?.extraMedia?.data?.attributes?.ext
          ) ? (
            <video
              src={
                process.env.REACT_APP_URL +
                data?.Header?.extraMedia?.data?.attributes?.url
              }
              className="h-full w-full object-cover object-center opacity-[20%]"
              autoPlay
              playsInline
              muted
              loop
              onContextMenu={(e) => e.preventDefault()}
            />
          ) : !constants.reusableConstants.videoFormats.includes(
              data?.Header?.extraMedia?.data?.attributes?.ext || ""
            ) && data?.Header?.extraMedia?.data?.attributes?.ext ? (
            <img
              src={
                process.env.REACT_APP_URL +
                data?.Header?.extraMedia?.data?.attributes?.url
              }
              alt="background_logo"
              className="w-full h-full object-cover object-center opacity-[20%]"
            />
          ) : (
            ""
          )}
        </div>
        <Navbar />
        <div className=" flex flex-col gap-y-8 source-sans z-[1] w-full px-homepagePaddingSm items-center pt-5 pb-[3.125rem] lg:pt-28 lg:items-start lg:mt-20 md:mt-0  lg:mb-[20rem]  sm:w-full xl:w-[57%] lg:px-homepagePaddingLg xl:px-homepagePadding2xl">
          <img
            alt="product"
            src={`${process.env.REACT_APP_URL}${data?.Header?.image?.data?.attributes?.url}`}
            className="w-28 lg:w-[14.688rem]"
          />
          <div className="w-full flex flex-col text-white font-semibold gap-y-8 text-descriptionHeadingSm lg:text-paragraphHomepage2xl">
            {data?.extraPoints?.map((value: any) => (
              <div key={value.id}>{value?.bulletPoint}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsLandingComponent;
