import { splitFirstWord } from "../../../Utils/UtilityFunctions/UtilityFunction";
import borderIMG from "../../../Images/vairav-about-border.svg";
import borderUPIMG from "../../../Images/about-top-border.svg";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import HexagonAboutUsComponent from "./HexagonAboutUsComponent";
import ArrowImg from "../../../Images/Vector.svg";

const VairavTimeline = ({
  backgroundImage,
  primaryHeading,
  primaryDescription,
  secondaryHeading,
  secondaryDescription,
  detailsArr,
}: any) => {
  const [timelines, setTimelines] = useState(detailsArr);
  const [lastIndex, setLastIndex] = useState(1);
  const [renderedTimelines, setRenderedTimelines] = useState<any>([]);
  const text =
    "Cybersecurity is not just a privilege. It's a fundamental right.";

  useEffect(() => {
    let temp = [];
    if (detailsArr && detailsArr.length >= 2) {
      temp = [];
      for (let i = 0; i < 2; i++) {
        temp.push(detailsArr[i]);
      }
    } else {
      temp = detailsArr;
    }
    setRenderedTimelines(temp);
  }, [detailsArr]);

  const nextTimeline = () => {
    if (detailsArr.length > lastIndex + 1) {
      let temp = [detailsArr[lastIndex], detailsArr[lastIndex + 1]];
      setLastIndex(lastIndex + 1);

      setRenderedTimelines(temp);
    }
  };

  const prevTimeline = () => {
    if (lastIndex - 2 >= 0) {
      let temp = [detailsArr[lastIndex - 2], detailsArr[lastIndex - 1]];
      setLastIndex(lastIndex - 1);
      setRenderedTimelines(temp);
    }
  };

  return (
    <div className="relative w-full h-fit flex flex-col items-center pb-[3.125rem] lg:pb-[8.125rem]">
      <div className="absolute w-full h-full z-[1] aboutUsImageGradient" />

      <div className="absolute bg-bluePrimary lg:w-screen h-full">
        <img
          src={backgroundImage}
          className="h-full lg:w-full object-cover  opacity-25 z-[-100]"
        />
      </div>
      <div className="flex flex-col w-full max-w-[1920px]">
        <div className="flex flex-col lg:flex-row  z-40 justify-center gap-x-[3.313rem] items-center px-homepagePaddingSm gap-y-[1.875rem] py-[3.125rem] lg:gap-y-0 lg:px-homepagePadding2xl lg:py-[8.125rem]">
          <div className="roboto leading-tight text-primaryHeadingSm lg:text-primaryHeading2xl text-right font-black w-[64%] lg:w-[441px] ">
            <span className="text-orangePrimary">
              {splitFirstWord(primaryHeading || "")[0] + " "}
            </span>
            <span className="">{splitFirstWord(primaryHeading || "")[1]}</span>
          </div>
          <div
            className={`flex flex-row lg:flex-col items-end gap-y-[2.563rem] relative border-orangePrimary border-b-[5px] lg:border-r-[5px] lg:border-b-0 lg:w-[36.438rem]`}
          >
            <img
              src={borderUPIMG}
              className="h-full w-[5px] absolute lg:hidden object-cover"
            />
            <img
              src={borderIMG}
              className="h-fit w-full hidden ml-[1px] lg:flex"
            />
            <div className="text-buttonSm leading-tight tracking-[-0.0025em] text-center pr-[1.063rem] pl-[1.063rem] pb-[1.875rem] lg:pb-0 lg:pl-[3rem] lg:text-left lg:text-paragraphHomepage2xl lg:pr-[1rem]">
              {primaryDescription}
            </div>
            <img
              src={borderIMG}
              className="h-fit w-full hidden ml-[1px] lg:flex"
            />
            <img
              src={borderUPIMG}
              className="h-full w-[5px] absolute lg:hidden object-cover right-0"
            />
          </div>
        </div>
        <div className="w-full flex z-[40] timelineGradient flex-col px-homepagePaddingSm pt-[1.875rem] lg:pt-0 lg:flex-row lg:px-homepagePadding2xl">
          <div className="w-full text-center flex flex-col items-end lg:py-[2.063rem] lg:text-right  lg:w-[24.133rem]">
            <div
              className="flex w-full justify-center
           lg:justify-end text-primaryHeadingSm font-black lg:text-primaryHeading2xl lg:w-[21.133rem]"
            >
              {secondaryHeading}
            </div>
            <div className="text-buttonSm lg:text-paragraphHomepage2xl">
              {secondaryDescription}
            </div>
          </div>
          <div className="flex w-full justify-between flex-row flex-1 items-center py-[3.125rem] lg:pl-[6.063rem]">
            <div className="h-full flex items-center">
              <FaChevronLeft
                onClick={prevTimeline}
                className="w-[1.438rem] h-[1.438rem] no-hover-effect"
              />
            </div>
            {renderedTimelines?.map((value: any) => (
              <React.Fragment key={value?.title}>
                <HexagonAboutUsComponent
                  date={value?.title}
                  title={value?.heading}
                  buttonText={value?.ButtonTitle}
                  subtitle={value?.subTitle}
                  link={value?.url}
                />
              </React.Fragment>
            ))}
            <div className="h-full flex items-center">
              <FaChevronRight
                onClick={nextTimeline}
                className="w-[1.438rem] h-[1.438rem] no-hover-effect"
              />
            </div>
          </div>
        </div>
        <img
          src={ArrowImg}
          className=" w-[6rem] -mt-[3rem] top-0   lg:w-[16%] xl:-mt-[5rem] 2xl:w-[18%] lg:mt-[-5rem] 2xl:-mt-[6rem]  z-40  left-0  absolute"
        />
      </div>
    </div>
  );
};

export default VairavTimeline;
