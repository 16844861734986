import { useAnimationControls, useInView } from "framer-motion";
import ApproachContentBox from "./ApproachContentBox";
import { useEffect, useRef } from "react";
import { ourApproachComponentProps } from "../../../Interfaces/Interface";

const OurApproachComponent: React.FC<ourApproachComponentProps> = ({
  title,
  mappingData,
  useFinal,
  description,
  product,
  isLinearGrident,
}) => {
  const lineControl = useAnimationControls();

  const lineContainer = useRef(null);
  const isInView = useInView(lineContainer, { once: true });

  useEffect(() => {
    animationControl();
  }, [isInView]);

  const animationControl = async () => {
    try {
      if (isInView) {
        for (let index = 0; index < mappingData.length; index++) {
          await lineControl.start(`lineStart${index}_1`);
          await lineControl.start(`lineStart${index}_2`);
          await lineControl.start(`lineStart${index}_3`);
          await lineControl.start(`lineStart${index}_4`);
          await lineControl.start(`lineStart${index}_5`);
        }
      }
    } catch {}
  };

  return (
    <div
      className={`flex ${
        isLinearGrident && "linear_bg_Class"
      } bg-bluePrimary justify-center`}
    >
      <div
        className={`w-full flex flex-col min-h-screen pt-10 pb-10 py-primaryPaddingYSm gap-y-4 max-w-[1920px] sm:gap-y-10 sm:py-primaryPaddingY2xl 3xl:min-h-fit`}
      >
        <div className="w-full roboto flex justify-center text-white blueprimaryGradient font-black text-center px-homepagePaddingSm sm:px-homepagePadding2xl text-primaryHeadingSm lg:text-primaryHeading2xl">
          {title}
        </div>
        {description && (
          <div className="w-full flex justify-center text-white  text-descriptionHeadingSm font-normal text-center sm:font-semibold px-homepagePaddingSm sm:text-paragraphHomepage2xl sm:px-homepagePadding2xl">
            <div className="w-full sm:w-[45%]">{description}</div>
          </div>
        )}
        <div
          ref={lineContainer}
          className="w-full flex flex-col gap-y-10 mt-10 lg:gap-y-0"
        >
          {mappingData?.map((value: any, index: number) => (
            <ApproachContentBox
              useFinal={useFinal}
              key={value.heading}
              lineControl={lineControl}
              length={mappingData.length}
              tier={value.tier}
              index={index}
              heading={value.heading}
              content={value.content}
              image={`${process.env.REACT_APP_URL}${value?.image?.data?.attributes.url}`}
              product={product}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurApproachComponent;
