import { useEffect, useState } from "react";
import HomepageContactUs from "../../../Components/FormComponents/ContactUs/HomepageContact";
import PageFooter from "../../../Components/common/Footer/PageFooter";
import Navbar from "../../../Components/common/Navbar/Navbar";
import useAxiosInstance from "../../../Utils/UtilityFunctions/RequestConfig";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../../Redux/Loading-Slice";
import apiConfig from "../../../Utils/API/apiConfig";

const ContactUs = () => {
  const [contactUsData, setContactUsData] = useState<any>([]);
  const [LocationData, setLocationData] = useState<any>([]);

  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();

  const fetchContactUsData = async () => {
    try {
      dispatch(setLoadingState(true));
      const contactus = axiosInstance.get(apiConfig.Form.contactUsDetails.api);
      const location = axiosInstance.get(
        apiConfig.Form.contactUsLocationDetails.api
      );
      const response = await Promise.all([contactus, location]);
      response[0].data.data.map((attr: any, index: number) => {
        const temp = attr.attributes;
        setContactUsData(temp);
      });
      response[1].data.data.map((attr: any, index: number) => {
        const temp = attr.attributes;
        setLocationData(temp);
      });

      dispatch(setLoadingState(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchContactUsData();
  }, []);
  return (
    <div
      className={`flex flex-col bg-bluePrimary homepageLayout enableBlur w-full 3xl:min-h-screen 3xl:justify-between`}
    >
      <Navbar />
      <HomepageContactUs data={contactUsData} LocationInfo={LocationData} />
      <PageFooter />
    </div>
  );
};

export default ContactUs;
