import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/common/Navbar/Navbar";
import SOCLandingComponent from "../../../Components/Services/SOCLandingComponent/SOCLandingComponent";
import { useLocation, useNavigate } from "react-router-dom";
import SocHeader from "../../../Components/Services/SocHeader/SocHeader";
import CylinderContentComponent from "../../../Components/Services/CylinderComponent/CylinderContentComponent";
import OurApproachComponent from "../../../Components/Services/OurApproachPage/OurApproachComponent";
import PageFooter from "../../../Components/common/Footer/PageFooter";
import useAxiosInstance from "../../../Utils/UtilityFunctions/RequestConfig";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../../Redux/Loading-Slice";
import GetStartedComponent from "../../../Components/FormComponents/GetStarted/GetStartedComponent";
import apiConfig from "../../../Utils/API/apiConfig";

const Audit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isaData, setIsaData] = useState<any>([]);
  const [toc1, setToc1] = useState<any>();
  const [toc2, setToc2] = useState<any>();
  const [toc3, setToc3] = useState<any>();
  const [toc4, setToc4] = useState<any>();
  const [toc5, setToc5] = useState<any>();
  const [toc6, setToc6] = useState<any>();
  const [toc7, setToc7] = useState<any>();
  const [toc8, setToc8] = useState<any>();

  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();

  const fetchIsaData = async () => {
    try {
      dispatch(setLoadingState(true));
      const response = await axiosInstance.get(apiConfig.Audit.getDetails.api);
      response.data.data.map((attr: any, index: number) => {
        const isaData = attr?.attributes.isaudit;
        setIsaData(isaData);
      });
      dispatch(setLoadingState(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchIsaData();
  }, []);

  useEffect(() => {
    if (isaData && isaData.length > 0) {
      const one_toc = isaData?.filter((data: any) => data.sectionToc === 1);
      setToc1(one_toc[0]);

      const two_toc = isaData?.filter((data: any) => data.sectionToc === 2);
      setToc2(two_toc[0]);

      const three_toc = isaData?.filter((data: any) => data.sectionToc === 3);
      setToc3(three_toc[0]);

      const four_toc = isaData?.filter((data: any) => data.sectionToc === 4);
      setToc4(four_toc[0]);

      const five_toc = isaData?.filter((data: any) => data.sectionToc === 5);
      setToc5(five_toc[0]);

      const six_toc = isaData?.filter((data: any) => data.sectionToc === 6);
      setToc6(six_toc[0]);

      const seven_toc = isaData?.filter((data: any) => data.sectionToc === 7);
      setToc7(seven_toc[0]);

      const eight_toc = isaData?.filter((data: any) => data.sectionToc === 8);
      setToc8(eight_toc[0]);
    }
  }, [isaData]);

  return (
    <div className="flex bg-bluePrimary overflow-hidden w-full">
      <div className={`flex-col  homepageLayout enableBlur w-full`}>
        <SOCLandingComponent
          buttonFunction={() => {
            navigate(`/request-demo?path=${location.pathname}`);
          }}
          backgroundImage={`${process.env.REACT_APP_URL}${toc1?.Header?.image?.data?.attributes?.url}`}
          backgroundType={toc1?.Header?.image?.data?.attributes?.ext}
          heading={toc1?.Header.title}
          buttonText={toc1?.Header.buttonText}
          description={toc1?.Header.description}
          isLinearGrident={true}
        />
        {toc2 && toc3 && (
          <SocHeader
            pageParagraph={toc2?.pageParagraph}
            pageHeader={toc2?.pageHeader}
            cardData={toc2?.Cards}
            HorizentailDetails={toc3?.Cards}
            headingText={[`${toc3?.pageHeader}`, `${toc3?.pageParagraph}`]}
          />
        )}
        <CylinderContentComponent
          orientation="left"
          bulletPoints={null}
          content={toc4?.Cards}
          heading={toc4?.pageHeader}
          listView={false}
          isLinearGrident={true}
        />
        <OurApproachComponent
          title={toc5?.heading}
          textColor={"text-orangePrimary"}
          mappingData={toc5?.TiersDetails}
          useFinal={false}
          isLinearGrident={true}
        />
        <div className="linear_secound">
          <CylinderContentComponent
            orientation="right"
            bulletPoints={toc6?.BulletPoints}
            content={toc6?.content}
            heading={toc6?.heading}
            listView={true}
            bulletImage="triangle"
            useGradient={false}
            useDiffColor={true}
          />
          <CylinderContentComponent
            orientation="left"
            bulletPoints={toc7?.BulletPoints}
            content={toc7?.content}
            heading={toc7?.heading}
            listView={true}
            useGradient={false}
          />
        </div>
        <GetStartedComponent data={toc8} pageRoute={location.pathname} />
        <PageFooter />
      </div>
    </div>
  );
};

export default Audit;
