import React, { useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";
import "./Honeycome.css";

const Honeybutton = ({
  imageFirst,
  imageSecound,
  data,
}: {
  imageFirst: string;
  imageSecound: string;
  data: string[];
}) => {
  const [firstAnimationComplete, setFirstAnimationComplete] =
    React.useState(false);
  const [secondAnimationComplete, setSecondAnimationComplete] =
    React.useState(false);

  const [thirdAnimationComplete, setthirdAnimationComplete] =
    React.useState(false);
  const [playanimation, setPlayAnimation] = React.useState(false);

  const lineContainer = React.useRef<HTMLDivElement>(null);
  const isInView = useInView(lineContainer, { once: true });

  const animateLineSequence = async () => {
    if (isInView) {
      setPlayAnimation(true);
    }
  };
  React.useEffect(() => {
    animateLineSequence();
  }, [isInView]);

  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;
  }, []);

  return (
    <div
      ref={elementRef}
      className="flex w-full  justify-center xl:justify-center xl:items-center xl:flex-col  flex-col mt-0 ex-xl:mt-[6rem] sm:mt-[18rem] lg:mt-28 2xl:mt-28"
    >
      <div className="2xl:mt-0 z-1  flex justify-center items-center w-full bottom_box"></div>
      <div
        className=" w-full lg:mt-[4.5rem] xl:mt-[10rem] 2xl:mt-[27rem] 3xl:mt-[34rem]"
        style={{}}
      >
        {/* bottom icon */}

        {/* first vertical animation */}
        <div className=" flex justify-center w-full ">
          <motion.div
            ref={lineContainer}
            className="h-[6rem] md:h-[10rem]"
            style={{
              width: "3px",
              background: "white",
            }}
            initial={{ scaleY: 0, originY: 0 }}
            animate={playanimation ? { scaleY: 1, originY: 0 } : {}}
            transition={{ duration: 0.5 }}
            onAnimationComplete={() => setFirstAnimationComplete(true)}
          ></motion.div>
        </div>

        <div
          ref={lineContainer}
          className="lg:pb-0 2xl:mt-0 z-1  flex justify-center items-center w-full"
        >
          <div className="xl:w-full flex justify-center flex-col items-center ">
            <img
              src={imageFirst}
              alt="trident"
              className="xl:w-[32rem] w-[75%] sm:w-[50%] md:w-[50%] h-auto"
            />
            <div className="w-full h-[6rem] md:h-[4.49rem] flex justify-center items-center">
              {/* Secound verical line */}
              {firstAnimationComplete && (
                <motion.div
                  className="w-[3px] bg-white h-[6rem] md:h-[4.49rem]"
                  initial={{ scaleY: 0, originY: 0 }}
                  animate={{ scaleY: 1, originY: 0 }}
                  transition={{ duration: 1.2 }}
                  onAnimationComplete={() => setSecondAnimationComplete(true)}
                ></motion.div>
              )}
            </div>
            <img
              src={imageSecound}
              alt="trident"
              className="xl:w-[32rem] w-[75%] sm:w-[50%] md:w-[50%] h-auto"
            />
          </div>
        </div>

        <div
          className="w-full flex justify-center items-center h-[4.5rem]"
          style={{ position: "relative" }}
        >
          {/* Third verical line */}

          {secondAnimationComplete && (
            <motion.div
              className="w-[3px] h-[4.5rem] bg-white "
              initial={{ scaleY: 0, originY: 0 }}
              animate={{ scaleY: 1, originY: 0 }}
              transition={{ duration: 1.2 }}
              onAnimationComplete={() => setthirdAnimationComplete(true)}
            ></motion.div>
          )}
        </div>
        <BoxList apiData={data} />
      </div>
    </div>
  );
};

export default Honeybutton;

const TextButton = ({
  textfirst,
  textsecound,
  minWidth,
}: {
  textfirst: string;
  textsecound: string;
  minWidth: string;
}) => {
  return (
    <>
      <div className="flex flex-col justify-center items-center lg:mb-[0.35rem]">
        <div
          className={`absolute lg:relative line bottom-0 md:mt-[-1rem]   xl:mt-2 rounded-md bg-transparent lg:bg-transparent z-10 border-gradient-orange border-2 text-center px-2 lg:px-0   xl:py-[0.5] py-2`}
        >
          <div
            className={`text-white text-center xl:z-10 flex flex-col ${
              textfirst === "24/7, 365" || textfirst === "Additional"
                ? "w-[8rem] md:w-[17rem] lg:w-[10rem] xl:w-[17rem]"
                : "w-[8rem] md:w-[17rem] lg:w-[13rem] xl:w-[12rem]"
            } font-semibold text-[0.8rem] lg:w-[14rem] xl:text-[1.5rem] tracking-wider xl:leading-8`}
          >
            <div>{textfirst}</div>
            <div>{textsecound}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const BoxList = ({ apiData }: { apiData: string[] }) => {
  const data = [
    {
      class: "flex justify-center",
      innerdata: [
        {
          class:
            "lg:w-[270px] w-[83px] border-b-0 border-r-0 h-[16px] lg:h-[50px]",
          text: null,
        },
        {
          class: "lg:w-[270px] w-[83px] border-b-0 h-[16px] lg:h-[50px]",
          text: null,
        },
        {
          class:
            "lg:w-[270px] w-[83px] border-b-0 border-l-0 h-[16px] lg:h-[50px]",
          text: null,
        },
      ],
    },
    {
      class: "flex justify-center",
      innerdata: [
        {
          class:
            "lg:w-[270px] w-[83px]  border-r-0 h-[28px] py-[1rem] lg:h-[85px]",
          text: (apiData?.length && apiData[0]) || "",
        },
        {
          class:
            "lg:w-[270px] w-[83px]  border-r-0 h-[28px] py-[1rem] lg:h-[85px]",
          text: (apiData?.length && apiData[1]) || "",
        },
        {
          class: "lg:w-[270px] w-[83px]   h-[28px] py-[1rem] lg:h-[85px]",
          text: (apiData?.length && apiData[2]) || "",
        },
        {
          class:
            "lg:w-[270px] w-[83px]  border-l-0 h-[28px] py-[1rem] lg:h-[85px]",
          text: (apiData?.length && apiData[3]) || "",
        },
      ],
    },
    {
      class: "flex justify-center",
      innerdata: [
        {
          class:
            "lg:w-[270px] w-[83px] border-t-0 border-r-0 border-b-0 border-t-0 h-[16px] lg:h-[50px]",
          text: null,
        },
        {
          class:
            "lg:w-[270px] w-[83px] border-t-0 border-b-0 h-[16px] lg:h-[50px]",
          text: null,
        },
      ],
    },
    {
      class: "flex justify-center",
      innerdata: [
        {
          class:
            "lg:w-[270px] w-[83px]  border-r-0 h-[28px] py-[1rem] lg:h-[85px]",
          text: (apiData?.length && apiData[4]) || "",
        },
        {
          class: "lg:w-[270px] w-[83px]  h-[28px] py-[1rem] lg:h-[85px]",
          text: (apiData?.length && apiData[5]) || "",
        },
        {
          class:
            "lg:w-[270px] w-[83px]  border-l-0 h-[28px] py-[1rem] lg:h-[85px]",
          text: (apiData?.length && apiData[6]) || "",
        },
      ],
    },
    {
      class: "flex justify-center",
      innerdata: [
        {
          class:
            "lg:w-[270px] w-[83px] border-t-0 border-b-0 h-[16px] lg:h-[50px]",
          text: null,
        },
      ],
    },
    {
      class: "flex justify-center",
      innerdata: [
        {
          class:
            "lg:w-[270px] w-[83px] border-r-[1px] h-[28px] py-[1rem] lg:h-[85px] ",
          text: (apiData?.length && apiData[7]) || "",
        },
        {
          class:
            "lg:w-[270px] w-[83px] border-l-[1px] h-[28px] py-[1rem] lg:h-[85px] ",
          text: (apiData?.length && apiData[8]) || "",
        },
      ],
    },
    {
      class: "flex justify-center",
      innerdata: [
        {
          class: "bg-[#F58220]  h-[16px] lg:h-[50px]",
          text: null,
        },
      ],
    },
    {
      class: "flex justify-center",
      innerdata: [
        {
          class:
            "lg:w-[270px] w-[83px]  h-[28px] py-[1rem] lg:h-[85px] lg:rounded-md ",
          text: (apiData?.length && apiData[9]) || "",
        },
      ],
    },
  ];
  return (
    <div className="flex justify-center items-center pb-[50px] lg:pb-0">
      <div className="lg:w-[90%] xl:w-full">
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <div className={`${item.class} border-[#F58220]  `} key={index}>
              {item.innerdata.map((innerItem, innerindex) => (
                <React.Fragment key={innerindex}>
                  <div
                    className={`${innerItem.class} ${
                      index === data.length - 2
                        ? "border-none w-[2px]"
                        : "border-[2px]"
                    } border-[2px] flex justify-center items-center  border-[#F58220]`}
                    key={innerindex}
                  >
                    {innerItem?.text && (
                      <div className="font-normal lg:font-semibold text-[9px] lg:text-[25px] w-full text-center lg:px-[28px]">
                        {innerItem?.text?.split("#")[0]}
                        <br />
                        {innerItem?.text?.split("#")[1]}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
