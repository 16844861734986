import HoneyPageHeader from "./HoneyPageHeader";
import HoneycomBox from "./HoneycomBox";
import Honeybutton from "./Honeybutton";
import {
  IHoneyCombText,
  ISectionsix,
} from "../../../Interfaces/ILandingInterface";
const Honeycomb = ({
  toc,
  tocSecound,
}: {
  toc: ISectionsix;
  tocSecound: IHoneyCombText;
}) => {
  return (
    <div className=" linear_bg_Class flex flex-col lg:py-[6.25rem]  w-full">
      <HoneyPageHeader
        title={toc?.title}
        subTitle={toc?.subTitle}
        heading={toc?.heading}
        productData={toc?.productData}
      />
      <HoneycomBox data={tocSecound?.boxText} />
      <Honeybutton
        imageFirst={
          tocSecound?.image?.data !== null
            ? `${process.env.REACT_APP_URL}${tocSecound?.image?.data[0]?.attributes?.url}`
            : ""
        }
        imageSecound={
          tocSecound?.image?.data !== null
            ? `${process.env.REACT_APP_URL}${tocSecound?.image?.data[1]?.attributes?.url}`
            : ""
        }
        data={tocSecound?.honeyCombBoxTitle}
      />
    </div>
  );
};

export default Honeycomb;
