import React, { useEffect, useState } from "react";
import PageFooter from "../../../Components/common/Footer/PageFooter";
import Navbar from "../../../Components/common/Navbar/Navbar";
import useAxiosInstance from "../../../Utils/UtilityFunctions/RequestConfig";
import TeamSection from "../../../Components/Company/Leadership/TeamSection";
import TeamDetailsPopup from "../../../Components/Company/Leadership/TeamDetailsPopup/TeamDetailsPopup";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../../Redux/Loading-Slice";
import apiConfig from "../../../Utils/API/apiConfig";

const Leadership = () => {
  const [toc1, setToc1] = useState<any>();

  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();

  const fetchLeadershipData = async () => {
    try {
      dispatch(setLoadingState(true));
      const response = await axiosInstance.get(
        apiConfig.Leadership.getDetails.api
      );

      response.data.data.map((attr: any, index: number) => {
        const leadershipDatas = attr.attributes.Leadership;
        if (leadershipDatas && leadershipDatas.length > 0) {
          const one_toc = leadershipDatas?.filter(
            (data: any) => data.sectionToc === 1
          );
          setToc1(one_toc[0]);
        }
      });
      dispatch(setLoadingState(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLeadershipData();
  }, []);

  return (
    <div className="flex bg-bluePrimary w-full">
      <div
        className={`flex flex-col items-center mainPlastic homepageLayout enableBlur w-full max-w-[1920px] 3xl:min-h-screen 3xl:justify-between`}
      >
        <Navbar />
        <div className="flex justify-start w-full ">
          <div className="flex flex-col max-w-[1920px] w-full text-center gap-y-8 px-homepagePaddingSm pt-[2rem] pb-[5rem] lg:pt-[5rem] lg:pb-[7rem] lg:text-base lg:text-left xl:w-[55%] lg:gap-y-[2rem] lg:px-homepagePaddingLg xl:px-homepagePadding2xl">
            <div className="w-full text-white font-black text-primaryHeadingSm text-center roboto lg:text-start lg:text-primaryHeading2xl lg:leading-[3.25rem]">
              {toc1?.Title}
            </div>
            <div className="w-full text-white source-sans text-descriptionHeadingSm text-center leading-normal lg:text-start lg:text-paragraphHomepage2xl ">
              {toc1?.subTitle}
            </div>
          </div>
        </div>
        <div className="flex justify-center bg-white w-full">
          <div className="flex flex-col w-full gap-y-[3.125rem] lg:gap-y-[6.25rem] px-[0.938rem] max-w-[1920px] py-[3.125rem] lg:px-homepagePaddingLg lg:py-[6.25rem] xl:px-[8.938rem] 2xl:px-homepagePadding2xl">
            {toc1?.teamDetail.map((value: any, index: number) => (
              <React.Fragment key={value?.title}>
                <TeamSection
                  sectionHeading={value?.title}
                  cardList={value?.leadership_team_details.data}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
        <PageFooter />
      </div>
    </div>
  );
};

export default Leadership;
