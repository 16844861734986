import { CounterDetailsProps } from "../../../Interfaces/Interface";
import Counter from "./Counter";

const CounterDetails: React.FC<CounterDetailsProps> = ({
  initialValue,
  finalValue,
  duration,
  counterDescription,
  initialString,
  finalString,
}) => {
  return (
    <div className=" w-full h-fit flex flex-col justify-center items-center pb-7 lg:py-0 lg:leading-tight">
      <div className="font-black text-white w-fit h-fit flex flex-row text-counterNumberSm leading-tight lg:leading-normal lg:gap-x-1 lg:text-counterNumber2xl">
        <span>{initialString}</span>
        <Counter
          initialValue={initialValue}
          finalValue={finalValue}
          duration={duration}
        />
        <span>{finalString}</span>
      </div>
      <div className="text-orangePrimary text-descriptionHeadingSm source-sans font-light w-[160px] text-center leading-normal tracking-widest lg:w-[250px] lg:h-fit lg:text-descriptionHeading2xl lg:leading-tight">
        {counterDescription}
      </div>
    </div>
  );
};

export default CounterDetails;
