const SecuritySolutions: React.FC<{ heading: string; cardDetails: any }> = ({
  heading,
  cardDetails,
}) => {
  return (
    <div className="flex justify-center">
      <div className="flex flex-col max-w-[1920px] items-center py-primaryPaddingYSm gap-y-[3.125rem] lg:gap-y-[6.25rem] lg:px-homepagePadding2xl lg:py-primaryPaddingY2xl">
        <div className="roboto font-black text-center leading-snug px-homepagePaddingSm w-full lg:text-primaryHeading2xl lg:px-0 lg:w-[61.938rem]">
          {heading}
        </div>
        <div className="w-full grid  gap-x-[3.125rem] grid-cols-1 gap-y-[2.5rem] px-[2.875rem] lg:grid-cols-3 lg:gap-y-0 lg:px-0">
          {cardDetails?.map((value: any, index: number) => (
            <div
              key={index}
              className="flex flex-col items-center col-span-1 border-2 border-orangePrimary text-center py-[1.563rem] gap-y-[1.125rem] px-[0.938rem] lg:gap-y-[1.563rem] lg:px-[1.5rem] lg:py-[3.125rem] "
            >
              <img
                src={`${process.env.REACT_APP_URL}${value?.image?.data?.attributes?.url}`}
                alt="Security_solution_logo"
                className="w-[7.375rem] h-[7.375rem]"
              />
              <div className="text-orangePrimary font-bold w-[13.5rem] text-primaryHeadingSm leading-tight lg:text-3xl">
                {value?.title}
              </div>
              <div className="font-light text-descriptionHeadingSm lg:text-descriptionHeading2xl">
                {value?.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SecuritySolutions;
