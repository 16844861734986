import React from "react";
import { simpleMenuType } from "../../../Interfaces/Interface";
import { FaChevronRight } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";

const SimpleSubNavbar: React.FC<{
  subMenu: any;
  bigWidth?: boolean;
}> = ({ subMenu, bigWidth }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-col bg-black source-sans text-white font-semibold tracking-wider ${
        bigWidth ? "lg:w-[700px]" : "lg:w-[225px]"
      } lg:gap-y-4 lg:text-[18px] lg:px-6 lg:py-6`}
    >
      {subMenu?.map((value: any, index: number) => (
        <React.Fragment key={index}>
          <Link to={value?.link}>
            <div className="subMenu hover:gap-x-3 hover:ease-in duration-150 w-full flex items-center gap-x-1 cursor-pointer hover:text-orangePrimary">
              <span>{value?.heading}</span>
              <FaChevronRight className="no-hover-effect w-[12px] h-[12px] subMenuChev" />
            </div>
          </Link>
        </React.Fragment>
      ))}
    </div>
  );
};

export default SimpleSubNavbar;
