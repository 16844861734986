import { FaChevronRight } from "react-icons/fa6";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductsDropdown from "./ProductsDropdown";

const ResponsiveDropdown = ({ navList }: any) => {
  const [selectedTab, setSelectedTab] = useState("");

  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col lg:hidden bg-black fixed top-[3rem] z-50 px-[1.375rem] py-[1.563rem] gap-y-[0.75rem]">
      {navList.map((value: any) => (
        <div className="w-full flex flex-col">
          <div
            onClick={() => {
              if (selectedTab !== value?.title) {
                setSelectedTab(value?.title);
              } else {
                setSelectedTab("");
              }
            }}
            className="text-white flex flex-row gap-x-2 text-buttonSm font-semibold items-center"
          >
            <div>{value?.title}</div>
            <FaChevronRight
              className={`no-hover-effect w-[13px] h-[13px] ${
                value?.title === selectedTab ? "rotate-[90deg]" : ""
              } `}
            />
          </div>
          {selectedTab === value?.title &&
          Array.isArray(value?.navbarSubList) &&
          value?.navbarSubList.length > 1 ? (
            <div className="flex flex-col py-[1.563rem] gap-y-[0.938rem]">
              {value?.navbarSubList?.map((value: any, i: number) => (
                <>
                  <div className="w-full">
                    <React.Fragment key={i}>
                      <div
                        onClick={() => {
                          if (value?.link) {
                            navigate(value?.link);
                          }
                        }}
                        className="w-full flex flex-row items-center source-sans text-white text-buttonSm gap-x-[5px] font-semibold tracking-wider hover:text-orangePrimary subMenu cursor-pointer"
                      >
                        <span>{value?.heading}</span>
                        <FaChevronRight className="no-hover-effect w-[8px] h-[8px] mt-1 subMenuChev" />
                      </div>
                    </React.Fragment>
                  </div>
                </>
              ))}
            </div>
          ) : selectedTab === value?.title &&
            value?.navbarSubList.length <= 1 ? (
            <ProductsDropdown navbarSubList={value?.navbarSubList} />
          ) : (
            <></>
          )}
        </div>
      ))}
    </div>
  );
};

export default ResponsiveDropdown;
