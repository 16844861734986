import { useEffect, useState } from "react";
import OurApproachComponent from "../../../Components/Services/OurApproachPage/OurApproachComponent";
import Navbar from "../../../Components/common/Navbar/Navbar";
import ProductsLandingComponent from "../../../Components/common/ProductsLandingComponent/ProductsLandingComponent";
import axios from "axios";
import SocHeader from "../../../Components/Services/SocHeader/SocHeader";
import CylinderContentComponent from "../../../Components/Services/CylinderComponent/CylinderContentComponent";
import PageFooter from "../../../Components/common/Footer/PageFooter";
import useAxiosInstance from "../../../Utils/UtilityFunctions/RequestConfig";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../../Redux/Loading-Slice";
import GetStartedComponent from "../../../Components/FormComponents/GetStarted/GetStartedComponent";
import apiConfig from "../../../Utils/API/apiConfig";

const TridentPage = () => {
  const [tridentData, setTridentData] = useState<any>([]);
  const [toc1, setToc1] = useState<any>();
  const [toc2, setToc2] = useState<any>();
  const [toc3, setToc3] = useState<any>();
  const [toc4, setToc4] = useState<any>();
  const [toc5, setToc5] = useState<any>();
  const [toc6, setToc6] = useState<any>();
  const [toc7, setToc7] = useState<any>();

  const axiosInstance = useAxiosInstance();
  const location = useLocation();
  const dispatch = useDispatch();

  const fetchTridentData = async () => {
    try {
      dispatch(setLoadingState(true));

      const response = await axiosInstance.get(
        apiConfig.TridentPage.getDetails.api
      );

      response.data.data.map((attr: any, index: number) => {
        const tridentData = attr.attributes.productTrident;
        setTridentData(tridentData);
      });
      dispatch(setLoadingState(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTridentData();
  }, []);

  useEffect(() => {
    if (tridentData && tridentData.length > 0) {
      const one_toc = tridentData?.filter((data: any) => data.sectionToc === 1);
      setToc1(one_toc[0]);

      const two_toc = tridentData?.filter((data: any) => data.sectionToc === 2);
      setToc2(two_toc[0]);

      const three_toc = tridentData?.filter(
        (data: any) => data.sectionToc === 3
      );
      setToc3(three_toc[0]);

      const four_toc = tridentData?.filter(
        (data: any) => data.sectionToc === 4
      );
      setToc4(four_toc[0]);

      const five_toc = tridentData?.filter(
        (data: any) => data.sectionToc === 5
      );
      setToc5(five_toc[0]);

      const six_toc = tridentData?.filter((data: any) => data.sectionToc === 6);
      setToc6(six_toc[0]);

      const seven_toc = tridentData?.filter(
        (data: any) => data.sectionToc === 7
      );
      setToc7(seven_toc[0]);
    }
  }, [tridentData]);
  return (
    <div className="flex   bg-bluePrimary overflow-hidden w-full sm:min-h-screen">
      <div className={`flex-col  homepageLayout enableBlur w-full`}>
        <ProductsLandingComponent data={toc1} isLinearGrident={true} />
        {toc2 && toc3 && (
          <SocHeader
            pageParagraph={toc2?.pageParagraph}
            pageHeader={toc2?.pageHeader}
            cardData={toc2?.Cards}
            HorizentailDetails={toc3?.Cards}
            headingText={[`${toc3?.pageHeader}`, ``]}
          />
        )}
        <CylinderContentComponent
          orientation="left"
          bulletPoints={null}
          content={toc4?.BulletsWithCard}
          heading={toc4?.heading}
          listView={false}
          useGradient={true}
          useDiffColor={false}
          isLinearGrident={true}
        />
        <OurApproachComponent
          title={toc5?.heading}
          product="trident"
          mappingData={toc5?.TiersDetails}
          description={toc5?.content}
          isLinearGrident={true}
        />
        <CylinderContentComponent
          orientation="right"
          bulletPoints={null}
          content={toc6?.BulletsWithCard}
          heading={toc6?.heading}
          listView={false}
          useGradient={true}
          useDiffColor={false}
        />
        <GetStartedComponent data={toc7} pageRoute={location.pathname} />
        <PageFooter />
      </div>
    </div>
  );
};

export default TridentPage;
