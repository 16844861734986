import React, { ReactNode, useRef, useState } from "react";
import Popup from "reactjs-popup";
import { complexMenuType, navbarSubType } from "../../../Interfaces/Interface";
import downIMG from "../../Images/downVector.png";
import { IoChevronDownOutline } from "react-icons/io5";

const NavbarDropdown: React.FC<{
  tab: string;
  subMenu?: React.ReactNode;
  totalLength: number;
  index: number;
  fullview?: boolean;
}> = ({ tab, subMenu, totalLength, index, fullview }) => {
  const [inDiv, setInDiv] = useState(false);
  const ref = useRef<any>();
  const closeTooltip = () => ref?.current?.close();

  document?.addEventListener("scroll", () => {
    closeTooltip();
    setInDiv(false);
  });
  return (
    <Popup
      ref={ref}
      trigger={
        <div
          className={`col-span-1 text-[0.938rem] flex justify-end items-center gap-x-2 ${
            inDiv ? "text-orangePrimary" : ""
          } hover:text-orangePrimary cursor-pointer`}
        >
          <span>{tab}</span>
          <IoChevronDownOutline className="no-hover-effect w-[18px] h-[18px]" />
        </div>
      }
      repositionOnResize={true}
      position={index === totalLength - 1 ? "bottom right" : "bottom center"}
      className={fullview ? "dropdown" : ""}
      on={["hover", "focus"]}
      arrow={false}
    >
      <div
        onMouseEnter={() => {
          setInDiv(true);
        }}
        onMouseLeave={() => {
          setInDiv(false);
        }}
        className="w-full mt-6 "
      >
        {subMenu}
      </div>
    </Popup>
  );
};

export default NavbarDropdown;
