import { getCountryCode, getCountryData } from "countries-list";
import { useField, useFormikContext } from "formik";
import { useEffect } from "react";

const CustomPhoneNumber: React.FC<any> = ({
  placeholder,
  type,
  phoneName,
  countryCodeName,
  country,
  serverError,
  ...props
}) => {
  const [phoneField, phoneMeta] = useField({ name: phoneName });
  const [countryCodeField, countryCodeMeta] = useField({
    name: countryCodeName,
  });

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(
      "countryCode",
      "+" + getCountryData(getCountryCode(country) || "US").phone[0]
    );
  }, [country]);

  return (
    <div className=" lg:h-[4rem]">
      <div className="flex flex-row w-full">
        <input
          {...countryCodeField}
          {...props}
          disabled={true}
          className="border-[#FFFFFF50] border-b-[1px] w-[4rem]  bg-transparent placeholder:text-white source-sans px-2 tracking-wide pb-1 md:pb-3 outline-none text-white text-navbar2xl lg:text-descriptionHeading2xl"
          type={type}
          autoComplete={"new-password"}
          placeholder={placeholder}
        />
        <input
          {...phoneField}
          {...props}
          className="border-[#FFFFFF50] border-b-[1px] flex-1 bg-transparent  placeholder:text-white source-sans px-2 tracking-wide pb-1 md:pb-3 outline-none text-white text-navbar2xl lg:text-descriptionHeading2xl"
          type={type}
          autoComplete={"new-password"}
          placeholder={placeholder}
        />
      </div>
      <div className=" w-full flex justify-end roboto text-[13px] text-red-500 px-2 h-[1.25rem] lg:h-[1.5rem]">
        {phoneMeta.touched &&
        phoneMeta.error &&
        countryCodeMeta.touched &&
        countryCodeMeta.error
          ? phoneMeta.error + " and " + countryCodeMeta.error
          : phoneMeta.touched &&
            phoneMeta.error &&
            !(countryCodeMeta.touched && countryCodeMeta.error)
          ? phoneMeta.error
          : !(phoneMeta.touched && phoneMeta.error) &&
            countryCodeMeta.touched &&
            countryCodeMeta.error
          ? countryCodeMeta.error
          : ""}

        {!phoneMeta.error && !countryCodeMeta && serverError ? (
          <div>{serverError}</div>
        ) : null}
      </div>
    </div>
  );
};

export default CustomPhoneNumber;
