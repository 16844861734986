import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPagination, setNoOfPages } from "../../../Redux/Pagination-Slice";
import { getPagesArray } from "../../../Utils/UtilityFunctions/UtilityFunction";
import Navbar from "../../../Components/common/Navbar/Navbar";
import PaginationControl from "../../../Components/UtilityComponents/Pagination/PaginationControl";
import PageFooter from "../../../Components/common/Footer/PageFooter";
import { RootState } from "../../../Redux/store";
import useAxiosInstance from "../../../Utils/UtilityFunctions/RequestConfig";
import {
  setFormPopupState,
  setSelectedURL,
} from "../../../Redux/FormPopup-Slice";
import { setLoadingState } from "../../../Redux/Loading-Slice";
import BlogCard from "../../../Components/common/BlogComponents/BlogCard";
import apiConfig from "../../../Utils/API/apiConfig";

const AdvisoryReportsPage = () => {
  const pageState = useSelector((state: RootState) => state.pagination);
  const dispatch = useDispatch();

  const [advisoryReportsData, setAdvisoryReportsData] = useState<any>([]);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    if (advisoryReportsData.length !== 0) {
      GetAdvisoryReportsDetails();
    }
  }, [pageState.currentPage, pageState.recordsPerPage]);

  useEffect(() => {
    GetAdvisoryReportsDetails(1);
  }, []);

  const GetAdvisoryReportsDetails = async (initialPage?: number) => {
    try {
      dispatch(setLoadingState(true));
      const response = await axiosInstance.get(
        apiConfig.AdvisoryReports.getDetails.api(
          initialPage ? initialPage : pageState.currentPage,
          pageState.recordsPerPage
        )
      );
      let tempAdvisoryReportsData = [];
      tempAdvisoryReportsData = response?.data?.data || [];
      setAdvisoryReportsData(tempAdvisoryReportsData);
      dispatch(
        setNoOfPages(
          getPagesArray(
            pageState.recordsPerPage,
            response?.data?.data?.attributes?.data?.pagination?.total
          )
        )
      );
      dispatch(setLoadingState(false));
    } catch {}
  };

  return (
    <div className="flex bg-bluePrimary overflow-hidden w-full">
      <div
        className={`flex justify-center linear_secound homepageLayout enableBlur w-full`}
      >
        <div
          className={`flex flex-col enableBlur w-full max-w-[1920px] 3xl:min-h-screen 3xl:justify-between`}
        >
          <Navbar />
          <div className="w-full flex flex-col px-homepagePaddingSm py-[3.125rem] gap-y-[3.125rem] lg:py-[6.25rem] lg:gap-y-[6.25rem] lg:px-homepagePaddingLg xl:px-homepagePadding2xl">
            <div className=" flex justify-center roboto font-black  text-white text-primaryHeadingSm lg:text-primaryHeading2xl">
              ADVISORY REPORTS
            </div>
            <div className="w-full grid gap-5 grid-cols-1 lg:grid-rows-[repeat(8,_minmax(15rem,_auto))] lg:grid-cols-3">
              {advisoryReportsData?.attributes?.data?.results?.map(
                (value: any) => (
                  <BlogCard
                    key={value?.id}
                    date={value?.createdAt}
                    image={
                      value?.media?.url ||
                      advisoryReportsData?.attributes?.defaultImage?.url ||
                      ""
                    }
                    cardType="advisoryReport"
                    buttonFunction={() => {
                      dispatch(
                        setSelectedURL(
                          process.env.REACT_APP_URL + value?.reportPdf?.url
                        )
                      );
                      dispatch(setFormPopupState(true));
                    }}
                    buttonText={"DOWNLOAD"}
                    heading={value?.title}
                  />
                )
              )}
            </div>
            <PaginationControl />
          </div>
          <PageFooter />
        </div>
      </div>
    </div>
  );
};

export default AdvisoryReportsPage;
