import {
  Variant,
  motion,
  useAnimationControls,
  useInView,
} from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

const LineDataComponent: React.FC<{ threatTypes: any }> = ({
  threatTypes = [],
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const firstLineControl = useAnimationControls();
  const secondLineControl = useAnimationControls();
  const thirdLineControl = useAnimationControls();
  const lineContainer = useRef(null);
  const isInView = useInView(lineContainer, { once: true });

  const animateLineSequence = async () => {
    try {
      if (isInView && screenWidth >= 1024) {
        await firstLineControl.start("lineGrowVerticalZero");
        await secondLineControl.start("lineGrowHorizontalZero");
        await thirdLineControl.start("lineGrowVerticalZero");
      } else if (isInView && screenWidth < 1024) {
        thirdLineControl.start("lineGrowVerticalZero");
      }
    } catch {}
  };

  useEffect(() => {
    animateLineSequence();
  }, [isInView]);

  const lineGrowVerticalZero: Variant = {
    scaleY: 1,
    originY: 0,
    transition: { duration: 0.45 },
  };

  const lineGrowHorizontalZero: Variant = {
    scaleX: 1,
    transition: { duration: 0.45 },
  };

  return (
    <>
      <div className="w-9/12 relative h-[100px]  flex-col items-center hidden lg:flex">
        <motion.div
          initial={{ scaleY: 0, originY: 0 }}
          variants={{ lineGrowVerticalZero }}
          animate={firstLineControl}
          className="w-[3px] h-[97px] bg-white"
        />
        <motion.div
          initial={{ scaleY: 0, originY: 0 }}
          variants={{ lineGrowVerticalZero }}
          animate={thirdLineControl}
          className={`absolute w-[3px] h-[100px]  ${
            threatTypes.length % 2 !== 0 ? "" : "hidden"
          } mt-[99px] bg-white hidden lg:flex`}
        />
        <div className="w-full h-[3px] pl-[4.9rem] pr-[4.2rem]">
          <motion.div
            initial={{ scaleX: 0 }}
            variants={{ lineGrowHorizontalZero }}
            animate={secondLineControl}
            className="w-full h-full bg-white"
          />
        </div>
      </div>
      <div
        ref={lineContainer}
        className="flex flex-col w-full lg:gap-y-0 lg:justify-between lg:flex-row lg:w-9/12"
      >
        {threatTypes.map((value: any, index: number) => (
          <div key={index} className={`flex flex-col items-center `}>
            {threatTypes.length % 2 !== 0 &&
            index === Math.floor(threatTypes.length / 2) ? (
              <div className="h-fit lg:h-[100px] ">
                <motion.div
                  initial={{ scaleY: 0, originY: 0 }}
                  variants={{ lineGrowVerticalZero }}
                  animate={thirdLineControl}
                  className={`w-[3px] flex lg:hidden  ${
                    index === threatTypes.length - 1
                      ? "lg:self-end lg:mr-[4.2rem]"
                      : index === 0
                      ? "lg:self-start lg:ml-[4.9rem]"
                      : ""
                  } h-[50px] lg:h-[100px] bg-white`}
                />
              </div>
            ) : (
              <motion.div
                initial={{ scaleY: 0, originY: 0 }}
                variants={{ lineGrowVerticalZero }}
                animate={thirdLineControl}
                className={`w-[3px]  ${
                  index === threatTypes.length - 1
                    ? "lg:self-end lg:mr-[4.2rem]"
                    : index === 0
                    ? "lg:self-start lg:ml-[4.9rem]"
                    : ""
                } h-[50px] lg:h-[100px] bg-white`}
              />
            )}

            <div
              key={value?.bulletPoint}
              className={
                "border-gradient-orange flex border text-white w-[120px] text-descriptionHeadingSm text-center justify-center items-center py-1 lg:text-base lg:w-fit lg:px-3"
              }
            >
              {value?.bulletPoint}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default LineDataComponent;
