import { FaChevronRight } from "react-icons/fa6";
import { MdDownload } from "react-icons/md";
import operationIMG from "../../Images/Operation.svg";
import { FaRegNewspaper } from "react-icons/fa";
import { HiDocumentReport } from "react-icons/hi";
import { FaBlog } from "react-icons/fa";
import { parseDateToCustomFormat } from "../../../Utils/UtilityFunctions/UtilityFunction";
import { blogCardProps } from "../../../Interfaces/Interface";
import { Link } from "react-router-dom";

const BlogCard: React.FC<blogCardProps> = ({
  heading,
  date,
  buttonText,
  image,
  cardType = "blog",
  path,
  buttonFunction,
}) => {
  return (
    <Link to={path || ""} className="cursor-pointer">
      <div className="col-span-1 justify-center relative buttonBlueGlow flex">
        <div className="absolute w-full h-[18rem]">
          <img
            src={image ? `${process.env.REACT_APP_URL}${image}` : ``}
            className="z-[-10] w-full h-full object-cover"
          />
        </div>
        {cardType === "blog" ? (
          <FaBlog className="absolute z-[1] w-[4rem] mt-[4.5rem] h-[4rem] text-white no-hover-effect" />
        ) : cardType === "advisoryReport" ? (
          <HiDocumentReport className="absolute z-[1] w-[4rem] mt-[4.5rem] h-[4rem] text-white no-hover-effect" />
        ) : cardType === "pressRelease" ? (
          <FaRegNewspaper className="absolute z-[1] w-[4rem] mt-[4.5rem] h-[4rem] text-white no-hover-effect" />
        ) : (
          <></>
        )}
        <div className="bg-[#0C2431] z-[1] w-full mt-[12rem]  mainBlogCard  flex flex-col justify-between rounded-[1.5px]  px-[1.5rem] gap-y-[1.5rem] min-h-[12rem] py-[1.3rem] sm:pt-[1.8rem] sm:pb-[1.4rem] sm:min-h-[12.5rem] sm:gap-y-[3rem]">
          <div className="w-full flex roboto font-semibold text-start text-button2xl text-white lg:leading-[2rem]">
            {heading}
          </div>
          <div className=" flex justify-between items-center source-sans font-semibold text-primaryHeadingSm text-orangePrimary ">
            <div className="text-[0.75rem] tracking-widest text-[#F47924]">
              {parseDateToCustomFormat(date)}
            </div>
            {path ? (
              <Link to={path || ""}>
                <button className="flex mainBlogCardButton flex-row items-center gap-x-1 text-[0.938rem] text-white font-normal">
                  <div className="tracking-widest flex items-center">
                    {buttonText}
                  </div>
                  {buttonText.toUpperCase() === "DOWNLOAD" ? (
                    <MdDownload className="w-[1rem] h-[1rem] mt-[-3px]" />
                  ) : (
                    <FaChevronRight className="w-[1rem] h-[1rem] mt-[-3px]" />
                  )}
                </button>
              </Link>
            ) : (
              <button
                onClick={buttonFunction}
                className="flex mainBlogCardButton flex-row items-center gap-x-1 text-[0.938rem] text-white font-normal"
              >
                <div className="tracking-widest flex items-center">
                  {buttonText}
                </div>
                {buttonText.toUpperCase() === "DOWNLOAD" ? (
                  <MdDownload className="w-[1rem] h-[1rem] mt-[-3px] hover:fill-orangePrimary" />
                ) : (
                  <FaChevronRight className="w-[1rem] h-[1rem] mt-[-3px] hover:fill-orangePrimary" />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
