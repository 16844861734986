import { configureStore } from "@reduxjs/toolkit";
import cardBlurSlice from "./CardBlur-Slice";
import PaginationSlice from "./Pagination-Slice";
import LoadingSlice from "./Loading-Slice";
import FormPopupSlice from "./FormPopup-Slice";
import CMSEssentialsSlice from "./CMSEssentials";
import { error } from "console";
import ErrorSlice from "./Error-Slice";

export const store = configureStore({
  reducer: {
    card: cardBlurSlice,
    pagination: PaginationSlice,
    loading: LoadingSlice,
    formPopup: FormPopupSlice,
    CMSEssentials: CMSEssentialsSlice,
    error: ErrorSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
