import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface ErrorSlice {
  statusCode: number | null;
  statusMessage: string;
}

// Define the initial state using that type
const initialState: ErrorSlice = {
  statusCode: null,
  statusMessage: "",
};

export const errorSlice = createSlice({
  name: "Authorization",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setStatusCode: (state, action: PayloadAction<number>) => {
      state.statusCode = action.payload;
    },
    setStatusMessage: (state, action: PayloadAction<string>) => {
      state.statusMessage = action.payload;
    },
  },
});

export const { setStatusCode, setStatusMessage } = errorSlice.actions;

export default errorSlice.reducer;
