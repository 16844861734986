const apiConfig = {
  Navbar: {
    getDetails: {
      api: "/api/navbars?populate=deep",
    },
  },
  Footer: {
    getDetails: {
      api: "/api/footers?populate=deep",
    },
  },
  Homepage: {
    getDetails: {
      api: "/api/landing-pages?populate=deep",
    },
  },
  Audit: {
    getDetails: {
      api: "/api/is-audits?populate=deep",
    },
  },
  GRC: {
    getDetails: {
      api: "/api/grcs?populate=deep",
    },
  },
  ISC: {
    getDetails: {
      api: "/api/iscs?populate=deep",
    },
  },
  SOC: {
    getDetails: {
      api: "/api/soc-services?populate=deep",
    },
  },
  MDR: {
    getDetails: {
      api: "/api/service-mdrs?populate=deep",
    },
  },
  VAPT: {
    getDetails: {
      api: "/api/vapts/?populate=deep",
    },
    Mobile: {
      getDetails: {
        api: "/api/vapts-mobiles/?populate=deep",
      },
    },
    Network: {
      getDetails: {
        api: "/api/vapt-networks/?populate=deep",
      },
    },
    Web: {
      getDetails: {
        api: "/api/vapts-webs/?populate=deep",
      },
    },
  },
  Leadership: {
    getDetails: {
      api: "/api/leaderships?populate=deep",
    },
  },
  WhyVairav: {
    getDetails: {
      api: "/api/why-vairavs?populate=deep",
    },
  },
  TridentPage: {
    getDetails: {
      api: "/api/product-tridents?populate=deep",
    },
  },
  PressRelease: {
    getDetails: {
      api: (currentPage: number, recordsPerPage: number) => {
        return `/api/press-releases?pagination[page]=${currentPage}&pagination[pageSize]=${recordsPerPage}&fields[0]=title&fields[1]=createdAt&populate=deep&sort[0]=createdAt:desc`;
      },
    },
    getIDDetails: {
      api: (id: string) => {
        return `/api/press-releases/${id}`;
      },
    },
    getRecentIDDetails: {
      api: "/api/press-releases?sort[0]=createdAt:desc&pagination[page]=1&pagination[pageSize]=1",
    },
  },
  Blog: {
    getDetails: {
      api: (currentPage: number, recordsPerPage: number) => {
        return `${process.env.REACT_APP_URL}/api/resource-blogs?pagination[page]=${currentPage}&pagination[pageSize]=${recordsPerPage}&fields[0]=title&fields[1]=createdAt&populate=deep&sort[0]=createdAt:desc`;
      },
    },
    getRecentIDDetails: {
      api: "/api/resource-blogs?sort[0]=createdAt:desc&pagination[page]=1&pagination[pageSize]=1",
    },
    getBlogIDDetails: {
      api: (id: string) => {
        return `/api/resource-blogs/${id}?populate=deep`;
      },
    },
  },
  AdvisoryReports: {
    getDetails: {
      api: (currentPage: number, recordsPerPage: number) => {
        return `/api/advisory-reports?pagination[page]=${currentPage}&pagination[pageSize]=${recordsPerPage}&populate=deep&sort[0]=createdAt:desc`;
      },
    },
    getRecentIDDetails: {
      api: "/api/advisory-reports?sort[0]=createdAt:desc&pagination[page]=1&pagination[pageSize]=1&populate=deep",
    },
  },
  Policies: {
    CookiePolicy: {
      getDetails: {
        api: "/api/cookies-policies",
      },
    },
    CopyrightPolicy: {
      getDetails: {
        api: "/api/copyright-policies",
      },
    },
    CodeOfConduct: {
      getDetails: {
        api: "/api/code-of-conducts",
      },
    },
    InformationSecurityPolicy: {
      getDetails: {
        api: "/api/information-security-policies",
      },
    },
    PrivacyNotice: {
      getDetails: {
        api: "/api/privacy-notices",
      },
    },
    BugOrConcernReporting: {
      getDetails: {
        api: "/api/report-a-bugs",
      },
    },
    TermsAndCondtions: {
      getDetails: {
        api: "/api/term-and-conditions",
      },
    },
  },
  Form: {
    postDetails: {
      api: "/api/security-services",
    },
    getAdvisoryPopupDetails: {
      api: "api/get-starteds?filters[formType][$eq]=Advisory",
    },
    getRequestADemoDetails: {
      api: "/api/get-starteds?filters[formType][$eq]=Request-a-demo&populate=deep",
    },
    getAboutUsDetails: {
      api: "/api/about-us?populate=deep",
    },
    getGetStartedDetails: {
      api: "/api/get-starteds?filters[formType][$eq]=getStarted&populate=deep",
    },
    contactUsDetails: {
      api: "/api/get-starteds?filters[formType][$eq]=contact-us&populate=deep",
    },
    contactUsLocationDetails: {
      api: "/api/get-starteds?filters[formType][$eq]=office-location&populate=deep",
    },
  },
};

export default apiConfig;
