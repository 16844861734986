import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface loadingState {
  loading: boolean;
}

// Define the initial state using that type
const initialState: loadingState = {
  loading: false,
};

export const loadingSlice = createSlice({
  name: "Authorization",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setLoadingState } = loadingSlice.actions;

export default loadingSlice.reducer;
