import { cylinderComponentProps } from "../../../Interfaces/Interface";
import { RxTriangleRight } from "react-icons/rx";
import vairavBullet from "../../../Images/vairavBullet.png";
import triangleBullet from "../../../Images/trianglePoly.png";

const CylinderContentComponent: React.FC<cylinderComponentProps> = ({
  heading,
  content,
  bulletPoints,
  orientation,
  listView,
  bulletImage = "logo",
  useDiffColor = false,
  useGradient = true,
  isLinearGrident,
}) => {
  return (
    <div
      className={`flex ${isLinearGrident && "linear_secound"} justify-center`}
    >
      <div
        className={`w-full flex  ${
          orientation === "right" ? "justify-end" : "justify-start"
        } pb-0 source-sans  lg:py-primaryPaddingY2xl max-w-[1920px] `}
      >
        <div
          className={`w-full ${
            !useDiffColor ? "bg-[#1C3947]" : "bg-bluePrimary lg:bg-[#1C3947] "
          } flex flex-col py-6 px-homepagePaddingSm ${
            orientation === "right"
              ? `lg:w-12/12 xl:w-11/12 lg:rounded-l-[1000px] lg:pl-[22%]`
              : `${
                  listView ? "lg:w-10/12 xl:w-8/12" : "lg:w-11/12"
                } lg:rounded-r-[1000px] lg:pr-24 lg:pl-[10%]`
          } lg:pb-20  ${
            listView ? "lg:gap-y-10 gap-y-3" : " gap-y-3 lg:gap-y-6"
          } lg:px-0 lg:py-20`}
        >
          <div
            className={`font-black text-orangePrimary w-full  ${
              !listView && orientation === "right"
                ? "lg:pl-[6rem] "
                : listView
                ? "text-center"
                : ""
            } text-primaryHeadingSm roboto text-left lg:text-left lg:w-[80%] xl:w-[68%] lg:text-primaryHeading2xl lg:leading-[3rem]`}
          >
            {heading}
          </div>
          {listView ? (
            <>
              <div className="text-white font-semibold tracking-wide text-center text-[16px] lg:px-0 lg:text-left lg:w-[80%] xl:w-[68%] lg:text-paragraphHomepage2xl">
                {content?.toString()}
              </div>
              <div
                className={`flex flex-col gap-y-4  ${
                  orientation === "right" ? "" : "max-w-[50rem]"
                } lg:gap-y-4`}
              >
                {bulletPoints?.map((value, index) => (
                  <div
                    key={`bullet${index}`}
                    className="w-full flex flex-row items-center source-sans font-light  gap-x-4 lg:px-[10px] lg:px-0"
                  >
                    {bulletImage === "logo" ? (
                      <img
                        alt="vairav"
                        src={vairavBullet}
                        className="w-[14px] lg:w-[17px] lg:mt-0"
                      />
                    ) : (
                      <img
                        alt="vairav"
                        src={triangleBullet}
                        className="w-[14px] h-[14px] mt-[2px] lg:w-[17px] lg:h-[17px] lg:mt-0"
                      />
                    )}

                    <div className="text-white text-descriptionHeadingSm lg:text-descriptionHeading2xl">
                      {value.bulletPoint}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              {content?.map(
                (
                  value: {
                    title: string;
                    description: string;
                    extraBulletPoint: string[];
                  },
                  index: number
                ) => (
                  <div
                    key={value.title}
                    className={`flex flex-col w-full gap-y-2 lg:gap-y-4 ${
                      value.extraBulletPoint ? "pb-4" : "lg:w-10/12 "
                    }
                  ${orientation === "right" ? "lg:pl-[6rem]" : "lg:w-10/12 "}
                  `}
                  >
                    <div className="text-white font-semibold tracking-wide  text-[16px] leading-normal w-full text-left lg:px-0 lg:text-left lg:w-[68%] lg:text-paragraphHomepage2xl">
                      {value.title}
                    </div>
                    <div
                      className={`flex flex-col gap-y-2 w-full ${
                        orientation === "right"
                          ? "items-end"
                          : "lg:max-w-[50rem]"
                      } lg:gap-y-4`}
                    >
                      {value?.description && (
                        <div
                          className={`text-white mb-4 ${
                            orientation == "right"
                              ? " pr-0 lg:pr-[2rem] xl:pr-[10rem]"
                              : ""
                          }
                      text-descriptionHeadingSm font-light lg:text-descriptionHeading2xl `}
                        >
                          {value.description}
                        </div>
                      )}
                      {value.extraBulletPoint
                        ? value.extraBulletPoint!.map((value, index) => (
                            <div
                              key={`bullet${index}`}
                              className="w-full flex flex-row items-center source-sans font-light  gap-x-4 px-0 lg:px-[28px]"
                            >
                              <div className="w-[20px] lg:w-[2rem]">
                                <RxTriangleRight className="w-[20px] h-[20px] lg:w-full lg:h-[2rem] text-orangePrimary" />
                              </div>

                              <div className="text-white text-descriptionHeadingSm lg:text-descriptionHeading2xl">
                                {value}
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                )
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CylinderContentComponent;
