import { Link } from "react-router-dom";

const HexagonAboutUsComponent = ({
  date,
  title,
  subtitle,
  buttonText,
  link,
}: any) => {
  return (
    <div>
      <div className="text-descriptionHeadingSm text-center px-[0.438rem] lg:py-[3.5rem] lg:px-[1.25rem] flex flex-col justify-center shadowed hexagonBG h-[8.008rem] w-[6.875rem] lg: lg:text-paragraphHomepage2xl lg:justify-between lg:w-[15.035rem] lg:h-[17.388rem]">
        <div className="font-black ">{date}</div>
        <div className="">{title}</div>
        <div className="font-semibold">{subtitle}</div>
        <div className="text-[0.625rem] cursor-pointer font-light lg:text-descriptionHeading2xl">
          {link ? <Link to={link}>{buttonText}</Link> : buttonText}
        </div>
      </div>
    </div>
  );
};

export default HexagonAboutUsComponent;
