import React, { useEffect, useRef } from "react";

interface CaptchaProps {
  onVerify: (token: string) => void;
  captchaToken: boolean;
}

declare global {
  interface Window {
    turnstile: {
      getResponse(): string;
      render: (
        element: HTMLElement,
        options: { sitekey: string; callback: (token: string) => void }
      ) => void;
      reset: (element: HTMLElement) => void;
    };
    onloadTurnstileCallback: () => void;
  }
}

const Captcha: React.FC<CaptchaProps> = ({ onVerify, captchaToken }) => {
  const captchaRef = useRef<HTMLDivElement>(null);
  let firstRun = true;
  useEffect(() => {
    const handleError = () => {
      console.error("CAPTCHA error occurred");
    };
    const renderCaptcha = () => {
      if (window.turnstile && captchaRef.current) {
        try {
          if (!firstRun) return;
          firstRun = false;
          window.turnstile.render(captchaRef.current, {
            sitekey: process.env.REACT_APP_TURNSTILE_SITEKEY!, // Replace with your Cloudflare Turnstile site key
            callback: (token: string) => {
              onVerify(token);
            },
            "error-callback": handleError,
          } as any);
        } catch (error) {}
      }
    };
    renderCaptcha();
    window.onloadTurnstileCallback = renderCaptcha;

    return () => {
      if (window.turnstile && captchaRef.current) {
        window.turnstile.reset(captchaRef.current);
      }
    };
  }, [onVerify]);

  const resetCaptcha = () => {
    try {
      if (window.turnstile && captchaRef.current) {
        window.turnstile.reset(captchaRef.current);
      }
    } catch (error) {}
  };

  useEffect(() => {
    resetCaptcha();
  }, [captchaToken]);

  return <div className="" ref={captchaRef}></div>;
};

export default Captcha;
