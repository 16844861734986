import React from "react";

type HexagonData = {
  position: string;
  image: any;
};

const Hexagonal = ({ HexagonalData }: { HexagonalData: HexagonData[] }) => {
  return (
    <div className="scale-[0.4] md:scale-75 lg:scale-100 lg:mt-10 max-sm:mt-[-12rem] max-sm:mb-[-16rem]">
      <div className="w-full flex justify-center items-center flex-col ">
        <div className="w-full flex flex-row justify-center gap-x-[1.5rem]">
          {HexagonalData?.filter((item) =>
            ["topLeft", "topRight"].includes(item?.position)
          )
            .sort((a, b) => (a.position === "topLeft" ? -1 : 1))
            .map((item, index) => (
              <React.Fragment key={index}>
                <RectangleApp
                  key={index}
                  position={item.position}
                  image={`${process.env.REACT_APP_URL}${item?.image?.data?.attributes?.url}`}
                />
              </React.Fragment>
            ))}
        </div>
        <div className="w-full justify-center flex flex-row gap-x-[18rem] mt-[-4.5rem]">
          {HexagonalData?.filter((item) =>
            ["Left", "Right"].includes(item?.position)
          )
            .sort((a, b) => (a.position === "Left" ? -1 : 1))
            .map((item, index) => (
              <React.Fragment key={index}>
                <RectangleApp
                  key={index}
                  position={item.position}
                  image={`${process.env.REACT_APP_URL}${item?.image?.data?.attributes?.url}`}
                />
              </React.Fragment>
            ))}
        </div>
        <div className="w-full flex flex-row justify-center gap-x-[1.5rem] mt-[-4.5rem]">
          {HexagonalData?.filter((item) =>
            ["bottomLeft", "bottomRight"].includes(item?.position)
          )
            .sort((a, b) => (a.position === "bottomLeft" ? -1 : 1))
            .map((item, index) => (
              <React.Fragment key={index}>
                <RectangleApp
                  key={index}
                  position={item.position}
                  image={`${process.env.REACT_APP_URL}${item?.image?.data?.attributes?.url}`}
                />
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

const RectangleApp = ({
  image,
  position,
}: {
  image: string;
  position: string;
}) => {
  const type = {
    topLeft: "w-[20rem]",
    topRight: "w-[20rem]",
    Left: "h-[22rem]",
    Right: "h-[22rem]",
    bottomLeft: "w-[20rem]",
    bottomRight: "w-[20rem]",
  };
  return (
    <img
      src={image}
      className={`${
        type[position as keyof typeof type]
      }  hover:scale-[1.1]  cursor-pointer  ease-in-out duration-150`}
    />
  );
};

export default Hexagonal;
