import React, { useEffect } from "react";
import "./App.css";
import {
  Route,
  useLocation,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import ScrollToTop from "./Components/common/scrollToTop/ScrollToTop";
import ClickToTop from "./Components/common/clickToTop/ClickToTop";
import { setCurrentPage } from "./Redux/Pagination-Slice";
import { useDispatch } from "react-redux";
import LoadingSystem from "./Components/UtilityComponents/LoadingSystem/LoadingSystem";
import routes from "./Utils/Routes/routes";
import AdvisoryValidationPopup from "./Components/FormComponents/AdvisoryValidationPopup/AdvisoryValidationPopup";
import ErrorElement from "./Components/Error/ErrorElement";
import ErrorBoundary from "./Components/Error/ErrorBoundary";

function App() {
  const dispatch = useDispatch();

  const Wrapper = () => {
    const location = useLocation();

    useEffect(() => {
      dispatch(setCurrentPage(1));
    }, [location.pathname]);

    return (
      <div className="scroller">
        <ScrollToTop />
        <AdvisoryValidationPopup />
        <Outlet />
      </div>
    );
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<ErrorElement />} element={<Wrapper />}>
        {routes.unprotectedRoutes.map((val, index) => (
          <React.Fragment key={index}>
            <Route
              path={val.path}
              element={
                <LoadingSystem>
                  <ClickToTop />
                  {val.component}
                </LoadingSystem>
              }
            />
          </React.Fragment>
        ))}
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
