import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { setCurrentPage } from "../../../Redux/Pagination-Slice";
import { useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa6";
import { revertAll } from "../../../Utils/UtilityFunctions/UtilityFunction";

const PaginationControl = () => {
  const [startingIndex, setStartingIndex] = useState(0);
  const [renderedPageNumbers, setRenderedPageNumbers] = useState<number[]>([]);

  const pageState = useSelector((state: RootState) => state.pagination);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const endIndex = startingIndex + 4;
    const temp = pageState.noOfPages.slice(startingIndex, endIndex);
    setRenderedPageNumbers(temp);
  }, [startingIndex, pageState.noOfPages]);

  useEffect(() => {
    setStartingIndex(0);
    revertAll();
    dispatch(setCurrentPage(1));
  }, [location.pathname]);

  const updatePageNumbers = (newIndex: number) => {
    setStartingIndex(newIndex);
  };

  const changePage = (increment: number) => {
    const newIndex = startingIndex + increment;
    updatePageNumbers(newIndex);
  };

  const nextPage = () => {
    changePage(1);
  };

  const previousPage = () => {
    if (startingIndex > 0) {
      changePage(-1);
    }
  };

  return (
    <div className="w-full flex flex-row items-center justify-center">
      <div className="w-fit flex flex-row font-medium items-center text-descriptionHeadingSm gap-x-4 sm:gap-x-6 sm:text-paragraphHomepage2xl">
        <button
          onClick={previousPage}
          disabled={startingIndex === 0}
          className={"mt-[-1px] px-1"}
        >
          <FaChevronLeft
            className={`w-[0.813rem] h-[0.813rem] sm:w-[16px] sm:h-[16px] ${
              startingIndex === 0
                ? "text-[#A2ABBA] hover:fill-[#A2ABBA]"
                : "text-white hover:fill-white"
            }`}
          />
        </button>
        {renderedPageNumbers.map((value: number, index: number) => (
          <button
            key={index}
            onClick={() => dispatch(setCurrentPage(value))}
            className={`px-1 roboto ${
              pageState.currentPage === value
                ? " text-orangePrimary font-bold"
                : "text-white font-light"
            }`}
          >
            {value}
          </button>
        ))}
        <button
          onClick={nextPage}
          disabled={startingIndex + 4 >= pageState.noOfPages.length}
          className={
            "mt-[-1px] px-1 text-paragraphHomepage2xl " +
            (startingIndex + 4 >= pageState.noOfPages.length
              ? "text-[#A2ABBA] hover:text-[#A2ABBA]"
              : "text-white hover:text-white")
          }
        >
          <FaChevronRight
            className={`w-[0.813rem] h-[0.813rem] sm:w-[16px] sm:h-[16px] ${
              startingIndex + 4 >= pageState.noOfPages.length
                ? "text-[#A2ABBA] hover:fill-[#A2ABBA]"
                : "text-white hover:fill-white"
            }`}
          />
        </button>
      </div>
    </div>
  );
};

export default PaginationControl;
