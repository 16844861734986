import Popup from "reactjs-popup";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { countries, getCountryData } from "countries-list";
import halfVairav from "../../../Images/halfVairavBlue.svg";
import fullVairav from "../../../Images/fullVairavLogoBlue.svg";
import {
  setFormPopupState,
  setSelectedURL,
} from "../../../Redux/FormPopup-Slice";
import useAxiosInstance from "../../../Utils/UtilityFunctions/RequestConfig";
import { extractFileName } from "../../../Utils/UtilityFunctions/UtilityFunction";
import { useSubmitUserDetails } from "../../../Utils/UtilityFunctions/UtilComponentFunctions";
import { advisoryFormSchema } from "../../../Schema/Forms/AdvisoryFormScehma";
import CustomInput from "../../common/customInput/CustomInput";
import CustomDropdown from "../../common/customInput/CustomDropdown";
import SingleCheckbox from "../../common/customInput/SingleCheckbox";
import { IoAccessibility, IoClose } from "react-icons/io5";
import apiConfig from "../../../Utils/API/apiConfig";
import CustomPhoneNumber from "../../common/customInput/CustomPhoneNumber";

const formData = [
  {
    placeholder: "Email Address",
    name: "emailAddress",
    type: "email",
    id: "emailAddress",
  },
  {
    placeholder: "First Name",
    name: "firstName",
    type: "text",
    id: "firstName",
  },
  {
    placeholder: "Last Name",
    name: "lastName",
    type: "text",
    id: "lastName",
  },
  {
    placeholder: "Title",
    name: "jobTitle",
    type: "text",
    id: "jobTitle",
  },
  {
    placeholder: "Select Country",
    name: "country",
    type: "text",
    id: "countryName",
  },
  {
    placeholder: "Phone Number",
    name: "phoneNumber",
    type: "text",
    id: "phoneNumber",
  },
  {
    placeholder: "Company Name",
    name: "companyName",
    type: "text",
    id: "companyName",
  },
];

const AdvisoryValidationPopup: React.FC<any> = ({}) => {
  const [countryList, setCountryList] = useState<string[]>([]);
  const submitUserDetails = useSubmitUserDetails();
  const [serverError, setServerError] = useState<any>({});
  const location = useLocation();

  const [advisoryData, setAdvisoryData] = useState<any>([]);

  const axiosInstance = useAxiosInstance();

  const fetchFormData = async () => {
    try {
      const response = await axiosInstance.get(
        apiConfig.Form.getAdvisoryPopupDetails.api
      );

      response.data.data.map((attr: any, index: number) => {
        const advisoryFormData = attr;
        setAdvisoryData(advisoryFormData);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchFormData();
  }, []);

  const dispatch = useDispatch();

  const popupStates = useSelector((state: RootState) => state.formPopup);

  const modalOverlayStyle = {
    backdropFilter: "brightness(40%)",
    padding: "0px",
  };

  const downloadFile = async () => {
    const response = await axiosInstance.get(popupStates.selectedURL, {
      responseType: "arraybuffer", // Important: tells Axios to handle the response as an ArrayBuffer
    });
    const filename = extractFileName(popupStates.selectedURL);
    const disposition = response.headers["content-disposition"];
    const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    saveAs(pdfBlob, filename);
    dispatch(setFormPopupState(false));
    setCaptchaReset(!captchaReset);
  };

  useEffect(() => {
    const names = Object.values(countries).map((country) => country.name);
    setCountryList(names);
  }, []);
  const [captchaToken, setCaptchaToken] = useState("");
  const [captchaReset, setCaptchaReset] = useState(false);

  const navigate = useNavigate();

  const popupBody: any = (close: () => void) => {
    return (
      <div className=" fixed left-0 top-0 lg:top-[10%] xl:left-[22%] border-0 h-full lg:h-[80%] w-full overflow-y-scroll flex lg:!py-0 flex-col lg:flex-row bg-[rgba(25,25,25,255)] lg:rounded-sm shadow-md px-3 lg:px-[1.875rem] lg:w-[68%] lg:left-[18%] xl:w-[55%]">
        <div className="relative lg:w-5/12  ">
          <div className="absolute flex justify-center lg:justify-end h-fit w-full z-[-100] lg:mt-[35%]">
            <img
              alt="halfVairav"
              src={halfVairav}
              className="w-[12.5rem] hidden 2xl:mb-[20%] lg:flex"
            />
            <img
              alt="fullVairav"
              src={fullVairav}
              className="w-[10.314rem] flex lg:hidden"
            />
          </div>
          <div className="w-full flex justify-end lg:hidden pt-2">
            <IoClose
              onClick={close}
              className="no-hover-effect h-[1.25rem] w-[1.25rem]"
            />
          </div>
          <div className="px-0 lg:px-0 pt-[1.5rem] lg:pt-[8.125rem] flex flex-col items-start mr-[2.688rem] gap-y-[0.938rem] lg:gap-y-[1.6rem]">
            <div className="text-blueTertiary roboto text-primaryHeadingSm lg:text-primaryHeading2xl leading-tight font-black text-left w-[60%] lg:w-auto">
              {advisoryData?.attributes?.heading}
            </div>
            <hr className="bg-white border-2 w-12 lg:w-20" />
            <div className=" flex flex-col  gap-y-[0.625rem]">
              <div className="text-white text-descriptionHeadingSm md:text-descriptionHeading2xl font-normal">
                {advisoryData?.attributes?.subHeading}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-fit source-sans lg:px-0 lg:w-7/12">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              emailAddress: "",
              jobTitle: "",
              phoneNumber: "",
              companyName: "",
              country: "United States",
              countryCode: getCountryData("US").phone[0],
              requiresEmailsNewsletters: false,
            }}
            onSubmit={async (values, actions) => {
              const formWithCapatchaToken = {
                ...values,
                captchaToken,
                pageRoute: location.pathname,
              };
              submitUserDetails(
                "/api/advisory-pop-up-forms",
                formWithCapatchaToken,
                "Advisory Form",
                actions.resetForm,
                serverError,
                setServerError,
                captchaReset,
                setCaptchaReset,
                captchaToken,
                downloadFile
              );
            }}
            validationSchema={advisoryFormSchema}
            enableReinitialize={true}
          >
            {({ isSubmitting, values }) => (
              <Form className="w-full h-full bg-[#00000065] text-white px-3 md:px-10 pt-[1.625rem] pb-[3.125rem] md:pt-[2.125rem] flex flex-col lg:justify-start">
                <div className="w-full text-buttonSm mb-[1.875rem] font-semibold lg:text-paragraphHomepage2xl text-center self-center lg:w-full lg:text-start">
                  Fill out the form to unclose the resource.
                </div>
                {formData?.map((items, index) => (
                  <React.Fragment key={index}>
                    {items.name === "country" ? (
                      <CustomDropdown
                        placeholder={items.placeholder}
                        name={items.name}
                        type={items.type}
                        serverError={
                          serverError &&
                          "field" in serverError &&
                          serverError.field === items.name &&
                          serverError.error
                        }
                        data={countryList}
                      />
                    ) : items.name === "phoneNumber" ? (
                      <CustomPhoneNumber
                        placeholder={items.placeholder}
                        phoneName={items.name}
                        countryCodeName={"countryCode"}
                        country={values.country}
                        type={items.type}
                        serverError={
                          (serverError &&
                            "field" in serverError &&
                            serverError.field === items.name) ||
                          (serverError.field === "countryCode" &&
                            serverError.error)
                        }
                      />
                    ) : (
                      <CustomInput
                        placeholder={items.placeholder}
                        name={items.name}
                        type={items.type}
                        serverError={
                          serverError &&
                          "field" in serverError &&
                          serverError.field === items.name &&
                          serverError.error
                        }
                      />
                    )}
                  </React.Fragment>
                ))}

                <div className="text-[14px] lg:text-descriptionHeading2xl">
                  <SingleCheckbox
                    setCaptchaToken={setCaptchaToken}
                    captchaReset={captchaReset}
                    serverError={serverError}
                    name="requiresEmailsNewsletters"
                    requiredLine={false}
                    checkboxText={
                      "I'm looking forward to receiving emails and newsletters from Vairav Tech."
                    }
                    type={"home"}
                    disclaimer={
                      <>
                        By submitting this form, I agree to the{" "}
                        <span
                          onClick={() =>
                            navigate("/policies/terms-and-conditions")
                          }
                          className="underline cursor-pointer"
                        >
                          Website Terms of Conditions
                        </span>{" "}
                        and the{" "}
                        <span
                          onClick={() => navigate("/policies/privacy-notice")}
                          className="underline cursor-pointer"
                        >
                          Vairav TECH Privacy Policy.
                        </span>
                      </>
                    }
                  />
                </div>
                <div className="w-full flex justify-center items-center pt-[1.563rem] md:pt-9">
                  <button
                    type="submit"
                    className="bg-blueTertiary py-[0.563] flex justify-center w-[13.375rem] text-[1.125rem] leading-loose lg:leading-none lg:text-paragraphHomepage2xl  lg:py-[0.813rem]  lg:w-[18.75rem]"
                  >
                    VIEW RESOURCE
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  return (
    <Popup
      modal
      position={"center center"}
      overlayStyle={modalOverlayStyle}
      closeOnDocumentClick={true}
      open={popupStates.formPopupState}
      onClose={() => {
        dispatch(setSelectedURL(""));
        dispatch(setFormPopupState(false));
      }}
      lockScroll={true}
    >
      {popupBody}
    </Popup>
  );
};

export default AdvisoryValidationPopup;
