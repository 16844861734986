import TemplatePolicy from "../../Pages/TemplatePolicies/TemplatePolicy";
import AboutUs from "../../Pages/Company/Aboutus/AboutUs";
import Leadership from "../../Pages/Company/Leadership/Leadership";
import PressReleaseDetailsPage from "../../Pages/Company/PressRelease/PressReleaseDetailsPage/PressReleaseDetailsPage";
import PressReleasePage from "../../Pages/Company/PressRelease/PressReleaseListPage/PressRelasePage";
import ContactUsPage from "../../Pages/Company/ContactUs/ContactUs";
import FormSucessPage from "../../Pages/UtilityPages/FormSucessPage/FormSucessPage";
import GetStarted from "../../Pages/UtilityPages/GetStarted/GetStarted";
import Homepage from "../../Pages/Homepage/Homepage";
import TridentPage from "../../Pages/Products/TridentPage/TridentPage";
import RequestADemoPage from "../../Pages/RequestADemoPage/RequestADemoPage";
import AdvisoryReportsPage from "../../Pages/Resources/AdvisoryReportsPage/AdvisoryReportsPage";
import BlogDetailsPage from "../../Pages/Resources/BlogDetailsPage/BlogDetailsPage";
import BloglistPage from "../../Pages/Resources/BloglistPage/BloglistPage";
import Audit from "../../Pages/Services/Audit/Audit";
import Grc from "../../Pages/Services/Grc/Grc";
import ISC from "../../Pages/Services/ISC/ISC";
import MDR from "../../Pages/Services/MDR/MDR";
import SOCPage from "../../Pages/Services/SOC/SOCPage";
import MobilePenTesting from "../../Pages/Services/VAPT/PlatformTesting/MobilePenTesting";
import NetworkPenTesting from "../../Pages/Services/VAPT/PlatformTesting/NetworkPenTesting";
import WebPenTesting from "../../Pages/Services/VAPT/PlatformTesting/WebPenTesting";
import Vapt from "../../Pages/Services/VAPT/Vapt";
import WhyVairavPage from "../../Pages/Company/WhyVairavPage/WhyVairavPage";
import ErrorElement from "../../Components/Error/ErrorElement";
import InProgress from "../../Components/InProgress/InProgress";
import apiConfig from "../API/apiConfig";

const routes = {
  unprotectedRoutes: [
    {
      path: "/error",
      component: <ErrorElement />,
    },
    {
      path: "/",
      component: <Homepage />,
    },
    {
      path: "/form/success",
      component: <FormSucessPage />,
    },
    {
      path: "/services/csoc-as-a-service",
      component: <SOCPage />,
    },
    {
      path: "/services/vulnerability-assessment-and-penetration-testing",
      component: <Vapt />,
    },
    {
      path: "/services/vulnerability-assessment-and-penetration-testing/network",
      component: <NetworkPenTesting />,
    },
    {
      path: "/services/vulnerability-assessment-and-penetration-testing/web",
      component: <WebPenTesting />,
    },
    {
      path: "/services/vulnerability-assessment-and-penetration-testing/mobile",
      component: <MobilePenTesting />,
    },
    {
      path: "/services/governance-risk-and-compliance",
      component: <Grc />,
    },
    {
      path: "/services/information-systems-audit",
      component: <Audit />,
    },
    {
      path: "/services/information-security-consultation",
      component: <ISC />,
    },
    {
      path: "/services/managed-detection-and-response",
      component: <MDR />,
    },
    {
      path: "/company/leadership",
      component: <Leadership />,
    },
    {
      path: "/company/about-us",
      component: <AboutUs />,
    },
    {
      path: "/company/whyvairav",
      component: <WhyVairavPage />,
    },
    {
      path: "/company/press-releases",
      component: <PressReleasePage />,
    },
    {
      path: "/company/press-releases/:name/:id",
      component: <PressReleaseDetailsPage />,
    },
    {
      path: "/products/trident",
      component: <TridentPage />,
    },
    {
      path: "/resources/blogs",
      component: <BloglistPage />,
    },
    {
      path: "/resources/advisory-reports",
      component: <AdvisoryReportsPage />,
    },
    {
      path: "/resources/blogs/:name/:id",
      component: <BlogDetailsPage />,
    },
    {
      path: "/get-started",
      component: <GetStarted />,
    },
    {
      path: "/contact-us",
      component: <ContactUsPage />,
    },
    {
      path: "/request-demo",
      component: <RequestADemoPage />,
    },
    {
      path: "/policies/cookie-policy",
      component: (
        <TemplatePolicy
          route={apiConfig.Policies.CookiePolicy.getDetails.api}
          objectProperty="CookiesPolicyData"
        />
      ),
    },
    {
      path: "/policies/copyright-policy",
      component: (
        <TemplatePolicy
          route={apiConfig.Policies.CopyrightPolicy.getDetails.api}
          objectProperty="CopyRightPolicyData"
        />
      ),
    },
    {
      path: "/policies/code-of-conduct",
      component: (
        <TemplatePolicy
          route={apiConfig.Policies.CodeOfConduct.getDetails.api}
          objectProperty="codeOfConductData"
        />
      ),
    },
    {
      path: "/policies/information-security-policy",
      component: (
        <TemplatePolicy
          route={apiConfig.Policies.InformationSecurityPolicy.getDetails.api}
          objectProperty="SecurityPolicyData"
        />
      ),
    },
    {
      path: "/policies/privacy-notice",
      component: (
        <TemplatePolicy
          route={apiConfig.Policies.PrivacyNotice.getDetails.api}
          objectProperty="privacyNoticeData"
        />
      ),
    },
    {
      path: "/policies/bug-or-concern-reporting",
      component: (
        <TemplatePolicy
          route={apiConfig.Policies.BugOrConcernReporting.getDetails.api}
          objectProperty="ReportData"
        />
      ),
    },
    {
      path: "/policies/terms-and-conditions",
      component: (
        <TemplatePolicy
          route={apiConfig.Policies.TermsAndCondtions.getDetails.api}
          objectProperty="TermandConditionData"
        />
      ),
    },
  ],
};

export default routes;
