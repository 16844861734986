import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import LoadingScreen from "./loadingScreen/LoadingScreen";

const LoadingSystem: React.FC<{
  children: React.ReactNode;
  minTime?: number;
}> = ({ children, minTime = 2000 }) => {
  const [elapsed, setElapsed] = useState(false); // Set to false initially
  const loadingState = useSelector((state: RootState) => state.loading.loading);

  useEffect(() => {
    // Set elapsed to false initially and after 2 seconds
    setElapsed(false);
    const timeout = setTimeout(() => {
      setElapsed(true);
    });

    return () => clearTimeout(timeout);
  }, [children]); // Trigger effect when loadingState changesz

  return (
    <>
      {(!elapsed || loadingState) && <LoadingScreen />}
      <div className={`${!elapsed || loadingState ? "hidden" : ""}`}>
        {children}
      </div>
    </>
  );
};

export default LoadingSystem;
